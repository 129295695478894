import React, { forwardRef, useEffect, useState } from "react";
import { Select, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
const Index = () => {
    const { t, i18n } = useTranslation();
    const [html, sethtml] = useState('')
    const [Loading, setloading] = useState(false)
    const languageobj = {
        zh:"zh-CN",
        es:"es-ES",
        ru:"ru",
        en:"en"
    }
    const lang = (language) => {
        if(language == 'zh-CN'){
            return 'zh'
        }else if(language == 'es-ES'){
            return 'es'
        }else if(language == 'ru'){
            return 'ru'
        }else{
            return 'en'
        }
    }
    const [language, setlanguage] = useState(lang(i18n.language))
    const { code } = useParams()
    useEffect(() => {
        if (code) {
            setloading(true)
            global.Ajax.GET(`api/Agreements/${code}/${language}`).then(res => {
                setloading(false)
                if (res.isSuccess && res.result.content) {
                    sethtml(res.result.content)
                }else{
                    sethtml("")
                }
            })
        }
        //eslint-disable-next-line
    }, [code, language])
    useEffect(() => {
        document.title = t('协议')
        //eslint-disable-next-line
    }, [])
    return (
        <React.Fragment>
            <div className="bgbai dzbox AgreementsPage">
                <div style={{ textAlign: "right" }}><Select style={{ width: 150, textAlign: "left" }} value={language} options={[
                    { label: '中文（简体）', value: 'zh' },
                    { label: 'English', value: 'en' },
                    { label: 'Español', value: 'es' },
                    { label: 'Русский язык', value: 'ru' },
                ]} onChange={(e) => {
                    setlanguage(e)
                    i18n.changeLanguage(languageobj[e])
                }}></Select></div>
                {Loading &&
                    <div style={{textAlign:"center"}}>
                        <Spin size="large" />
                    </div>
                }
                <div dangerouslySetInnerHTML={{ __html: html }}></div>
                {(!code || !html) && <h1 style={{ textAlign: 'center', fontSize: "40px" }}>{t('暂无协议')}</h1>}
            </div>
        </React.Fragment>
    )
}

export default forwardRef(Index);