import { App, Button, Form, Input } from "antd";
import React, { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";

const EditGoodsRemark = (props, _ref) => {
    const [t] = useTranslation();
    const { message } = App.useApp();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const onFinish = (data) => {
        data.id = props.data.id;
        if (props.pathType == 'Cart') {  // 购物车修改备注
            setLoading(true)
            global.Ajax.hhtc_request('api/ShopCart/EditRemark', data, "PUT").then(res => {
                if (res.isSuccess) {
                    message.success(res.message);
                    props.onOk?.();
                } else {
                    message.error(res.message);
                }
                setLoading(false);
            })
        } else if (props.pathType == 'CheckOrder') {  // 确认订单页面修改备注
            props.onOk(data);
        }
    }
    return (
        <Form
            form={form}
            onFinish={onFinish}
            preserve={false}
            initialValues={{
                remark: props.data.remark,
            }}
        >
            <Form.Item name='remark' rules={[{ required: true, message: t('请输入备注') }]}>
                <Input.TextArea rows={5} placeholder={t("给采购的留言，没有特殊要求则不写")} />
            </Form.Item>
            <div className="flex" style={{ justifyContent: 'flex-end' }}>
                <Button onClick={() => props.onCancel?.()}>{t("取消")}</Button>
                <Button loading={loading} type="primary" htmlType="submit" className="marginl10">{t("确定")}</Button>
            </div>
        </Form>
    )
}

export default forwardRef(EditGoodsRemark);