import { Input, Table, Tabs } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Index = (props, _ref) => {
    const {t}=useTranslation();
    const [tabs] = useState([
        { label: t('全部'), key: '1' },
        { label: t('订单咨询'), key: '2' },
        { label: t('包裹咨询'), key: '3' },
        { label: t('包裹售后'), key: '4' },
        { label: t('其他咨询'), key: '5' },
    ]);
    const columns = [{
        title: t('类型'),
        dataIndex: 'type',
    }, {
        title: t('关联单号'),
        dataIndex: 'type',
    }, {
        title: t('咨询内容'),
        dataIndex: 'type',
    }, {
        title: t('提交时间'),
        dataIndex: 'type',
    }, {
        title: t('状态'),
        dataIndex: 'type',
    }]
    useEffect(() => {
        document.title = t('我的咨询');
        //eslint-disable-next-line
    }, [])
    return (
        <React.Fragment>
            <Tabs
                items={tabs}
                className="zxtabs"
            />
            <div className="flexAllCenter" style={{ padding: '20px 0' }}>
                <Input bordered={false} placeholder={`${t("包裹号")}/${t("订单号")}`} className="bgf5" style={{ width: 280, height: 40 }} suffix={(<i className="iconfont icon-dingbusousuo"></i>)} />
            </div>
            <Table
                columns={columns}
            />
        </React.Fragment>
    )
}

export default forwardRef(Index);