import React, { forwardRef, useState,useEffect } from "react";
import { useTranslation } from "react-i18next";

const Index = (props, _ref) => {
    const { t } = useTranslation();
    const [levels, setlevels] = useState([])
    const [levelId, setlevelId] = useState({});
    const getMembershipLevel = () => {
        global.Ajax.GET('api/MembershipLevel/Item').then(res => {
            if (res.isSuccess) {
                setlevels(res.result);
            }
        })
    }
    const getInfo = () => {
        global.Ajax.GET('api/Member/MemberInfo').then(res => {
            if (res.isSuccess) {
                setlevelId(res.result.levelId);
            }
        })
    }
    useEffect(() => {
        if(!props.levelId){
            document.title = t('我的特权');
            getInfo();
        }
        getMembershipLevel()
        //eslint-disable-next-line
    }, [props.levelId]);
    return (
        <React.Fragment>
            <div className="flexCenter paddb12 margint20 borderb1f2">
                <h1 className="marginrauto">{t("我的特权")}</h1>
            </div>
            <div className="flex levelbox margint20">
                <div className="item first">
                    <div className="weight600 td">{t("等级特权")}</div>
                    <div className="td">{t("国际运费减免")}</div>
                </div>
                {
                    levels.map((item, index) => {
                        return <div className={item.levelId == levelId ? "item col active" : 'item col'} key={index}>
                            <div className="weight600 td">
                                {item.levelName}
                                {item.levelId == levelId ? <p>{t("我的等级")}</p> : <p className="font12 color6" style={{ fontWeight: 'normal' }}>{item.maxPoints}{t("积分")}</p>}
                            </div>
                            <div className="td">{item.discountText}</div>
                        </div>
                    })
                }
            </div>
        </React.Fragment>
    )
}

export default forwardRef(Index);