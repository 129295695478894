import { Radio } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const Index = () => {
    const {t}=useTranslation();
    return (
        <React.Fragment>
            <div className="flex margint20 marginl20 sybox">
                <div className="center">
                    <img alt='' src={require('../../imgs/watermark.png')} className="block" />
                    <Radio className="margint20 font12 weight600">{t("系统水印")}</Radio>
                </div>
                <div className="center" style={{marginLeft: 100}}>
                    <img alt='' src={require('../../imgs/wsy.png')} className="block" />
                    <Radio className="margint20 font12 weight600">{t("无水印")}</Radio>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Index;