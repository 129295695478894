import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Tooltip,
  // theme,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Helper from "../../class/Helper";
import Price from "../../Common/Price";

const Index = (props, _ref) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  // const {
  //   token: { colorError },
  // } = theme.useToken();
  const { currency, symbol, rate } = useSelector((state) => {
    return state.infos;
  });
  const [open, setOpen] = useState(false);
  const [balanceInfo, setBalanceInfo] = useState({
    balance: {
      value: "0.00",
    },
  });
  const [list, setList] = useState([]);
  const [searchObj, setSearchObj] = useState({
    IsAddition: "",
    page: 1,
    size: 10,
  });
  const [total, setTotal] = useState(0);
  let typeobj = {
    1: t("账户余额"),
    2: "PayPal",
    3: t("支付宝"),
    10: t("人工")
  }

  const columns = [
    {
      title: t("交易时间"),
      dataIndex: "activityDate",
      render: (time) => Helper.utc2time(time),
    },
    {
      title: `${t("账户余额")}(${symbol})`,
      dataIndex: "currentBalance",
      render: (item) => Helper.convertPrice(item.value, rate),
    },
    {
      title: `${t("收入")}/${t("支出")}(${symbol})`,
      dataIndex: "balance",
      render: (balance, item) =>
        `${item.isAddition ? "+" : "-"}${Helper.convertPrice(balance.value, rate)}`,
    },
    {
      title: t("扣款方式"),
      dataIndex: "rechargeType",
      render: (val) => `${typeobj[val] || val}`,
    },
    {
      title: t("备注"),
      dataIndex: "description",
    },
  ];
  useEffect(() => {
    // 余额
    global.Ajax.GET("api/Balance/BalanceForMemberId").then((res) => {
      if (res.isSuccess) {
        setBalanceInfo(res.result);
      }
    });
  }, []);
  useEffect(() => {
    getList();
    //eslint-disable-next-line
  }, [searchObj]);
  const onCancel = () => {
    setOpen(false);
  };
  // 获取余额记录
  const getList = () => {
    let obj = `IsAddition=${searchObj.IsAddition}&PageIndex=${searchObj.page}&PageSize=${searchObj.size}`;
    if (searchObj.PeriodMax) {
      obj += `&PeriodMax=${searchObj.PeriodMax}&PeriodMin=${searchObj.PeriodMin}`;
    }
    global.Ajax.GET(`api/Balance/BalanceActivitiesList?${obj}`).then((res) => {
      if (res.isSuccess) {
        setList(res.result.items);
        searchObj.page == 1 && setTotal(res.result.pagination.availCnt);
      }
    });
  };
  return (
    <React.Fragment>
      <div className="moneybox">
        <div className="top">
          <p>{t("可用余额")} :</p>
          <div className="flexCenter">
            <p className="pri">
              <Price value={balanceInfo.balance.value} />
            </p>
            <Button
              onClick={() => setOpen(true)}
              type="primary"
              shape="round"
              style={{ background: "#333", width: 120, height: 40 }}
              className="marginlauto"
            >
              {t("充值")}
            </Button>
            <Tooltip
              title={
                <p className="font12">
                  {t(
                    "充值意味着你可以充值一些人民币币种的钱到你的Bccbuy账户钱包，直接使用余额支付可以给你节省时间。"
                  )}
                </p>
              }
            >
              <i className="iconfont icon-a-bangzhu14px marginl4 cursor"></i>
            </Tooltip>
            <Button
              shape="round"
              style={{ width: 120, height: 40, border: 0 }}
              className="marginl10"
              onClick={() => props.navigate("/user/balance/withdraw")}
            >
              {t("提现")}
            </Button>
            {/* <Tooltip title={(<p className="font12">{t("退款后资金按原路退回")}</p>)}> */}
            <i className="iconfont icon-a-bangzhu14px marginl4 cursor"></i>
            {/* </Tooltip> */}
          </div>
        </div>
        {/* <div className="bot flexCenter">
          <span className="font12 marginr10">
            {t("欠款")}
            <Tooltip
              title={
                <p className="font12">
                  {t(
                    "欠款指的是您未支付/未补款但我们优先帮您处理以免耽误时效的包裹费用、订单退换货运费或者是货到付款等费用。请及时偿还欠款，否则下次无法交易。"
                  )}
                </p>
              }
            >
              <i className="iconfont icon-a-bangzhu14px cursor font14"></i>
            </Tooltip>
            ：
          </span>
          <span className="weight600 marginr10" style={{ color: colorError }}>
            ¥ 0.00
          </span>
          <Button disabled shape="round" className="colorlv borderlv">
            {t("偿还欠款")}
          </Button>
          <Button
            onClick={() => props.navigate("/user/debtrecords")}
            type="link"
            className="marginlauto"
            style={{ display: "block" }}
          >
            {t("查看记录")}
          </Button>
        </div> */}
      </div>
      <div>
        <DatePicker.RangePicker
          className="marginr10"
          onChange={(_, date) => {
            setSearchObj({
              ...searchObj,
              PeriodMin: date[0],
              PeriodMax: date[1],
            });
          }}
        />
        <Select
          placeholder={t("类型")}
          options={[
            { label: "充值", value: 1 },
            { label: "扣款", value: 2 },
          ]}
          style={{ width: 180 }}
          className="marginr10"
          allowClear
          onChange={(e) => {
            console.log(e);
            setSearchObj({
              ...searchObj,
              IsAddition: e > 0 ? (e == 1 ? true : false) : "",
            });
          }}
        />
        {/* <Button onClick={()=>props.navigate('/user/bills')} type="link">{t("查看账单")}</Button> */}
      </div>
      <Table
        dataSource={list}
        columns={columns}
        rowKey={"id"}
        className="money_table"
        pagination={{
          pageSize: searchObj.size,
          total: total,
        }}
        onChange={(page) => {
          setSearchObj({
            ...searchObj,
            page: page.current,
            size: page.pageSize,
          });
        }}
      />
      {/* 充值 */}
      <Modal
        open={open}
        width={600}
        title={t("充值")}
        centered
        destroyOnClose
        onCancel={onCancel}
        className="disclaimer dzpzbox"
        onOk={() => {
          form.submit();
        }}
      >
        <Form
          form={form}
          colon={false}
          style={{ marginBottom: 50 }}
          autoComplete="off"
          onFinish={(data) => {
            props.navigate(`/checkout?value=${data.PayAmount}`);
          }}
        >
          <Form.Item
            label={t("充值金额")}
            name="PayAmount"
            rules={[{ required: true, message: t("请输入充值金额") }]}
          >
            <Input placeholder={t("请输入金额")} suffix="CNY" />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, cur) => prev.PayAmount != cur.PayAmount}
          >
            {({ getFieldValue }) => {
              let PayAmount = getFieldValue("PayAmount");
              return (
                currency != "CNY" &&
                PayAmount > 0 && (
                  <p className="font14 colorr3" style={{ marginLeft: 81 }}>
                    ≈ {symbol} {Helper.convertPrice(PayAmount, rate)}
                  </p>
                )
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default Index;
