import {
  Button,
  // DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Table,
  message,
} from "antd";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Price from "../../Common/Price";
import Helper from "../../class/Helper";
import "./withdraw.css";

const Index = (props, _ref) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const { TextArea } = Input;
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [returnopen, setreturnopen] = useState(false);
  const [list, setList] = useState([{}]);
  const [column, setcolumn] = useState({});
  const [total, settotal] = useState(0);
  const [balanceInfo, setBalanceInfo] = useState({
    balance: {
      value: "0.00",
    },
  });
  const OrderPayTypeEnum = {
    0: "",
    1: "余额",
    2: "Paypal",
    3: "支付宝",
    10: "人工",
    11: "余额扣除",
    Balance: "余额",
    Paypal: "Paypal",
    Alipay: "支付宝",
    Artificial: "人工",
  };
  const unitObj = {
    0: "Nil",
    1: "USD",
    2: "CNY",
    3: "EUR",
    4: "GBP",
    5: "CAD",
    6: "JPY",
    7: "KRW",
    8: "AUD",
    9: "HKD",
    10: "MYR",
    11: "TWD",
    12: "NOK",
  };
  const columns = [
    {
      title: t("交易单号"),
      dataIndex: "thirdOrderNumber",
    },
    {
      title: t("交易方式"),
      dataIndex: "payType",
      render: (payType) => {
        return <>{t(OrderPayTypeEnum[payType]) || payType}</>;
      },
    },
    {
      title: t("可提现额度"),
      dataIndex: "orderPrice",
      render: (orderPrice) => {
        return (
          <>
            {orderPrice?.value}
            {unitObj[orderPrice?.unit] || orderPrice?.unit}
          </>
        );
      },
    },
    {
      title: t("状态"),
      dataIndex: "payState",
      render: (payState, record) => {
        return (
          <>
            {t(
              Helper.getorderpaystate(
                payState,
                record.payScenario,
                record.tradeType
              )
            ) || payState}
          </>
        );
      },
    },
    {
      title: t("开始日期"),
      dataIndex: "createdTime",
      render: (createdTime) => {
        return <>{Helper.utc2time(createdTime)}</>;
      },
    },
    {
      title: t("操作"),
      dataIndex: "id",
      align: "center",
      width: 150,
      render: (_, item) => {
        if (item.tradeType == 1 || item.tradeType == "Pay") {
          return [
            <div className="flexAllCenter pubbtn font12 weight600">
              <p
                onClick={() => {
                  setcolumn(item);
                  setreturnopen(true);
                }}
              >
                {t("提现")}
              </p>
            </div>,
          ];
        }
        return [];
      },
    },
  ];
  // 提现申请
  const onFinish = (values) => {
    setloading(true);
    let url = "api/Withdrawal",
      type;
    let data = {
      amount: parseFloat(values.amount) || 0,
      unit: 2,
      remarks: values.remarks || "",
      payType: values.payType || 0,
      type: 125,
      wallet: values.wallet || "",
    };
    global.Ajax.hhtc_request(url, data, type).then((res) => {
      setloading(false);
      if (res.isSuccess) {
        message.success(res.message);
        form?.resetFields?.();
        setOpen(false);
      } else {
        message.error(res.message);
      }
    });
  };
  // 退款
  const CancelADeposit = (values) => {
    setloading(true);
    let url = "api/Withdrawal/cancelADeposit",
      type = "PUT";
    let wallet = column.thirdOrderNumber || "";
    let data = {
      amount: parseFloat(values.amount) || 0,
      unit: 2,
      remarks: "退款",
      payType: column.payType || 0,
      type: 125,
      wallet: wallet,
    };
    global.Ajax.hhtc_request(url, data, type).then((res) => {
      setloading(false);
      if (res.isSuccess) {
        message.success(res.message);
        form1?.resetFields?.();
        getList();
        setreturnopen(false);
      } else {
        message.error(res.message);
      }
    });
  };
  const getList = (pageindex = 1, pagesize = 10) => {
    global.Ajax.GET(
      `api/Common/PayList?PageSize=${pagesize}&PageIndex=${pageindex}`
    ).then((res) => {
      if (res.isSuccess) {
        settotal(
          res?.result?.pagination?.availCnt || res?.result?.items?.length
        );
        setList(res.result.items);
      } else {
        setList([]);
      }
    });
  };
  useEffect(() => {
    document.title = t("入账提现");
    global.Ajax.GET("api/Balance/BalanceForMemberId").then((res) => {
      if (res.isSuccess) {
        setBalanceInfo(res.result);
      }
    });
    getList();
    //eslint-disable-next-line
  }, []);
  const max = useMemo(() => {
    return Math.min(
      parseFloat(column?.orderPrice?.value || "0"),
      parseFloat(balanceInfo.balance.value || "0")
    );
  }, [column, balanceInfo]);
  const onCancel = () => {
    setreturnopen(false);
    setOpen(false);
  };
  return (
    <React.Fragment>
      <div class="withdrawpage">
        <div className="flex alignitems spacebeweent">
          <div className="flexCenter">
            <h1>
              {t("可提现记录")}
              &emsp;
              <Price
                className="font18 weight600"
                value={balanceInfo?.balance?.value}
              />
            </h1>
            <Button
              onClick={() => {
                form?.resetFields?.();
                setOpen(true);
              }}
              type="primary"
              className="marginl10"
            >
              {t("提现申请")}
            </Button>
          </div>
          <div onClick={() => props.navigate("/user/balance/withdrawrecord")}>
            <span style={{ color: "#169cff", cursor: "pointer" }}>
              {t("提现记录")}
            </span>
          </div>
        </div>
        <Table
          dataSource={list}
          columns={columns}
          pagination={{
            total: total,
          }}
          onChange={(e) => {
            getList(e.current, e.pageSize);
          }}
        />
      </div>

      {/* 提现 */}
      <Modal
        title={t("提示")}
        open={open}
        width={500}
        centered
        destroyOnClose
        maskClosable={false}
        footer={null}
        onCancel={onCancel}
        className="huihead CustomerWithdrawpage"
      >
        <Form colon={false} form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label={t("收款类型")}
            name="payType"
            rules={[{ required: true, message: t("请选择收款类型") }]}
          >
            <Radio.Group
              options={[
                { label: t("支付宝"), value: 3 },
                { label: "PayPal", value: 2 },
              ]}
            />
          </Form.Item>
          <Form.Item
            label={t("账号")}
            name="wallet"
            rules={[{ required: true, message: t("请输入提现账号") }]}
          >
            <Input placeholder={t("请输入")} />
          </Form.Item>
          <Form.Item
            label={t("提现金额")}
            name="amount"
            rules={[
              { required: true, message: t("请输入提现金额") },
              {
                type: "number",
                validator:(rule,value)=>{
                  return Helper.numbermessage(rule,value,0.01,parseFloat(balanceInfo?.balance?.value || "0"),t)
                }, // 自定义提示语
              },
            ]}
            className="maginb0"
          >
            <Input
              placeholder={t("请输入")}
              suffix="CNY"
              type="number"
              step={0.01}
            />
          </Form.Item>
          <span>
            {t("本次提现最高金额是")}&ensp;{balanceInfo?.balance?.value}
          </span>
          <Form.Item label={t("备注")} name="remarks" className="margint19">
            <TextArea placeholder={t("可填写您的备注信息")} />
          </Form.Item>
          <Button
            type="primary"
            shape="round"
            htmlType="submit"
            loading={loading}
            className="block marginlauto"
          >
            {t("确定")}
          </Button>
        </Form>
      </Modal>
      {/* 退款 */}
      <Modal
        title={t("提示")}
        open={returnopen}
        width={500}
        centered
        destroyOnClose
        maskClosable={false}
        footer={null}
        onCancel={onCancel}
        className="huihead"
      >
        <Form
          colon={false}
          layout="vertical"
          onFinish={CancelADeposit}
          form={form1}
        >
          <p>
            {" "}
            {t("本次提现最高金额是")}&ensp;{max}
          </p>
          <Form.Item
            label={t("提现金额")}
            name="amount"
            rules={[{ required: true, message: t("请输入提现金额") },
              {
                type: "number",
                validator: (rule, value) => {
                  return Helper.numbermessage(
                    rule,
                    value,
                    0.01,
                    parseFloat(max || "0"),
                    t
                  );
                }, // 自定义提示语
              }]}
          >
            <Input
              type="number"
              placeholder={t("请输入")}
              suffix="CNY"
              step={0.01}
            />
          </Form.Item>
          <Button
            type="primary"
            shape="round"
            htmlType="submit"
            loading={loading}
            className="block marginlauto"
          >
            {t("确定")}
          </Button>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default forwardRef(Index);
