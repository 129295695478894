import {
  Affix,
  App,
  Button,
  DatePicker,
  Dropdown,
  Empty,
  Form,
  Image,
  Modal,
  Pagination,
  Popover,
  Select,
  Tooltip,
  theme,
  Space,
  Tabs,
} from "antd";
import Input from "antd/es/input/Input";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import Helper from "../../class/Helper";
import OrderMask from "../../Common/OrderMark"; // 订单标识
import { useTranslation } from "react-i18next";
import Price from "../../Common/Price";
import Loading from "../../Common/Loading";
import PlatformIcon from "../../Common/PlatformIcon";

// 上传图片
const CustomUpload = () => {
  return (
    <div className="cursor dashbtn marginl10">
      <img alt="" src={require("../../imgs/jia.png")} className="width16" />
    </div>
  );
};

const Index = (props, _ref) => {
  const { t } = useTranslation();
  const {
    token: { colorError, colorErrorBg },
  } = theme.useToken();
  const btnRef = useRef(null);
  const [form] = Form.useForm();
  const { modal, message } = App.useApp();
  const [items, setItems] = useState([]);
  const [otherSearch, setOtherSearch] = useState(true);
  const [list, setList] = useState([]);
  const [obj, setObj] = useState({
    id: "",
    show: false,
  });
  const [timer, setTimer] = useState(null);
  const [sendLoading] = useState(false);
  const [sendMsg, setSendMsg] = useState(null);
  const OrderState = {
    1: {
      title: t("待支付"),
      tooltip: t(
        "订单已提交，正在等待付款，请在72小时内完成付款，否则订单会自动取消。"
      ),
    },
    2: {
      title: t("已支付"),
      tooltip: t("已支付"),
    },
    3: {
      title: t("已审核"),
      tooltip: t("已审核"),
    },
    4: {
      title: t("已采购"),
      tooltip: t("已采购"),
    },
    5: {
      title: t("已入库"),
      tooltip: t("已入库"),
    },
    8:{
      title: t("已完成"),
      tooltip: t("已完成"),
    },
    10: {
      title: t("取消订购"),
      tooltip: t("采购接单前，被取消的订单。采购接单前用户可以自行取消订单。"),
    },
    25: {
      title: t("失败"),
      tooltip: t("失败"),
    },
    40: {
      title: t("待退货"),
      tooltip: t("采购订单已发货，用户申请退货，等待商家处理。"),
    },
    41: {
      title: t("确认退货"),
      tooltip: t("用户申请退货，商家同意退货。"),
    },
    42: {
      title: t("退货中"),
      tooltip: t("退货中，商家正在处理中。"),
    },
  };
  const [bsOpen, setBsOpen] = useState(false);
  const [dzShow, setDzShow] = useState(false);
  const [bgOpen, setBgOpen] = useState(false);
  const [cancelObj, setCancelObj] = useState({
    orderNumber: "",
    remark: "",
  });
  const [cancelShow, setCancelShow] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [searchObj, setSearchObj] = useState({
    page: 1,
    size: 10,
    state: 0,
    OrderNumber: "",
    platForm: 0,
    tagIds: [],
  });
  const [listLoading, setListLoading] = useState(false);
  const [tagsList, setTagsList] = useState([]);
  const [total, setTotal] = useState(0);

  const [msgList, setMsgList] = useState([]);

  useEffect(() => {
    document.title = t("我的订单");
    if (items.length == 0) {
      getColors();
      getTagsList();
    }
    getList();
    //eslint-disable-next-line
  }, [
    searchObj.state,
    searchObj.OrderNumber,
    searchObj.PeriodMin,
    searchObj.page,
    searchObj.size,
    searchObj.tagIds,
    searchObj.platForm,
  ]);
  // 获取颜色
  const getColors = async () => {
    let data = await Helper.colorList();
    setItems(data);
  };
  // 获取标签
  const getTagsList = () => {
    global.Ajax.GET("api/Tag/List").then((res) => {
      if (res.isSuccess) {
        setTagsList(res.result);
      }
    });
  };
  const getMsgList = (orderNumber) => {
    global.Ajax.GET("api/OrderMessage/List?orderNumber=" + orderNumber).then(
      (res) => {
        if (res.isSuccess) {
          setMsgList(res.result);
        }
      }
    );
  };
  const onCancel = () => {
    setBsOpen(false);
    setDzShow(false);
    setBgOpen(false);
    setCancelShow(false);
  };
  // 获取订单列表
  const getList = () => {
    setListLoading(true);
    global.Ajax.hhtc_request("api/SaleOrder/GetSaleOrders", {
      pageIndex: searchObj.page,
      pageSize: searchObj.size,
      state: searchObj.state,
      orderNumber: searchObj.OrderNumber,
      periodMin: searchObj.PeriodMin,
      periodMax: searchObj.PeriodMax,
      platForm: searchObj.platForm,
      tagIds: searchObj.tagIds,
    }).then((res) => {
      if (res.isSuccess) {
        setList(res.result.items);
        searchObj.page == 1 && setTotal(res.result.pagination.availCnt);
      }
      setListLoading(false);
    });
  };
  // 删除订单
  const del = (orderNumber) => {
    modal.confirm({
      title: t("提示"),
      content: t("确定需要删除订单吗？该商品不会退回给卖家来得到退款。"),
      centered: true,
      autoFocusButton: false,
      onOk: () => {
        global.Ajax.hhtc_request(
          `api/SaleOrder/DelOrder?orderNumber=${orderNumber}`,
          {},
          "DELETE"
        ).then((res) => {
          if (res.isSuccess) {
            message.success(res.message);
            getList();
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };
  // 取消支付
  const cancelOrder = (data) => {
    setCancelLoading(true);
    data.orderNumber = cancelObj.orderNumber;
    global.Ajax.hhtc_request("api/SaleOrder/CancelOrder", data, "PUT").then(
      (res) => {
        if (res.isSuccess) {
          message.success(res.message);
          onCancel();
          getList();
        } else {
          message.error(res.message);
        }
        setCancelLoading(false);
      }
    );
  };
  // 添加标签
  const setTag = (orderProductId, tagId) => {
    global.Ajax.hhtc_request("api/ProductDetailTag/Add", {
      orderProductId,
      tagId,
    }).then((res) => {
      if (res.isSuccess) {
        getList();
      }
    });
  };
  // 删除标签
  const delTag = (id) => {
    global.Ajax.hhtc_request(
      `api/ProductDetailTag/Delete/${id}`,
      {},
      "DELETE"
    ).then((res) => {
      if (res.isSuccess) {
        getList();
      }
    });
  };
  const tabs = [
    {
      label: "全部",
      key: 0,
    },
    {
      label: "待付款",
      key: 1,
    },
    {
      label: "已付款",
      key: 2,
    },
    {
      label: "已审核",
      key: 3,
    },
    {
      label: "已采购",
      key: 4,
    },
    {
      label: "已入库",
      key: 5,
    },
    {
      label: "已完成",
      key: 8,
    },
    {
      label: "已取消",
      key: 10,
    },
    {
      label: "待退货",
      key: 40,
    },
    {
      label: "确认退货",
      key: 41,
    },
    {
      label: "退货中",
      key: 42,
    },
  ];
  const onHideMsg = () => {
    console.log("关闭", timer);
    setMsgList([]);
    if (timer) {
      clearInterval(timer);
      setTimer(null);
    }
  };
  const onShowMsg = (orderNumber) => {
    console.log("开启", timer);
    getMsgList(orderNumber);

    if (timer) {
      clearInterval(timer);
      setTimer(null);
    }
    setTimer(
      setInterval(() => {
        getMsgList(orderNumber);
      }, 3000)
    );
    console.log("开启后", timer);
  };
  return (
    <React.Fragment>
      <div className="flexCenter">
        <Tabs
          activeKey={searchObj.state}
          items={tabs.map((item)=>{
            item.label = t(item.label)
            return item
          })}
          style={{ width: "100%" }}
          tabPosition="top"
          onChange={(key) => {
            setSearchObj({
              ...searchObj,
              state: key,
            });
          }}
        />
        {/* <p className="marginlauto">
          <i className="iconfont icon-shijianpaixu_jiangxu"></i>
          {t("时间排序")}
        </p> */}
      </div>
      <div>
        <Input
          onChange={(e) => {
            setSearchObj({
              ...searchObj,
              OrderNumber: e.target.value,
            });
          }}
          placeholder={`${t("名称")}/${t("主订单")}/${t("商品单号")}`}
          style={{ width: 280 }}
          suffix={<i className="iconfont icon-dingbusousuo"></i>}
        />
        <span
          onClick={() => setOtherSearch(!otherSearch)}
          className="marginl10 cursor otherLock"
        >
          {t("精简筛选条件")}
          <i
            className={`iconfont ${
              otherSearch ? "icon-xuankuangshouqi" : "icon-xuankuangzhankai"
            }`}
          ></i>
        </span>
        <div className={`row10 flwp others ${otherSearch ? "active" : ""}`}>
          <Select
            popupClassName="colorboxs"
            className="colorselt margint10 marginr10"
            placeholder={t("标识颜色")}
            popupMatchSelectWidth={false}
            options={items}
            style={{ width: 144 }}
            mode="multiple"
            fieldNames={{ label: "key" }}
            optionRender={(option) => (
              <span
                className="colorkk"
                style={{ background: option.data.key }}
              ></span>
            )}
            tagRender={(props) => {
              return (
                <span
                  className="colorkk"
                  style={{ background: props.label, marginLeft: -10 }}
                ></span>
              );
            }}
            onChange={(e) => {
              setSearchObj({
                ...searchObj,
                tagIds: e,
              });
            }}
          />
          <Select
            placeholder={t("选择平台")}
            options={[
              { label: t("淘宝"), value: 1 },
              { label: t("1688"), value: 2 },
              { label: t("京东"), value: 3 },
            ]}
            className="margint10 marginr10"
            allowClear
            style={{ width: 140 }}
            onChange={(platForm) => {
              setSearchObj({
                ...searchObj,
                platForm,
              });
            }}
          />
          <DatePicker.RangePicker
            className="margint10 marginr10"
            onChange={(_, date) => {
              setSearchObj({
                ...searchObj,
                PeriodMin: date[0],
                PeriodMax: date[1],
              });
            }}
          />
        </div>
      </div>
      {/* 列表 */}
      <div className="order_table" style={{ minHeight: 800 }}>
        <div
          className="flex font12 weight600 center"
          style={{
            borderTop: "1px solid #ddd",
            borderBottom: "1px solid #ddd",
            padding: "12px 0",
          }}
        >
          <div className="rowFlex infos">{t("商品详情")}</div>
          <div className="pribox">{t("单价")}</div>
          <div className="numbox">{t("数量1")}</div>
          <div className="ztbox">{t("订单状态")}</div>
          <div className="fjbox">{t("附加服务")}</div>
          <div className="totalbox" style={{ borderRight: 0 }}>
            {t("订单总额")}
          </div>
          <div className="totalbox" style={{ borderRight: 0 }}>
            {t("实付金额")}
          </div>
          <div className="czbox">{t("其他操作")}</div>
        </div>
        {/* 加载中 */}
        {listLoading && <Loading style={{ paddingTop: 100 }} />}
        {/* 空数据 */}
        {!listLoading && list.length == 0 && (
          <Empty style={{ marginTop: 100 }} />
        )}
        {/* 表内容 */}
        {!listLoading &&
          list.map((item, index) => (
            <div className="card_item" key={String(index)}>
              <div className="flexCenter font12 tbox">
                <PlatformIcon platform={item.platForm} className="marginl10" />
                <span className="weight600 marginl10 rowFlex line1">
                  {t("订单编号")}: {item.orderNumber}
                </span>
                <p className="marginl10">
                  {t("创建时间")}: {Helper.utc2time(item.createdTime)}{" "}
                </p>
                <p className="weight600 marginl10">
                  {t("仓库")}: Bccbuy{t("仓库")}
                </p>
                <p className="marginlauto color9 cursor">
                  {t("采购员")}:Preanna
                </p>
                <p
                  className="weight600 marginl10 cursor"
                  style={{ color: colorError }}
                >
                  {t("打赏TA")}
                </p>
                <p
                  className="marginl10 ydsbtn"
                  style={{ color: colorError, background: colorErrorBg }}
                >
                  {t("已打赏")}
                </p>
                <p className="colorlv marginl10 cursor">{t("咨询")}</p>
                {item.orderState == 10 && (
                  <p
                    onClick={() => del(item.orderNumber)}
                    className="colorlv cursor marginlauto"
                  >
                    {t("删除订单")}
                  </p>
                )}
              </div>
              <div className="child">
                {item.products &&
                  item.products.map((row, k) => (
                    <div className="item" key={String(k)}>
                      <div className="flex cellinfo">
                        <div className="infos flex rowFlex">
                          <img
                            alt=""
                            src={row.picUrl}
                            className="pic marginl10"
                          />
                          <div className="wzk rowFlex marginl10">
                            <p
                              onClick={() => {
                                props.navigate(
                                  `/product?platform=${item.platForm}&num_iid=${row.productId}`
                                );
                              }}
                              className="line2 title cursor"
                            >
                              {row.title}
                            </p>
                            <p className="line2 color_type color9 margint4">
                              {row.propertiesName}
                            </p>
                          </div>
                        </div>
                        <div className="pribox">
                          <Price value={row.price.value} />
                        </div>
                        <div className="numbox">
                          <span className="">x {row.num}</span>
                        </div>
                        <div className="ztbox">
                          <React.Fragment>
                            <span>{OrderState[item.orderState]?.title} </span>
                            <Tooltip
                              title={
                                <p className="font12">
                                  {OrderState[item.orderState]?.tooltip}
                                </p>
                              }
                            >
                              <i className="iconfont icon-a-bangzhu14px font12 cursor weight600"></i>
                            </Tooltip>
                          </React.Fragment>
                        </div>
                        <div className="fjbox borderr">
                          <p>{t("提交包裹")}</p>
                          <p
                            onClick={() => {
                              setDzShow(true);
                            }}
                          >
                            {t("定制拍照")}
                          </p>
                          <p
                            onClick={() => {
                              setBgOpen(true);
                            }}
                          >
                            {t("延长保管")}
                          </p>
                        </div>
                        {/* 订单总额 */}
                        <div className="totalbox">
                          {/* 一个店铺多个商品只显示一个 */}
                          {k == 0 && (
                            <React.Fragment>
                              <Price value={item.amount.value} />
                            </React.Fragment>
                          )}
                        </div>
                        {/* 实付金额 */}
                        <div className="totalbox">
                          {/* 一个店铺多个商品只显示一个 */}
                          {k == 0 && (
                            <React.Fragment>
                              <Price value={item.payAmount.value} />
                            </React.Fragment>
                          )}
                        </div>
                        <div className="czbox">
                          {k == 0 && (
                            <React.Fragment>
                              {/* 订单待支付 */}
                              {item.orderState == 1 && (
                                <React.Fragment>
                                  {/* <CountDown /> */}
                                  <Button
                                    onClick={() =>
                                      props.navigate(
                                        `/checkout?orderNumber=${item.orderNumber}`
                                      )
                                    }
                                    type="primary"
                                    size="small"
                                    className="font12"
                                  >
                                    {t("去支付")}
                                  </Button>
                                </React.Fragment>
                              )}
                              {item.orderState < 8 && (
                                <p
                                  onClick={() => {
                                    setCancelObj({
                                      orderNumber: item.orderNumber,
                                    });
                                    setCancelShow(true);
                                  }}
                                  className="cursor center margint10"
                                >
                                  {item.orderState == 1
                                    ? t("取消支付")
                                    : t("取消订单")}
                                </p>
                              )}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                      {/* 其他操作 */}
                      <div className="flex">
                        <div className="othercz">
                          <p style={{ marginLeft: -90 }}>
                            <Tooltip
                              placement="bottom"
                              title={
                                <p className="font12 colorbai">
                                  {t(
                                    "不可折叠：该商品为皮制品/易损品，不支持折叠，请您知悉。"
                                  )}
                                </p>
                              }
                            >
                              <i className="iconfont icon-bukezhedie colorlv cursor"></i>
                            </Tooltip>
                          </p>
                          <div className="flexCenter bqbox">
                            <span>{t("标签")}：</span>
                            <Popover
                              content={
                                <React.Fragment>
                                  <Button
                                    onClick={() => {
                                      setBsOpen(true);
                                    }}
                                    type="default"
                                    size="small"
                                    shape="round"
                                    className="font12"
                                    style={{ lineHeight: "22px" }}
                                  >
                                    +{t("创建新标签")}
                                  </Button>
                                  {Helper.filterArray(tagsList, row.tags).map(
                                    (m, n) => (
                                      <Button
                                        key={String(n)}
                                        onClick={() => setTag(row.id, m.id)}
                                        type="primary"
                                        size="small"
                                        className="font12 margint10"
                                        style={{
                                          display: "block",
                                          background: m.colour,
                                          lineHeight: "22px",
                                        }}
                                      >
                                        {m.name}
                                      </Button>
                                    )
                                  )}
                                </React.Fragment>
                              }
                            >
                              <i className="iconfont icon-tianjiabiaoqian cursor"></i>
                            </Popover>
                            {/* 订单已添加标签 */}
                            {row.tags &&
                              row.tags.map((m, n) => (
                                <Dropdown
                                  menu={{
                                    items: [
                                      {
                                        key: "1",
                                        label: (
                                          <span
                                            className="font12"
                                            onClick={() => delTag(m.id)}
                                          >
                                            <i className="iconfont icon-dingdanhuishouzhan font14"></i>
                                            {t("删除")}
                                          </span>
                                        ),
                                      },
                                    ],
                                  }}
                                  overlayClassName="delboxxx"
                                  arrow={{
                                    pointAtCenter: true,
                                  }}
                                  placement="bottom"
                                  key={String(n)}
                                >
                                  <Button
                                    type="primary"
                                    shape="round"
                                    size="small"
                                    className="font12 marginl10"
                                    style={{
                                      background: m.tagColour,
                                      paddingTop: 1,
                                    }}
                                  >
                                    {m.tagName}
                                  </Button>
                                </Dropdown>
                              ))}
                          </div>
                          {/* 备注信息 */}
                          <div className="flex bzkk">
                            <p className="color9">{t("商品备注信息")}：</p>
                            <p className="rowFlex">{row.remark}</p>
                          </div>
                          {/* 商品照片 */}
                          <div className="goods_photo bgf5 flexCenter">
                            <p className="color9 marginr10">{t("普通拍照")}</p>
                            <div className="rowFlex">
                              <Image
                                src={require("../../imgs/pic.png")}
                                width={40}
                                height={40}
                              />
                              <Image
                                src={require("../../imgs/pic.png")}
                                width={40}
                                height={40}
                              />
                            </div>
                            <i className="iconfont icon-download"></i>
                            <Popover
                              content={
                                <div>
                                  <p className="font14">
                                    <i
                                      className="iconfont icon-fuzhilianjie"
                                      style={{
                                        color: "#f7a348",
                                        marginRight: 6,
                                      }}
                                    ></i>
                                    {t("复制链接")}
                                  </p>
                                  <p>
                                    <i
                                      className="iconfont icon-reddit"
                                      style={{
                                        color: "#ff4500",
                                        marginRight: 6,
                                      }}
                                    ></i>
                                    Reddit
                                  </p>
                                </div>
                              }
                            >
                              <i className="iconfont icon-fenxiangzhaopian"></i>
                            </Popover>
                          </div>
                          {/* 服务延长保管期 */}
                          <div className="fwbgq flexCenter font12 margint10">
                            <p className="a_title line1">
                              {t("【服务】延长保管期")}
                            </p>
                            <p className="a_price">¥ 10.00 x 1</p>
                            <p className="a_qty">{t("待支付")}</p>
                            <p className="a_price"></p>
                            <div className="a_control flexAllCenter">
                              <Button
                                type="primary"
                                size="small"
                                className="font12"
                              >
                                {t("去支付")}
                              </Button>
                              <p className="colorlv marginl4 cursor">
                                {t("取消支付")}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="totalbox"></div>
                        <div className="totalbox"></div>
                      </div>
                      {/* 历史消息 */}
                      <div className="flex">
                        <div className="historybox">
                          {msgList.length == 0 &&
                            obj.show &&
                            obj.id == `${index}-${k}` && <Empty />}
                          {msgList.length > 0 &&
                            msgList.map((msg, kk) => {
                              if (msg.senderId == item.memberId) {
                                return (
                                  <div
                                    className="cont user"
                                    style={{
                                      display:
                                        obj.show && obj.id == `${index}-${k}`
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <p className="nick">
                                      {msg.sender}(
                                      {Helper.utc2time(msg.createdTime)})
                                    </p>
                                    <p className="neir">{msg.content}</p>
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    className="cont"
                                    style={{
                                      display:
                                        obj.show && obj.id == `${index}-${k}`
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <p className="nick">
                                      {msg.sender}(
                                      {Helper.utc2time(msg.createdTime)})
                                    </p>
                                    <p className="neir">{msg.content}</p>
                                  </div>
                                );
                              }
                            })}
                          {item.orderMessages.length > 0 &&
                            item.orderMessages.map((msg, kk) => {
                              if (msg.senderId == item.memberId) {
                                return (
                                  <div
                                    className="cont user"
                                    style={{
                                      display:
                                        obj.show && obj.id == `${index}-${k}`
                                          ? "none"
                                          : "block",
                                    }}
                                  >
                                    <p className="nick">
                                      {msg.sender}(
                                      {Helper.utc2time(msg.createdTime)})
                                    </p>
                                    <p className="neir">{msg.content}</p>
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    className="cont"
                                    style={{
                                      display:
                                        obj.show && obj.id == `${index}-${k}`
                                          ? "none"
                                          : "block",
                                    }}
                                  >
                                    <p className="nick">
                                      {msg.sender}(
                                      {Helper.utc2time(msg.createdTime)})
                                    </p>
                                    <p className="neir">{msg.content}</p>
                                  </div>
                                );
                              }
                            })}

                          <div
                            className={"msgInput"}
                            style={{
                              display:
                                obj.show && obj.id == `${index}-${k}`
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <Space.Compact
                              style={{
                                width: "100%",
                              }}
                            >
                              <Input
                                value={sendMsg}
                                onChange={(e) => {
                                  setSendMsg(e.target.value);
                                }}
                              />
                              <Button
                                onClick={() => {
                                  global.Ajax.hhtc_request(
                                    "api/OrderMessage/AddMsg",
                                    {
                                      OrderNumber: item.orderNumber,
                                      Content: sendMsg,
                                    },
                                    "POST"
                                  ).then((res) => {
                                    if (res.isSuccess) {
                                      // getList();
                                      item.orderMessages.push({
                                        sender: item.memberName,
                                        senderId: item.memberId,
                                        createdTime: new Date().toDateString(),
                                        content: sendMsg,
                                      });
                                      list[index] = item;
                                      setList(list);
                                      setSendMsg(null);
                                      getMsgList(item.orderNumber);
                                    }
                                  });
                                }}
                                disabled={sendMsg == null || sendMsg == ""}
                                loading={sendLoading}
                                type="primary"
                              >
                                {t("发送")}
                              </Button>
                            </Space.Compact>
                          </div>
                          <p
                            onClick={() => {
                              if (obj.id != `${index}-${k}`) {
                                //一定是开启一个新的
                                onShowMsg(item.orderNumber);
                                setObj({
                                  id: `${index}-${k}`,
                                  show: true,
                                });
                              } else {
                                if (obj.show) {
                                  onHideMsg();
                                } else {
                                  onShowMsg(item.orderNumber);
                                }
                                setObj({
                                  id: `${index}-${k}`,
                                  show: !obj.show,
                                });
                              }
                            }}
                            className="more flexAllCenter"
                          >
                            {t("历史消息")}
                            <i
                              className={`iconfont ${
                                obj.show && obj.id == `${index}-${k}`
                                  ? "icon-shouqi_16"
                                  : "icon-zhankai_24"
                              }`}
                            ></i>
                          </p>
                        </div>
                        <div className="totalbox"></div>
                        <div className="totalbox"></div>
                        <div className="czbox"></div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
      {list.length > 0 && (
        <Affix offsetBottom={0}>
          {/* 底部操作栏 */}
          <div className="fixbot flexCenter bgbai">
            {total > 0 && (
              <Pagination
                className="marginlauto"
                total={total}
                showSizeChanger
                showQuickJumper
                showTotal={() => {
                  return `${t("共条", {
                    num: total,
                  })}`;
                }}
                pageSize={searchObj.size}
                onChange={(page, size) => {
                  setSearchObj({
                    ...searchObj,
                    page,
                    size,
                  });
                }}
                onShowSizeChange={(page, size) => {
                  setSearchObj({
                    ...searchObj,
                    page,
                    size,
                  });
                }}
              />
            )}
          </div>
        </Affix>
      )}
      {/* 订单标识 */}
      <Modal
        title={t("订单标识")}
        width={600}
        centered
        onCancel={onCancel}
        open={bsOpen}
        footer={null}
        className="disclaimer"
        destroyOnClose={true}
      >
        <OrderMask
          onOk={() => {
            onCancel();
          }}
        />
      </Modal>
      {/* 定制拍照 */}
      <Modal
        open={dzShow}
        title={t("定制拍照")}
        width={800}
        onCancel={onCancel}
        centered
        destroyOnClose
        // footer={null}
        className="disclaimer dzpzbox"
        onOk={() => {
          form.submit();
        }}
      >
        <div className="finebox flex">
          <img alt="" src={require("../../imgs/pic.png")} className="pic" />
          <div className="rowFlex gtitle">
            <p>新品阳晨软毛牙刷竹炭细丝独立包装成人牙刷跨境外贸源头工厂批发</p>
            <p>x 1</p>
          </div>
          <div className="flexCenter">
            <span>{t("数量：")}</span>
            <div className="flexCenter goods_num">
              <img
                alt=""
                src={require("../../imgs/jian.png")}
                className="width16 cursor"
              />
              <Input
                bordered={false}
                defaultValue={1}
                className="rowFlex"
                style={{ textAlign: "center" }}
              />
              <img
                onClick={() => {
                  btnRef.current.click();
                }}
                alt=""
                src={require("../../imgs/jia.png")}
                className="width16 cursor"
              />
            </div>
          </div>
        </div>
        <p className="font14 margint20">{t("拍照模板")}：</p>
        <div className="cont font12">
          <Form colon={false} layout="vertical" form={form}>
            <Form.List name="info" initialValue={[{}]}>
              {(fields, { add, remove }) => (
                <React.Fragment>
                  {fields.map((field) => (
                    <div className="flexCenter" key={field.key}>
                      <Form.Item
                        className="txtt"
                        label={`${t("照片")}1`}
                        name={[field.name, "name"]}
                        rules={[
                          { required: true, message: t("请输入备注内容") },
                        ]}
                      >
                        <Input.TextArea
                          className="font12"
                          rows={2}
                          placeholder={t(
                            "仅支持输入1个拍照要求，例如：拍摄logo近照、测量手链尺寸之类的"
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        className="btn"
                        label=" "
                        name={[field.name, "img"]}
                      >
                        <CustomUpload />
                      </Form.Item>
                      {fields.length > 1 && (
                        <i
                          onClick={() => remove(field.name)}
                          className="iconfont icon-dingdanhuishouzhan cursor"
                        ></i>
                      )}
                    </div>
                  ))}
                  <p ref={btnRef} onClick={() => add()}></p>
                </React.Fragment>
              )}
            </Form.List>
          </Form>
        </div>
        <p className="tipss">
          {t("温馨提示：一张照片仅提供拍摄一件商品的一个细节要求")}
        </p>
        <p className="service">
          {t("本月剩余免费定制拍照张服务费：", {
            num: 0,
            price: "2.00",
          })}
        </p>
      </Modal>
      {/* 延长保管 */}
      <Modal
        open={bgOpen}
        title={t("延长保管")}
        width={800}
        onCancel={onCancel}
        centered
        destroyOnClose
        className="disclaimer dzpzbox"
      >
        <div className="finebox flex">
          <img alt="" src={require("../../imgs/pic.png")} className="pic" />
          <div className="rowFlex gtitle">
            <p>新品阳晨软毛牙刷竹炭细丝独立包装成人牙刷跨境外贸源头工厂批发</p>
            <p>x 1</p>
          </div>
          <Select
            options={[
              { label: "1个月", value: 1 },
              { label: "2个月", value: 2 },
              { label: "3个月", value: 3 },
            ]}
            placeholder={t("请选择")}
            style={{ width: 180 }}
          />
        </div>
        <div className="finebox flex" style={{ marginTop: 2 }}>
          <img
            alt=""
            src={require("../../imgs/warn.png")}
            className="width16"
          />
          <span>
            &ensp;{t("您的商品在仓库的报关时间将会延长（30*N）天，到期时间为")}
            &ensp;
          </span>
          <span className="colorjh">2024-04-05 16:28:30</span>
        </div>
        <p className="service">{t("服务费")}：¥ 2.00</p>
      </Modal>
      {/* 取消支付 */}
      <Modal
        open={cancelShow}
        width={560}
        title={`${t("取消支付")}？`}
        centered={true}
        destroyOnClose={true}
        footer={null}
        onCancel={onCancel}
      >
        <p className="color6 margint20">
          {t("取消后订单无法恢复，请选择取消支付原因")}
        </p>
        <Form onFinish={cancelOrder}>
          <Form.Item
            name="remark"
            rules={[{ required: true, message: t("请选择退单原因") }]}
          >
            <Select
              placeholder={t("请选择退单原因")}
              style={{ width: "100%", background: "#f5f5f5" }}
              bordered={false}
              className="margint10"
              options={[
                { label: t("我不想买了"), value: "我不想买了" },
                { label: t("信息填写有误"), value: "信息填写有误" },
                { label: t("卖家缺货"), value: "卖家缺货" },
                { label: t("无法付款"), value: "无法付款" },
                { label: t("其他原因"), value: "其他原因" },
              ]}
            />
          </Form.Item>
          <div
            className="flexCenter paddt12"
            style={{ justifyContent: "flex-end" }}
          >
            <Button onClick={onCancel}>{t("取消")}</Button>
            <Button
              loading={cancelLoading}
              type="primary"
              htmlType="submit"
              className="marginl10"
            >
              {t("确定")}
            </Button>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default forwardRef(Index);
