import { Spin } from "antd";
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";

const Loading = (props, _ref) => {
    const { t } = useTranslation();
    return (
        <div className="row10 center paddb12" style={props.style || {}}>
            <Spin></Spin>
            <p className="font14 color9 margint10 paddb12">{t("努力加载中")}...</p>
        </div>
    )
}

export default forwardRef(Loading);