import { App, Button, Form, Input, Popover, Radio, Switch, Tabs } from "antd";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Helper from "../class/Helper";


const SelectCountry = forwardRef(({ value = '', onChange }, _ref) => {
    const { t } = useTranslation();
    const tabs = [
        { label: 'HOT', key: 'HOT' },
        { label: 'ABC', key: 'ABC' },
        { label: 'DEF', key: 'DEF' },
        { label: 'GHI', key: 'GHI' },
        { label: 'JKL', key: 'JKL' },
        { label: 'MNO', key: 'MNO' },
        { label: 'PQR', key: 'PQR' },
        { label: 'STU', key: 'STU' },
        { label: 'VWY', key: 'VWY' },
        { label: 'Z', key: 'Z' },
        { label: 'China', key: 'China' },
    ];
    const [tabList, setTabList] = useState([]);
    const [data, setData] = useState([]);
    const [obj, setObj] = useState({})
    const [open, setOpen] = useState(false);
    const [activeKey, setActiveKey] = useState('HOT');
    useEffect(() => {
        getCountry();
        //eslint-disable-next-line
    }, []);
    useImperativeHandle(_ref, () => ({
        setOpen,
        setObj,
    }))
    const getCountry = async () => {
        let country = await Helper.getCountry();
        let list = [];
        for (let i in country) {
            let index = tabs.findIndex(item => item.label != 'HOT' && item.label.indexOf(country[i].nameEn[0]) != -1);
            list[index] = list[index] || [];
            list[index].push(country[i])
        }
        // 热门
        let hot = Helper.getHot(country);
        // 中国
        let arr = Helper.getChinaList();
        list[0] = hot;
        setTabList([
            ...list,
            arr,
        ]);
        setData([
            ...hot,
        ])
    }
    const triggerChange = (data) => {
        onChange?.(data);
    };
    return (
        <React.Fragment>
            <Popover
                fresh
                content={(
                    <div className="contrybox">
                        <Tabs
                            items={tabs}
                            activeKey={activeKey}
                            onTabClick={(key) => {
                                setActiveKey(key);
                                let k = tabs.findIndex(item => item.key == key);
                                setData([
                                    ...tabList[k],
                                ])
                            }}
                        />
                        <div className="flwp countrylist">
                            {data.map((item, index) => (
                                <div onClick={() => {
                                    let obj = item;
                                    if (item.icon == 'cn'&&item.name!='中国大陆') {
                                        obj = { nameEn: 'China', name: '中国大陆', icon: 'cn', value: `CN-${item.value}` };
                                    }
                                    setObj(obj);
                                    setOpen(false);
                                    triggerChange(obj.value);
                                }} className="item flex font12" key={String(index)}>
                                    <img alt='' src={require(`../imgs/icon/${item.icon}.png`)} className="gqicon" />
                                    <div className="rowFlex marginl4">
                                        <p>{item.name}</p>
                                        <p>{item.nameEn}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                open={open}
            >
                <div onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setOpen(true);
                }} className="flexCenter cursor" style={{ border: '1px solid #d9d9d9', borderRadius: 6 }}>
                    {!obj.icon && <i className="iconfont icon-guojia marginl4" style={{ color: '#c0c4cc' }}></i>}
                    {obj.icon && <img alt='' src={require(`../imgs/icon/${obj.icon}.png`)} className="gqicon marginl4" />}
                    <Input value={obj.icon=='cn'?obj.name:obj.nameEn} className="rowFlex" readOnly placeholder={t("国家")} bordered={false} />
                    <i className="iconfont icon-xuankuangzhankai marginr4"></i>
                </div>
            </Popover>
        </React.Fragment>
    )
})

const EditAddress = (props, _ref) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const seltRef = useRef(null);
    const { message } = App.useApp();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (props.pageType == 'edit') {
            let data = props.data;
            getCountry(data.nation)
            form.setFieldsValue({
                ...data,
            })
        }
        //eslint-disable-next-line
    }, []);
    const getCountry = async (nation) => {
        let country = await Helper.getCountry();
        // 中国
        let arr = Helper.getChinaList();
        let list = [
            ...country,
            ...arr,
        ]
        let row = list.find(item => item.value == nation);
        row && seltRef.current.setObj(row);
    }
    const onValuesChange = (res, values) => {
        let key = Object.keys(res)[0];
        if (key == 'nation') {
            let arr=values.nation.split('-');
            if(arr.length>1){
                let list = Helper.getChinaList();
                // 首字母排序
                list.sort((a, b) => a['value'].localeCompare(b['value']));
                form.setFieldsValue({
                    nation: arr[0],
                    province: arr[1],
                    city: undefined,
                })
            }else{
                form.setFieldsValue({
                    province: undefined,
                    city: undefined,
                }) 
            }
        }
    }
    const onFinish = (data) => {
        setLoading(true)
        let url = 'api/Address/Add',
            type;
        if (props.pageType == 'edit') {
            url = 'api/Address/Edit';
            type = "PUT";
            data.id = props.data.id;
        }
        global.Ajax.hhtc_request(url, data, type).then(res => {
            if (res.isSuccess) {
                message.success(res.message);
                props.onOk?.();
            } else {
                message.error(res.message);
            }
            setLoading(false);
        })
    }
    return (
        <React.Fragment>
            <Form
                colon={false}
                autoComplete="off"
                labelCol={{ flex: '130px' }}
                onClick={() => {
                    seltRef.current.setOpen(false);
                }}
                onFinish={onFinish}
                form={form}
                onValuesChange={onValuesChange}
                className="editbox"
            >
                <div className="flex">
                    <Form.Item className="item49" label={t('收件人')} name='name' rules={[{ required: true, message: t('名不能为空') }]}>
                        <Input placeholder={t("名")} />
                    </Form.Item>
                    <Form.Item className="item49" name='sname' rules={[{ required: true, message: t('姓不能为空') }]}>
                        <Input placeholder={t("姓")} />
                    </Form.Item>
                </div>
                <div className="flex">
                    <Form.Item style={{ width: '40%' }} label={t('所在地区')} name='nation' rules={[{ required: true, message: t('国家不能为空') }]}>
                        <SelectCountry ref={seltRef} />
                    </Form.Item>
                    <Form.Item className="flex1 marginl10 " name='province' rules={[{ required: true, message: t('省不能为空') }]}>
                        <Input placeholder={t("省/州")} />
                    </Form.Item>
                    <Form.Item className="flex1 marginl10" name='city' rules={[{ required: true, message: t('城市不能为空') }]}>
                        <Input placeholder={t("城市")} />
                    </Form.Item>
                </div>
                <Form.Item label={t('详细地址')} name='address1' rules={[{ required: true, message: t('地址不能为空') }]}>
                    <Input.TextArea rows={2} placeholder={t("请输入公寓门牌号，街道地址等详细地址信息")} />
                </Form.Item>
                <Form.Item label={t('邮编')} name='postCode' rules={[{ required: true, message: t('邮编不能为空') }]}>
                    <Input placeholder={t("邮编")} />
                </Form.Item>
                <Form.Item label={t('手机号')} name='phone' rules={[{ required: true, message: t('手机号不能为空') }]}>
                    <Input placeholder={t("手机号")} />
                </Form.Item>
                {props.type != 'card' && <React.Fragment>
                    <Form.Item label={t('地址标签')} name='tag' rules={[{ required: true, message: t('请选择地址标签') }]}>
                        <Radio.Group
                            options={[
                                { label: t('家'), value: 1 },
                                { label: t('工作'), value: 2 },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item initialValue={false} label={t('默认地址')} name='default' valuePropName="checked">
                        <Switch />
                    </Form.Item>
                </React.Fragment>}
                <Button loading={loading} type="primary" shape="round" htmlType="submit">{t("确定")}</Button>
            </Form>
        </React.Fragment>
    )
}

export default EditAddress;