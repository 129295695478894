import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // 使用localStorage
import { configureStore,combineReducers } from '@reduxjs/toolkit';
import infos from './modules/info.js';
 
// 定义你的reducer
const rootReducer = combineReducers({
  // ... 其他reducers
  infos,
});
 
// 创建persist配置
const persistConfig = {
  key: 'root',
  storage,
//   stateReconciler: autoMergeLevel2 // 用于合并重叠的state
};
 
// 创建持久化的reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);
 
// 创建store
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware)=>getDefaultMiddleware({
        serializableCheck: false,
    })
});
 
// 持久化store
const persistor=persistStore(store);

export {
    store,
    persistor
};