import { App as AntApp, ConfigProvider } from "antd";
import React, { Suspense, lazy, useEffect, useRef } from "react";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import zh_CN from "antd/locale/zh_CN";
import es_ES from "antd/locale/es_ES"; // 西班牙语
import en_US from "antd/locale/en_US"; // 英语
import ru_RU from "antd/locale/ru_RU";
import { useDispatch, useSelector } from "react-redux";

import Loading from "./Common/Loading";
import UserIndex from "./component/User/UserIndex"; // 个人中心
import UserHome from "./component/User/Home"; // 个人中心--首页
import Cart from "./component/User/Cart"; // 购物车
import Collection from "./component/User/Collection"; // 收藏夹
import OrderList from "./component/User/OrderList"; // 我的订单
import OrderDetail from "./component/User/OrderDetail"; // 订单详情
import Storage from "./component/User/Storage"; // 我的仓库
import Preview from "./component/User/Preview"; // 预演包裹
import Parcel from "./component/User/Parcel"; // 我的包裹
import Comments from "./component/User/Comments"; // 我的评论
import Balance from "./component/User/Balance"; // 我的资产
import DebtRecords from "./component/User/DebtRecords"; // 欠款记录
import Bills from "./component/User/Bills"; // 我的账单
import Report from "./component/User/Report"; // 消费分析
import Integral from "./component/User/Integral"; // 我的积分
import Consult from "./component/User/Consult"; // 我的咨询
import Message from "./component/User/Message"; // 站内信
import Account from "./component/User/Account"; // 账户设置
import UserInfo from "./component/User/UserInfo"; // 个人信息
import LevelRecord from "./component/User/LevelRecord"; // 成长值记录
import Address from "./component/User/Address"; // 收货地址
import SecurityCenter from "./component/User/Security"; // 账户安全
import EditPwd from "./component/User/EditPwd"; // 修改密码
import SetPayPwd from "./component/User/SetPayPwd"; // 设置支付密码
import CardAddress from "./component/User/CardAddress"; // 信用卡地址
import MCenter from "./component/User/MCenter"; // 自定义功能
import Promy from "./component/User/Promy"; // 推广联盟
import SetCard from "./component/User/SetCard"; // 提现账号
import Withdraw from "./component/User/Withdraw"; // 余额提现
import Reward from "./component/User/Reward"; // 我的奖金
import ListRevenue from "./component/User/ListRevenue"; // 清单原创收益
import PromyApp from "./component/About/PromyApp";  // 推广二维码中转
import IntegralRecord from "./component/User/IntegralRecord"; // 积分记录
import Agreement from "./component/Agreement/Agreement"; //协议
import Myprivilege from "./component/User/Myprivilege"; // 我的特权
import Rout from "./component/Router"; //layout
import HomeIndex from "./component/Home/HomeIndex"; // 首页
import AppPage from "./component/About/AppPage"; // app二维码页面
import Ajax from "./class/Ajax";
import Invitees from "./component/User/Invitees";
import MoneyBag from "./component/User/MoneyBag";
import Coupon from "./component/User/Coupon";
import { useTranslation } from "react-i18next";
global.Ajax = new Ajax();

// const HomeIndex = lazy(() => import('./component/Home/HomeIndex'));  // 首页
const Product = lazy(() => import("./component/Home/Product")); // 商品详情
const ShopDetail = lazy(() => import("./component/Home/ShopDetail")); // 店铺详情
const HelpIndex = lazy(() => import("./component/Help/HelpIndex")); // 帮助中心
const HelpCenter = lazy(() => import("./component/Help/HelpCenter")); // 帮助中心-首页
const Question = lazy(() => import("./component/Help/Question")); // 帮助中心-常见问题
const Search = lazy(() => import("./component/Help/Search")); // 帮助中心-搜索结果页
const QuestionDetail = lazy(() => import("./component/Help/Detail")); // 帮助中心-搜索结果页
const Estimation = lazy(() => import("./component/Estimation/Estimation")); // 运费估算
const CustomLogic = lazy(() => import("./component/CustomLogic/CustomLogic")); // 定制物流
const NoticeList = lazy(() => import("./component/Notice/NoticeList")); // 公告列表
const NoticeDetail = lazy(() => import("./component/Notice/NoticeDetail")); // 公告详情
const Transport = lazy(() => import("./component/Transport/Transport")); // 转运订单
const TransportList = lazy(() => import("./component/Transport/TransportList")); // 历史转运
const Uniorder = lazy(() => import("./component/Uniorder/Uniorder")); // 手动填单
const Customize = lazy(() => import("./component/Customize/Customize")); // 定制订购
const CheckOrder = lazy(() => import("./component/Home/CheckOrder")); // 确认订单
const CheckOut = lazy(() => import("./component/Home/CheckOut")); // 收银台
const About = lazy(() => import("./component/About/Index"));
const AboutIndex = lazy(() => import("./component/About/About")); // 关于我们
const ContactUs = lazy(() => import("./component/About/ContactUs")); // 联系我们
const Login = lazy(() => import("./component/Auth/Login")); // 登录

const Reg = lazy(() => import("./component/Auth/Reg")); // 注册
const Forget = lazy(() => import("./component/Auth/Forget")); // 忘记密码
const ActiveMail = lazy(() => import("./component/Auth/ActiveMail")); // 激活邮箱
const SearchGoodsList = lazy(() => import("./component/Home/SearchList")); // 搜索商品列表
const SubmitParcel = lazy(() => import("./component/User/SubmitParcel")); // 提交包裹
const WithdrawRecord = lazy(() => import("./component/User/WithdrawRecord"));
const RevenueList = lazy(() => import("./component/User/RevenueList"));
const CustomerWithdraw = lazy(() =>
  import("./component/User/CustomerWithdraw")
); // 推广大使提现
const langList = {
  "zh-CN": zh_CN,
  en: en_US,
  "es-ES": es_ES,
  ru: ru_RU,
};

const WrapComps = (props) => {
  let navigate = useNavigate();
  let params = useParams();
  let location = useLocation();
  let Element = props.el;
  return (
    <Element
      ref={props._ref}
      location={location}
      params={params}
      navigate={navigate}
      {...props}
    />
  );
};
const Index = () => {
  const routRef = useRef(null);
  const dispatch = useDispatch();
  const { currency } = useSelector((state) => {
    return state.infos;
  });
  useEffect(() => {
    // 获取当前选中币种的汇率
    global.Ajax.GET(`api/Common/CurrentRate/${currency}`).then((res) => {
      if (res.isSuccess) {
        dispatch({
          type: "setCurrency",
          data: {
            rate: res.result,
          },
        });
      }
    });
    //eslint-disable-next-line
  }, []);
  // 刷新右侧悬浮购物车列表
  const refreshCart = () => {
    routRef.current && routRef?.current?.getList?.();
  };
  // 显示搜索条款弹窗
  const searchShow = (value) => {
    routRef.current && routRef.current.setSearchTipsShow(true);
    routRef.current && routRef.current.setSearchValue(value);
  };
  return (
    <Router>
      <Suspense fallback={<Loading style={{ paddingTop: 100 }} />}>
        <Routes>
          <Route path="login" element={<WrapComps el={Login} />} />
          <Route path="reg" element={<WrapComps el={Reg} />} />
          <Route path="forget" element={<WrapComps el={Forget} />} />
          <Route path="activeMail" element={<WrapComps el={ActiveMail} />} />
          <Route
            path="agreements/:code?"
            element={<WrapComps el={Agreement} />}
          />
          <Route
            path="promyapp"
            element={<WrapComps el={PromyApp} />}
          />
          <Route path="" element={<Navigate to="/home" replace={true} />} />
          <Route path="" element={<WrapComps el={Rout} _ref={routRef} />}>
            <Route
              path="home"
              element={
                <WrapComps
                  el={HomeIndex}
                  searchShow={(value) => {
                    searchShow(value);
                  }}
                />
              }
            />
            <Route path="help" element={<WrapComps el={HelpIndex} />}>
              <Route path="hpcenter" element={<WrapComps el={HelpCenter} />} />
              <Route
                path="question/:id?/:childrensid?"
                element={<WrapComps el={Question} />}
              />
              <Route path="search" element={<WrapComps el={Search} />} />
              <Route
                path="detail"
                element={<WrapComps el={QuestionDetail} />}
              />
            </Route>
            <Route path="estimation" element={<WrapComps el={Estimation} />} />
            <Route
              path="customlogic"
              element={<WrapComps el={CustomLogic} />}
            />
            <Route path="ntcenter" element={<WrapComps el={NoticeList} />} />
            <Route path="ntdetail" element={<WrapComps el={NoticeDetail} />} />
            <Route
              path="product"
              element={
                <WrapComps
                  el={Product}
                  onRefresh={() => {
                    refreshCart();
                  }}
                />
              }
            />
            <Route path="shopdetail" element={<WrapComps el={ShopDetail} />} />
            <Route path="transport" element={<WrapComps el={Transport} />} />
            <Route
              path="transportlist"
              element={<WrapComps el={TransportList} />}
            />
            <Route path="uniorder" element={<WrapComps el={Uniorder} />} />
            <Route path="customize" element={<WrapComps el={Customize} />} />
            <Route path="user" element={<WrapComps el={UserIndex} />}>
              <Route path="percenter" element={<WrapComps el={UserHome} />} />
              <Route
                path="cart"
                element={
                  <WrapComps
                    el={Cart}
                    onRefresh={() => {
                      refreshCart();
                    }}
                  />
                }
              />
              <Route
                path="collection"
                element={<WrapComps el={Collection} />}
              />
              <Route path="orderlist" element={<WrapComps el={OrderList} />} />
              <Route
                path="orderdetail"
                element={<WrapComps el={OrderDetail} />}
              />
              <Route path="storage" element={<WrapComps el={Storage} />} />
              <Route path="preview" element={<WrapComps el={Preview} />} />
              <Route path="parcel" element={<WrapComps el={Parcel} />} />
              <Route path="comments" element={<WrapComps el={Comments} />} />
              <Route path="balance" element={<WrapComps el={Balance} />}>
                <Route path="withdraw" element={<WrapComps el={Withdraw} />} />
                
                <Route
                  path="withdrawRecord"
                  element={<WrapComps el={WithdrawRecord} />}
                />
                <Route path="moneyBag" element={<WrapComps el={MoneyBag} />} />
                <Route
                  path="coupon/:paramtype?"
                  element={<WrapComps el={Coupon} />}
                />
              </Route>
              <Route
                path="debtrecords"
                element={<WrapComps el={DebtRecords} />}
              />
              <Route path="bills" element={<WrapComps el={Bills} />} />
              <Route path="report" element={<WrapComps el={Report} />} />
              <Route path="integral" element={<WrapComps el={Integral} />} />
              <Route path="consult" element={<WrapComps el={Consult} />} />
              <Route path="message" element={<WrapComps el={Message} />} />
              <Route path="account" element={<WrapComps el={Account} />}>
                <Route path="userinfo" element={<WrapComps el={UserInfo} />} />
                <Route path="address" element={<WrapComps el={Address} />} />
                <Route
                  path="secenter"
                  element={<WrapComps el={SecurityCenter} />}
                />
                <Route
                  path="cardaddress"
                  element={<WrapComps el={CardAddress} />}
                />
                <Route
                  path="levelrecord"
                  element={<WrapComps el={LevelRecord} />}
                />
                <Route
                  path="myprivilege"
                  element={<WrapComps el={Myprivilege} />}
                />
              </Route>
              <Route path="editpwd" element={<WrapComps el={EditPwd} />} />
              <Route path="setpaypwd" element={<WrapComps el={SetPayPwd} />} />
              <Route path="mcenter" element={<WrapComps el={MCenter} />} />
              <Route path="promy" element={<WrapComps el={Promy} />}></Route>
              <Route
                path="promywithdraw"
                element={<WrapComps el={CustomerWithdraw} />}
              />
              <Route
                path="promyrevenueList"
                element={<WrapComps el={RevenueList} />}
              />
              <Route
                path="promyinvitees"
                element={<WrapComps el={Invitees} />}
              />
              <Route path="setcard" element={<WrapComps el={SetCard} />} />

              <Route path="reward" element={<WrapComps el={Reward} />} />
              <Route path="revenue" element={<WrapComps el={ListRevenue} />} />
              <Route
                path="integral_record"
                element={<WrapComps el={IntegralRecord} />}
              />
            </Route>
            <Route path="checkorder" element={<WrapComps el={CheckOrder} />} />
            <Route path="checkout" element={<WrapComps el={CheckOut} />} />
            <Route path="app" element={<WrapComps el={AppPage} />} />
            <Route path="about" element={<WrapComps el={About} />}>
              <Route path="abcenter" element={<WrapComps el={AboutIndex} />} />
              <Route path="contactus" element={<WrapComps el={ContactUs} />} />
            </Route>
            <Route path="goods" element={<WrapComps el={SearchGoodsList} />} />
            <Route
              path="submitparcel"
              element={<WrapComps el={SubmitParcel} />}
            />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};
const App = () => {
  const { i18n } = useTranslation();
  return (
    <ConfigProvider
      locale={langList[i18n.language]}
      theme={{
        token: {
          colorPrimary: "#11ba66",
        },
      }}
    >
      <AntApp>
        <Index />
      </AntApp>
    </ConfigProvider>
  );
};
export default App;
