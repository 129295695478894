import React from "react";

const Jian=({ value = '', onChange })=>{
    const triggerChange = (changeValue) => {
        onChange?.(changeValue)
    }
    return(
        <img onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (value > 1) {
                let num = value - 1;
                triggerChange(num);
            }
        }} alt='' src={value <= 1 ? require('../imgs/jianhui.png') : require('../imgs/jian.png')} className="cursor width16" style={{ cursor: value <= 1 ? 'not-allowed' : 'pointer', }} />
    )
}

export default Jian;