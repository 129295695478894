import { Table, Timeline } from "antd";
import React, { forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Index = (props, _ref) => {
    const { t } = useTranslation();
    const columns = [{
        title: t('商品名称'),
        dataIndex: 'name',
        align: 'center',
        className: 'font12',
        width: '40%',
        render: () => (
            <div className="flex" style={{ alignItems: 'flex-start' }}>
                <img alt='' src={require('../../imgs/pic.png')} className="width80 marginr10" />
                <div className="rowFlex font12" style={{ textAlign: 'left' }}>
                    <p className="line2">新品阳晨软毛牙刷竹炭细丝独立包装成人牙刷跨境外贸源头工厂批发</p>
                    <p className="color9">{t("颜色")}:YC-956竹炭软毛;</p>
                </div>
            </div>
        )
    }, {
        title: t('单价'),
        dataIndex: 'name',
        align: 'center',
        className: 'font12',
        render: () => `¥ 5.00`
    }, {
        title: t('状态'),
        dataIndex: 'name',
        className: 'font12',
        align: 'center',
        render: () => `${t("已入库")}`
    }, {
        title: t('数量1'),
        dataIndex: 'name',
        className: 'font12',
        align: 'center',
        render: () => `1`
    }, {
        title: t('总价'),
        dataIndex: 'name',
        className: 'font12',
        align: 'center',
        render: () => `¥ 5.00`
    }];
    useEffect(() => {
        document.title = t("订单详情");
        //eslint-disable-next-line
    }, [])
    return (
        <React.Fragment>
            <div className="order_step">
                <div className="active">
                    <div className="icon icon1"></div>
                    <p>{t("处理中")}</p>
                </div>
                <p className="line"></p>
                <div>
                    <div className="icon  icon2"></div>
                    <p>{t("已订购")}</p>
                </div>
                <p className="line"></p>
                <div>
                    <div className="icon icon3"></div>
                    <p>{t("已发货")}</p>
                </div>
                <p className="line"></p>
                <div>
                    <div className="icon icon4"></div>
                    <p>{t("已到货")}</p>
                </div>
                <p className="line"></p>
                <div>
                    <div className="icon icon5"></div>
                    <p>{t("已入库")}</p>
                </div>
            </div>
            <div className="order_status_box">
                <h4>{t("订单状态")}：{t("已入库")}</h4>
                <div className="reminder">
                    <span>{t("温馨提示")}</span>
                    <p>{t("1、我们会免费为您保管3个月，超3个月任未提交包裹运单或做任何反馈将视放弃商品，我们将统一对商品做销毁处理，了解详细")}</p>
                    <p>{t("2、货品已经放入仓库，可以随时进入我的仓库，提交到您的海外收货地址")}</p>
                </div>
            </div>
            <Timeline
                className="order_tracks"
                items={[
                    {
                        children: (
                            <div>
                                <p>2024-01-03 09:25:13</p>
                                <div>
                                    商家已经发货，发货方式： 申通快递 物流单号： 773264132300951
                                </div>
                            </div>
                        )
                    }, {
                        children: (
                            <div>
                                <p>2024-01-02 16:33:08</p>
                                <div>
                                    您的商品已经订购，交易单号： 3716239718282899108
                                </div>
                            </div>
                        )
                    }, {
                        children: (
                            <div>
                                <p>2024-01-02 16:33:08</p>
                                <div>
                                    您的商品已经订购，交易单号： 3716239718282899108
                                </div>
                            </div>
                        )
                    }
                ]}
            />
            <div className="flex font12 margint20" style={{ borderBottom: '1px solid #eaeef4', paddingBottom: 15 }}>
                <p className="rowFlex">{t("订单咨询")}：PI23211022372</p>
                <span className="colorlv">{t("咨询")}</span>
            </div>
            <Table
                dataSource={[1]}
                columns={columns}
                pagination={false}
            />
        </React.Fragment>
    )
}

export default forwardRef(Index);