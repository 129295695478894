import { Switch, Table } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const Index = () => {
    const {t}=useTranslation();
    const columns = [{
        title: t('授权类型'),
        dataIndex: 'type',
        className: 'font12',
    }, {
        title: t('单笔限额'),
        dataIndex: 'type',
        className: 'font12',
        align: 'center',
        render: ()=>`¥ 0.00`,
    }, {
        title: t('每日封顶'),
        dataIndex: 'type',
        className: 'font12',
        align: 'center',
        render: ()=>`¥ 0.00`,
    }, {
        title: t('剩余额度'),
        dataIndex: 'type',
        className: 'font12',
        render: ()=>`¥ 0.00`,
        align: 'center',
    }, {
        title: t('状态'),
        dataIndex: 'type',
        className: 'font12',
        align: 'center',
        render: () => (
            <Switch size="small" />
        )
    }, {
        title: t('操作'),
        dataIndex: 'type',
        className: 'font12',
        align: 'center',
        width: 100,
        render: () => (
            <p className="cursor weight600">{t("修改")}</p>
        )
    }]
    return (
        <React.Fragment>
            <Table
                dataSource={[1]}
                columns={columns}
                pagination={false}
                className="margint20"
            />
        </React.Fragment>
    )
}

export default Index;