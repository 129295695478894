import React, { forwardRef, useEffect, useState } from "react";
import { Button, Checkbox, Modal } from "antd";
import { useTranslation } from "react-i18next";

const AgreementModal = (props) => {
    const [t, i18n] = useTranslation()
    const code = props.code?.split(",");
    const [agreementlist, setagreementlist] = useState([]);
    const [agreement, setAgreement] = useState(false);
    const {
        onChange,
        hideCheckbox,
        showPopup,
        show,
    } = props;
    const [agreementShow, setAgreementShow] = useState(false);
    const [agreementName, setAgreementName] = useState("");
    const [agreementContent, setAgreementContent] = useState("");
    const lang = () => {
        switch (i18n.language) {
            case 'zh-CN':
                return 'zh'
            case 'es-ES':
                return 'es'
            case 'ru':
                return 'ru'
            default:
                return 'en'
        }
    }
    const filterlist = (x) => {
        const list = x.filter((item) => {
            return code.includes(item.code);
        });
        setagreementlist(list);
    };
    const getlist = () => {
        global.Ajax.GET(`api/Agreements/Summary?LanguageCode=${lang()}`).then(res => {
            if(res.isSuccess){
                let currentDate = new Date();
                currentDate.setHours(currentDate.getHours() + 24);
                let futureTimestamp = currentDate.getTime();
                localStorage.setItem("agreementObj", JSON.stringify({
                    list: res.result,
                    date: futureTimestamp,
                    LanguageCode: lang()
                }));
                filterlist(res.result);
            }
        })
    };
    useEffect(() => {
        const agreementobj = localStorage.getItem("agreementObj") ? JSON.parse(localStorage.getItem("agreementObj")) : {};
        let _agreement = localStorage.getItem("_agreement") ? JSON.parse(localStorage.getItem("_agreement")) : {};
        if (!agreementobj || agreementobj.date <= new Date().getTime() || agreementobj.LanguageCode != lang()) {
            getlist();
        } else {
            const list = agreementobj?.list?.filter((item) => code.includes(item.code));
            if (list?.length == 0 || !list) {
                getlist();
            } else {
                setagreementlist(list);
            }
        }
        let list = [];
        list = [...code].map((item) => {
            const newitem = {
                key: item,
                value: _agreement[item] || false,
            };
            return newitem;
        });
        const bool = list.every((item) => {
            return item.value;
        });
        setAgreement(bool);
        if (!bool && code.length == 1 && show) {
            handleAgreementClick(null, code[0]);
        }
        list.forEach((item) => {
            _agreement[item.key] = item.value;
        });
        localStorage.setItem("_agreement", JSON.stringify(_agreement));
        // disable the rule for a single line
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [1]);
    useEffect(() => {
        if (onChange) {
            onChange(agreement);
        }
        // disable the rule for a single line
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agreement]);
    useEffect(() => {
        if (showPopup && code.length == 1) {
            handleAgreementClick(null, code[0]);
        }
        // disable the rule for a single line
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPopup]);
    const handleAgreementChange = (e) => {
        let _agreement = localStorage.getItem("_agreement") ? JSON.parse(localStorage.getItem("_agreement")) : {};
        code.forEach((item) => {
            _agreement[item] = e.detail;
        });
        localStorage.setItem("_agreement", JSON.stringify(_agreement));
        setAgreement(e.detail);
    };

    const handleAgreementClick = (e, codes) => {
        e && e.stopPropagation();
        setAgreementContent("")
        setAgreementName("")
        global.Ajax.GET(`api/Agreements/${codes}/${lang()}`).then(res => {
            if (res.isSuccess) {
                setAgreementName(res.result.name || "") 
                setAgreementContent(res.result.content || "")
            }
            setAgreementShow(true);
        })
    };

    const handleAgreeClick = () => {
        setAgreementShow(false);
        if (showPopup && onChange) {
            onChange(false);
        }
        handleAgreementChange({ detail: true });
    };
    return (
        <React.Fragment>
            {!hideCheckbox && (
                <div style={{marginBottom:"10px"}}>
                    <Checkbox checked={agreement} onChange={(e) => {
                        handleAgreementChange({ detail: e.target.checked })
                    }}>
                        {t("我已阅读并同意")}
                        {agreementlist?.map((item) => {
                            return (
                                <span
                                    style={{ cursor: "pointer", color: "#3387ff" }}
                                    key={item.id}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        handleAgreementClick(e, item.code)
                                    }}
                                >
                                    《{item.name}》
                                </span>
                            );
                        })}
                    </Checkbox>
                </div>
            )}
            {/* 搜索弹窗 */}
            <Modal
                footer={null}
                centered
                title={agreementName}
                width={1000}
                open={showPopup || agreementShow}
                onCancel={() => {
                    setAgreementShow(false);
                }}
                className="disclaimer"
                // closable={false}
                maskClosable={false}
            >
                <p dangerouslySetInnerHTML={{ __html: agreementContent }}></p>
                <div className="flex margint20" style={{ justifyContent: "center" }}>
                    <Button
                        onClick={handleAgreeClick}
                        type="primary"
                        shape="round"
                        className="marginl10"
                        style={{ width: 100 }}
                    >
                        {t("同意")}
                    </Button>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default forwardRef(AgreementModal);
