import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ScrollToTop from "./ScrollToTop";
import { Outlet, useLocation } from "react-router-dom";
import {
  Affix,
  App,
  Badge,
  Button,
  Dropdown,
  Empty,
  FloatButton,
  Input,
  Menu,
  Modal,
  Popover,
  Select,
  Spin,
  Tooltip,
  Upload,
} from "antd";
import { useTranslation } from "react-i18next";
import Jian from "../Common/Jian";
import Helper from "../class/Helper";
import Price from "../Common/Price";
import { langObj } from "../class/lange";
import { useDispatch, useSelector } from "react-redux";

const Router = (props, _ref) => {
  const [t, i18n] = useTranslation();
  // const [link,setlink] = useState({})
  // if(process.env.NODE_ENV === 'development'){
  //     const linkobj = require('./develop.json')
  // }
  const location = useLocation();
  const { message } = App.useApp();
  const inptRef = useRef(null);
  const dispatch = useDispatch();
  const [bottomtabar, setbottomtabar] = useState([]);
  const [menu] = useState([
    { label: t("首页"), key: "home", path: "/home" },
    { label: t("转运订单"), key: "transport", path: "/transport" },
    // {
    //     label: t('自定义订单'), key: 'custom', children: [
    //         { label: t('手动填单'), key: 'uniorder', path: '/uniorder' },
    //         { label: t('定制订购'), key: 'customize', path: '/customize' }
    //     ]
    // },
  ]);
  const [options] = useState([
    { label: t("商品"), value: 1 },
    // { label: t('店铺'), value: 2 },
  ]);
  const items = [
    { label: <p>{t("个人中心")}</p>, key: "user", path: "/user/percenter" },
    {
      label: <p>{t("我的订单")}</p>,
      key: "orderlist",
      path: "/user/orderlist",
    },
    { label: <p>{t("我的仓库")}</p>, key: "storage", path: "/user/storage" },
    { label: <p>{t("我的包裹")}</p>, key: "parcel", path: "/user/parcel" },
    {
      label: <p>{t("我的资产")}</p>,
      key: "balance",
      path: "/user/balance/moneyBag",
    },
    { label: <p>{t("退出")}</p>, key: "exit" },
  ];
  const [selectedKeys, setSelectedKeys] = useState();
  const lang = [
    { title: "中文（简体）", key: "zh-CN" },
    { title: "English", key: "en" },
    { title: "Español", key: "es-ES" },
    { title: "Русский язык", key: "ru" },
  ];
  const unit = [
    { id: 1, title: "USD", symbol: "$" },
    { id: 2, title: "CNY", symbol: "¥" },
    { id: 3, title: "EUR", symbol: "€" },
    { id: 4, title: "GBP", symbol: "￡" },
    // { id: 5, title: "CAD", symbol: "C$" },
    // { id: 6, title: "JPY", symbol: "JPY¥" },
    // { id: 7, title: "KRW", symbol: "₩" },
    // { id: 8, title: "AUD", symbol: "A$" },
    // { id: 9, title: "HKD", symbol: "HK$" },
    // { id: 10, title: "MYR", symbol: "RM" },
    // { id: 11, title: "TWD", symbol: "NT$" },
    // { id: 12, title: "NOK", symbol: "kr" },
  ];
  const { currency, headPortrait } = useSelector((state) => {
    return state.infos;
  });
  const [cartList, setCartList] = useState([]);
  const [cartRequest, setCartRequest] = useState(true);
  const [cartLoading, setCartLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState(1);
  const [searchTipsShow, setSearchTipsShow] = useState(false);
  let timer = null; // 倒计时
  const [time, setTime] = useState(Helper.getCurrentDate(true));
  useEffect(() => {
    let path = props.location.pathname;
    if (path) {
      let row = menu.find((item) => item.path == path);
      row ? setSelectedKeys([row.key]) : setSelectedKeys([]);
      // if (!row && menu[2]) {
      //   let child = menu[2].children;
      //   let data = child.find((item) => item.path == path);
      //   data && setSelectedKeys([data.key]);
      // }
    }
    if (localStorage.getItem("mycnToken")) {
      cartList.length == 0 && cartRequest && getList();
      // let data = JSON.parse(localStorage.getItem('mycnUserInfo'));
      // console.log(data);
      // setUserInfo(data)
    }
    !timer && getTime();
    // 获取所有币种
    // global.Ajax.GET('Common/Units').then(res => { })
    return () => {
      timer && clearInterval(timer);
    };
    //eslint-disable-next-line
  }, [location.pathname]);
  useImperativeHandle(_ref, () => ({
    getList,
    setSearchTipsShow,
    setSearchValue,
  }));
  const go = (url) => {
    props.navigate(url);
  };
  // 切换语言
  const changeLanguage = (locale) => {
    localStorage.setItem("lang", locale);
    i18n.changeLanguage(locale);
    window.location.reload();
    // window.location.href = '';
  };
  // 切换币种
  const changeUnit = (item) => {
    localStorage.setItem(
      "currency",
      JSON.stringify({
        currency: item.title,
        unit: item.id,
        symbol: item.symbol,
      })
    );
    global.Ajax.GET(`api/Common/CurrentRate/${item.title}`).then((res) => {
      if (res.isSuccess) {
        dispatch({
          type: "setCurrency",
          data: {
            currency: item.title,
            unit: item.id,
            symbol: item.symbol,
            rate: res.result,
          },
        });
      }
    });
  };
  // 获取购物车列表
  const getList = () => {
    setCartRequest(false);
    setCartLoading(true);
    global.Ajax.GET(`api/ShopCart/List?page=1&page_size=999`).then((res) => {
      if (res.isSuccess) {
        let data = Helper.arr2Toarr1(res.result.items);
        setCartList(data);
      }
      setCartLoading(false);
    });
  };
  // 删除购物车商品
  const del = (id) => {
    global.Ajax.hhtc_request("api/ShopCart/Del", [id], "DELETE").then((res) => {
      if (res.isSuccess) {
        getList();
      } else {
        message.error(res.message);
      }
    });
  };
  // 修改商品购物车数量
  const changeCartNum = (id, num) => {
    global.Ajax.hhtc_request(
      "api/ShopCart/EditNum",
      {
        id,
        num,
      },
      "PUT"
    ).then((res) => {
      if (res.isSuccess) {
        getList();
      } else {
        message.error(res.result);
      }
    });
  };
  //
  const getTime = () => {
    timer && clearInterval(timer);
    timer = setInterval(() => {
      let time = Helper.getCurrentDate(true);
      setTime(time);
    }, 1000);
  };
  const getBottomTabbar = () => {
    global.Ajax.GET(`api/Common/TabBarList`).then((res) => {
      if (res.isSuccess) {
        const list = res.result.map((item) => {
          item.key.showtitle =
            item.key[langObj[i18n.language]] || item.key.title;
          item.value = item.value.map((x) => {
            x.showtitle = x[langObj[i18n.language]] || x.title;
            return x;
          });
          return item;
        });
        setbottomtabar(list);
      }
    });
  };
  useEffect(() => {
    getBottomTabbar();
    //eslint-disable-next-line
  }, []);
  return (
    <React.Fragment>
      {/* 头部导航 */}
      <div className="small_nav bgf2">
        <div className="w1200 flex">
          <div className="content_left">
            <p>{time}</p>
          </div>
          <div className="content_right flex">
            <p className="line1" onClick={() => go("/help/question")}>
              {t("新手指引")}
            </p>
            <p onClick={() => go("/help/hpcenter")} className="line1">
              {t("帮助中心")}
            </p>
            <p onClick={() => go("/estimation")} className="line1">
              {t("运费估算")}
            </p>
            <Popover
              overlayClassName="langWrap"
              arrow={false}
              content={
                <div className="langbox">
                  <p className="title">{t("语言")}</p>
                  <div className="flwp">
                    {lang.map((item, index) => (
                      <p
                        onClick={() => changeLanguage(item.key)}
                        className={`item49 ${
                          i18n.language == item.key ? "active" : ""
                        }`}
                        key={String(index)}
                      >
                        {item.title}
                      </p>
                    ))}
                    {/* <p className="item49 active">中文（简体）</p>
                                <p className="item49">English</p>
                                <p className="item49">한국어</p>
                                <p className="item49">Español</p> */}
                  </div>
                  <p className="title" style={{ marginTop: 10 }}>
                    {t("币种")}
                  </p>
                  <div className="flwp">
                    {unit.map((item, index) => (
                      <p
                        onClick={() => changeUnit(item)}
                        className={`item49 ${
                          item.title == currency ? "active" : ""
                        }`}
                        key={String(index)}
                      >
                        {item.title}
                      </p>
                    ))}
                  </div>
                </div>
              }
            >
              <div className="lang flexCenter line1">
                <i className="iconfont icon-yuyan"></i>
                {t("中文")} /<i className="iconfont icon-a-bianzu4"></i>
                {currency}
                <i className="iconfont icon-xialajiantou"></i>
              </div>
            </Popover>
          </div>
        </div>
      </div>
      {/* 导航栏 */}
      <Affix>
        <div className="bgbai navBar">
          <div className="w1200 flexCenter">
            <img
              onClick={() => {
                props.navigate("/home");
              }}
              alt=""
              src={require("../imgs/logo.jpg")}
              className="logo cursor"
            />
            <Menu
              mode="horizontal"
              items={menu}
              selectedKeys={selectedKeys}
              style={{ borderBottom: 0, minWidth: 300, width: "fit-content" }}
              onClick={(e) => {
                let row = menu.find((item) => item.key == e.key);
                if (row && row.path) {
                  props.navigate(row.path);
                } else {
                  let child = menu[2].children;
                  let data = child.find((item) => item.key == e.key);
                  data && data.path && props.navigate(data.path);
                }
                setSelectedKeys(e.keyPath);
              }}
            />
            <div className="rowFlex flexCenter rbox">
              <div className="bgf5 marginlauto searchBox flexCenter">
                <Select
                  placeholder={t("请选择")}
                  options={options}
                  bordered={false}
                  style={{ width: 100 }}
                  defaultValue={1}
                  onChange={(type) => {
                    setSearchType(type);
                  }}
                />
                <Input
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  value={searchValue}
                  ref={inptRef}
                  onPressEnter={(e) => {
                    let value = e.target.value;
                    if (value && value != "") {
                      // 检测是否已同意搜索条款
                      if (localStorage.getItem("searchTips") != "show") {
                        setSearchTipsShow(true);
                        return;
                      }
                      // 跳转
                      if (searchType == 1) {
                        // 商品
                        props.navigate("/goods", {
                          state: {
                            text: value,
                          },
                          replace: window.location.href.includes("/goods")
                            ? true
                            : false,
                        });
                        // 清除图片文件源
                        dispatch({
                          type: "setSearchFile",
                          data: {
                            file: "",
                          },
                        });
                      }
                      setSearchValue("");
                    }
                  }}
                  className="rowFlex"
                  placeholder={t("请输入商品链接或者名称/店铺链接/PI")}
                  bordered={false}
                />
                <Upload
                  beforeUpload={(file) => {
                    // 检测是否已同意搜索条款
                    if (localStorage.getItem("searchTips") != "show") {
                      setSearchTipsShow(true);
                      return;
                    }
                    // 清除图片文件源
                    dispatch({
                      type: "setSearchFile",
                      data: {
                        file: "",
                      },
                    });
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      let url = e.target.result;
                      dispatch({
                        type: "setSearchFile",
                        data: {
                          file: url,
                        },
                      });
                    };
                    reader.readAsDataURL(file);
                    if (searchType == 1) {
                      // 商品
                      props.navigate("/goods?type=img", {
                        state: {
                          text: "",
                        },
                        replace: window.location.href.includes("/goods")
                          ? true
                          : false,
                      });
                    }

                    return false;
                  }}
                  showUploadList={false}
                  maxCount={1}
                  accept="image/*"
                >
                  <div className="upbtn flexAllCenter cursor">
                    <i className="iconfont icon-tupian"></i>
                  </div>
                </Upload>
              </div>
              {/* <div onClick={() => go('/estimation')} className="center marginl10 cursor">
                                <i className="iconfont icon-yunfeigusuan"></i>
                                <p className="yfgs">{t("运费估算")}</p>
                            </div> */}
              {localStorage.getItem("mycnToken") ? (
                <Dropdown
                  menu={{
                    items,
                    onClick: (e) => {
                      let row = items.find((item) => item.key == e.key);
                      if (row.key == "exit") {
                        localStorage.removeItem("mycnToken");
                        message.success(t("再见"), 2, () => {
                          props.navigate("/home");
                        });
                        return;
                      }
                      row && row.path != "" && props.navigate(row.path);
                    },
                  }}
                  placement="bottom"
                >
                  <img
                    alt=""
                    src={
                      headPortrait && headPortrait != ""
                        ? headPortrait
                        : require("../imgs/avatar.png")
                    }
                    className="avatar marginl10"
                  />
                </Dropdown>
              ) : (
                <React.Fragment>
                  <Button
                    className="marginl10"
                    onClick={() => props.navigate("/login")}
                    type="primary"
                    shape="round"
                  >
                    {t("登录")}
                  </Button>
                  <Button
                    onClick={() => props.navigate("/reg")}
                    type="default"
                    shape="round"
                    className="borderlv colorlv marginl10"
                  >
                    {t("注册")}
                  </Button>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </Affix>
      {/* 右侧悬浮栏 */}
      <Affix offsetTop={400} style={{ right: 30, position: "absolute" }}>
        <div className="right_menus">
          <div className="menus color6">
            <Tooltip
              placement="left"
              title={<p className="font12">{t("下载APP")}</p>}
            >
              <div onClick={() => props.navigate("/app")}>
                <i className="iconfont icon-shouji1"></i>
              </div>
            </Tooltip>
            <Popover
              placement="left"
              content={
                <React.Fragment>
                  {/* 加载层 */}
                  {cartLoading && (
                    <div
                      className="flexAllCenter"
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(255,255,255,.8)",
                        zIndex: 99,
                      }}
                    >
                      <Spin />
                    </div>
                  )}
                  <div className="shopping_car">
                    <h2 className="center paddb12">
                      {t("购物车")}({cartList.length})
                    </h2>
                    <div className="list">
                      {/* 空数据 */}
                      {cartList.length == 0 && <Empty />}
                      {cartList.map((item, index) => (
                        <div
                          onClick={() => {
                            window.open(
                              `/product?platform=${item.platForm}&num_iid=${item.productId}`,
                              "_blank",
                              "noreferrer"
                            );
                          }}
                          className="flex cursor"
                          key={String(index)}
                        >
                          <img alt="" src={item.picUrl} className="pic" />
                          <div className="rowFlex marginl10 font12">
                            <p className="line1">{item.title}</p>
                            <p className="line1 color9">
                              {item.propertiesName}
                            </p>
                            <div className="flexCenter goods_num bgf5">
                              <Jian
                                value={item.num}
                                onChange={(num) => changeCartNum(item.id, num)}
                              />
                              {/* <img alt='' src={require('../imgs/jian.png')} className="width16 cursor" /> */}
                              <Input
                                bordered={false}
                                value={item.num}
                                className="rowFlex font12"
                                style={{ textAlign: "center" }}
                              />
                              <img
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  let num = item.num + 1;
                                  changeCartNum(item.id, num);
                                }}
                                alt=""
                                src={require("../imgs/jia.png")}
                                className="width16 cursor"
                              />
                            </div>
                            <div className="flexCenter">
                              <Price
                                className="color6 marginrauto"
                                value={item.price.value}
                              />
                              {/* <span className="color6 marginrauto">¥ {item.price.value}</span> */}
                              <i
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  del(item.id);
                                }}
                                className="iconfont icon-dingdanhuishouzhan cursor"
                              ></i>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {cartList.length > 0 && (
                      <React.Fragment>
                        <div className="center margint20">
                          <span className="font12 color9">
                            {t("订单总价：")}
                          </span>
                          <Price
                            className="font18 weight600"
                            value={Helper.calcTotal(cartList)}
                          />
                          {/* <span className="font18 weight600">¥ {Helper.calcTotal(cartList)}</span> */}
                        </div>
                        <Button
                          onClick={() => props.navigate("/user/cart")}
                          type="primary"
                          block
                          size="small"
                          className="font12 weight600"
                          style={{ paddingTop: 2 }}
                        >
                          {t("去购物车结算")}
                        </Button>
                      </React.Fragment>
                    )}
                  </div>
                </React.Fragment>
              }
            >
              <div>
                <Badge size="small" count={cartList.length} offset={[-4, 6]}>
                  <i className="iconfont icon-xuanfu_gouwuche"></i>
                </Badge>
              </div>
            </Popover>
            {/* <div>
                            <i className="iconfont icon-xuanfu_kefu"></i>
                        </div> */}
            <div
              onClick={() => {
                if (!localStorage.getItem("mycnToken")) {
                  message.error("请先登录", 1.2, () => {
                    props.navigate("/login");
                  });
                  return;
                }
                props.navigate("/user/collection");
              }}
            >
              <i className="iconfont icon-xuanfu_shoucangjia"></i>
            </div>
          </div>
          <FloatButton.BackTop
            icon={<div className="sj"></div>}
            visibilityHeight={300}
          />
          {/* <div onClick={() => {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        });
                    }} className="totop flexAllCenter cursor"></div> */}
        </div>
      </Affix>
      {/* 子路由 */}
      <ScrollToTop>
        <Outlet />
      </ScrollToTop>
      {/* footer */}
      <div className="bgf5">
        <div className="w1200 iconsBox flex">
          <div className="rowFlex flex">
            <i className="iconfont icon-a-MassiveCommodity"></i>
            <div className="rowFlex">
              <p className="title">{t("海量产品")}</p>
              <p className="desc">{t("中国所有线上、线下商品皆可帮助购买")}</p>
            </div>
          </div>
          <div className="rowFlex flex">
            <i className="iconfont icon-a-CombinedShape"></i>
            <div className="rowFlex">
              <p className="title">{t("节约成本")}</p>
              <p className="desc">
                {t("您购买不同商家商品，我们帮您整合成一个包裹寄送到您国家")}
              </p>
            </div>
          </div>
          <div className="rowFlex flex">
            <i className="iconfont icon-a-CombinedShape1"></i>
            <div className="rowFlex">
              <p className="title">{t("服务品质")}</p>
              <p className="desc">{t("提供高效且成熟稳定的服务支持")}</p>
            </div>
          </div>
          <div className="rowFlex flex">
            <i className="iconfont icon-a-QuickResponse"></i>
            <div className="rowFlex">
              <p className="title">{t("快速响应")}</p>
              <p className="desc">{t("最短时间内帮助您解决困难")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="w1200">
          <div className="help flex">
            <div className="bot_menu flex">
              <div className="bot_menu flex flexwrap">
                {bottomtabar.map((item, index) => {
                  return (
                    <div className="bt_item" key={index}>
                      <h1 style={{ marginTop: "10px", marginBottom: "10px" }}>
                        {item.key.showtitle}
                      </h1>
                      {item.value.map((x) => {
                        return (
                          <p
                            onClick={() => {
                              if (x.url && x.url !== "#") {
                                props.navigate(x.url);
                              }
                              // window.location.hash = x.url
                            }}
                          >
                            {x.showtitle}
                          </p>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="rightcontent">
              <h4>{t("关注我们")}</h4>
              <i className="iconfont icon-Discord"></i>
            </div>
          </div>
          <div className="copyright flexCenter">
            <div className="left rowFlex flexCenter">
              <p>Copyright© 2020-2024 Bccbuy.com All Rights Reserved&ensp;</p>
            </div>
            <div className="right flex">
              <p
                onClick={() => {
                  props.navigate("/about/abcenter");
                }}
              >
                {t("关于 Bccbuy")}
              </p>
              <p
                onClick={() => {
                  window.open(`/help/question`, "_blank");
                }}
              >
                {t("服务条款和用户管理")}
              </p>
              <p
                onClick={() => {
                  window.open(`/help/question`, "_blank");
                }}
              >
                {t("隐私声明")}
              </p>
              <p
                onClick={() => {
                  props.navigate("/about/contactus");
                }}
              >
                {t("联系我们")}
              </p>
            </div>
          </div>
          <p className="p1">
            {t("公司名称：")}Jiangsu Bccbuy Supply Chain Co.,LTD
          </p>
          <p className="p1">
            {t("办公地址：")}Room 1606 Jixiang Building JieFang North Road,
            Liangxi District Wuxi Jiangsu China
          </p>
          <img
            alt=""
            src={require("../imgs/footer_pay.png")}
            className="footer_pay"
          />
        </div>
      </div>
      {/* 搜索弹窗 */}
      <Modal
        footer={null}
        centered
        width={500}
        open={searchTipsShow}
        closable={false}
        maskClosable={false}
        className=""
      >
        <h1>{t("搜索服务条款")}</h1>
        <p className="margint20">
          {t(
            "搜索的商品均来自第三方平台，Bccbuy只提供信息搜索、代购、寄送服务。Bccbuy无法鉴定商品质量、真伪。"
          )}
        </p>
        <span className="color9 cursor">
          {t("同意《搜索服务条款》则为您展示搜索结果。")}
        </span>
        <p></p>
        <div className="flex margint20" style={{ justifyContent: "flex-end" }}>
          <Button onClick={() => setSearchTipsShow(false)} shape="round">
            {t("拒绝")}
          </Button>
          <Button
            onClick={() => {
              setSearchTipsShow(false);
              setTimeout(() => {
                if (searchType == 1) {
                  // 商品
                  props.navigate("/goods", {
                    state: {
                      text: searchValue,
                    },
                  });
                } else if (searchType == 2) {
                  // 店铺
                }
                setSearchValue("");
              }, 100);
              localStorage.setItem("searchTips", "show");
            }}
            type="primary"
            shape="round"
            className="marginl10"
          >
            {t("同意")}
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default forwardRef(Router);
