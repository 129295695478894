import {
  Button,
  Table,
} from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./withdraw.css";
import "./CustomerWithdraw.css";

const Index = (props, _ref) => {
  const { t } = useTranslation();
  const [list, setList] = useState([{}]);
  const [total, setTotal] = useState(0);
  const [searchObj, setSearchObj] = useState({
    page: 1,
    size: 10,
  });
  const columns = [
    {
      title: t("id"),
      dataIndex: "id",
    },
    {
      title: t("用户名"),
      dataIndex: "name",
    },
  ];
  const getList = () => {
    let url =  "api/Member/me/invitees";
    let obj = `PageIndex=${searchObj.page}&PageSize=${searchObj.size}`;
    global.Ajax.GET(`${url}?${obj}`).then((res) => {
      if (res.isSuccess) {
        setTotal(
          res?.result?.pagination?.availCnt || res?.result?.length
        );
        setList(res.result);
      } else {
        setList([]);
      }
    });
  };
  useEffect(() => {
    document.title = t("已邀请用户");
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    getList();
    //eslint-disable-next-line
  }, [searchObj]);
  return (
    <React.Fragment>
      <div class="withdrawpage CustomerWithdrawpage">
        <div className="flexCenter tbox">
          <Button
            type="link"
            className="pl0"
            onClick={() => props.navigate("/user/promy")}
          >
            {t("返回推广联盟")}
          </Button>
        </div>
        <div className="flexCenter spacebeween">
          <div className="flexCenter spacebeween">
            <div className="font18">{t("已邀请用户")}： {total}</div>
          </div>
        </div>
        <Table
          dataSource={list}
          columns={columns}
          pagination={{
            current: searchObj.page,
            pageSize: searchObj.size,
            total: total,
          }}
          onChange={(e) => {
            setSearchObj({
              ...searchObj,
              page: e.current,
              size: e.pageSize,
            });
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default forwardRef(Index);
