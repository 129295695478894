import {
  Button,
  // Carousel,
  Modal,
  // Progress,
  Tooltip,
  // theme,
  message,
  Form,
  Radio,
  Input,
} from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import { useTranslation } from "react-i18next";
import Helper from "../../class/Helper";

const Index = (props, _ref) => {
  const { t, i18n } = useTranslation();
  // const {
  //   token: { colorLink },
  // } = theme.useToken();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [title, setTitle] = useState("");
  const [levellist, setlevellist] = useState([]);
  const [type, settype] = useState(1);
  const [BusinessCard, setBusinessCard] = useState({});
  const [loading,setloading] = useState(false);
  const [withdrawopen, setwithdrawopen] = useState(false);
  const [info, setinfo] = useState({});
  const [madol, setmadol] = useState({
    visize: false,
    code: "",
  });
  // 检测是否登录
  const isLogin = () => {
    if (!localStorage.getItem("mycnToken")) {
      message.error(t("请先登录"), 1.2, () => {
        props.navigate("/login");
      });
      return false;
    }
    return true;
  };
  const [agreementContent, setAgreementContent] = useState({
    name: "",
    content: "",
  });
  function convertToPercentage(decimal) {
    const percentage = decimal * 100;
    const roundedPercentage = Math.round(percentage);
    if (roundedPercentage % 1 === 0) {
      return `${roundedPercentage.toFixed(0)}%`; // 如果是整数，直接去掉小数部分
    } else {
      return `${roundedPercentage}%`; // 否则保留一位小数
    }
  }
  const getlevel = () => {
    global.Ajax.GET("api/Referral/LevelItem").then((res) => {
      if (res.isSuccess) {
        setlevellist(res.result);
      } else {
        setlevellist([]);
      }
    });
  };
  const getcard = () => {
    global.Ajax.GET("api/Member/BusinessCard").then((res) => {
      if (res.isSuccess) {
        setBusinessCard(res.result);
      } else {
        setBusinessCard(undefined);
      }
    });
  };
  const getinfo = () => {
    global.Ajax.GET("api/Referral/me/detail").then((res) => {
      if (res.isSuccess) {
        setinfo(res.result);
      } else {
        setinfo([]);
      }
    });
    isLogin();
  };
  const lang = () => {
    switch (i18n.language) {
      case "zh-CN":
        return "zh";
      case "es-ES":
        return "es";
      case "ru":
        return "ru";
      default:
        return "en";
    }
  };
  const getcontent = () => {
    global.Ajax.GET(`Agreements/${madol.code}/${lang()}`).then((res) => {
      if (res.isSuccess) {
        setAgreementContent({
          name: res.result.name || "",
          content: res.result.content || "",
        });
      }
    });
  };
  const copyUrl = (url) => {
    copy(`${url}`);
    message.success(`Copied ${url}`);
  };
  useEffect(() => {
    document.title = t("推广联盟");
    getlevel();
    getinfo();
    getcard();
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (madol.visize) {
      getcontent();
    }
    //eslint-disable-next-line
  }, [madol.visize]);
  const onCancel = () => {
    setOpen(false);
    setwithdrawopen(false)
    form?.resetFields?.();
    setTitle("");
  };
  const onFinish = (values) => {
    setloading(true)
    let url = "api/Withdrawal",
      type;
    let wallet = values.payType != 1 ? values.wallet || "" : "";
    let data = {
      amount: parseFloat(values.amount) || 0,
      unit: 2,
      remarks: values.remarks,
      payType: values.payType,
      type: 10,
      wallet: wallet,
    };
    global.Ajax.hhtc_request(url, data, type).then((res) => {
      setloading(false)
      if (res.isSuccess) {
        message.success(res.message);
        form?.resetFields?.();
        setwithdrawopen(false)
        setOpen(false);
      } else {
        message.error(res.message);
      }
    });
  };
  return (
    <React.Fragment>
      <div className="promyBox">
        <img
          alt=""
          src={require("../../imgs/cnBg.png")}
          className="block row10"
        />
        <div className="flexCenter row10 poabs">
          {/* <Carousel className="swiper" vertical dots={false} autoplay infinite>
            <div className="flexCenter">
              <img
                alt=""
                src={require("../../imgs/pic.png")}
                className="avatar"
              />
              <p className="marginl10">e****</p>
              <h4 className="marginl10 rowFlex">
                {t("累计收益")} 184108.79 CNY
              </h4>
            </div>
            <div className="flexCenter">
              <img
                alt=""
                src={require("../../imgs/pic.png")}
                className="avatar"
              />
              <p className="marginl10">2****</p>
              <h4 className="marginl10 rowFlex">
                {t("累计收益")} 184108.79 CNY
              </h4>
            </div>
          </Carousel> */}
          <span
            className="marginlauto"
            onClick={() => {
              setmadol({
                visize: true,
                code: "referrersrules",
              });
            }}
          >
            {t("推广规则")}
          </span>
          <span
            className="marginl10"
            onClick={() => {
              setmadol({
                visize: true,
                code: "referrers",
              });
            }}
          >
            {t("推广协议")}
          </span>
        </div>
        {/* 推广佣金 */}
        <div className="commission_box">
          <div className="head flexCenter">
            <div className="left rowFlex flex">
              <h1>{t("我的推广佣金")}</h1>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => props.navigate("/user/promyinvitees")}
              >
                <span>
                  {t("已邀请用户")}：<strong>{info?.invitees || 0}</strong>
                </span>
              </div>
            </div>
          </div>
          <div className="bgbai">
            <div className="flexCenter">
              <div>
                <p>{t("我的联盟余额")}(CNY)</p>
                <div
                  onClick={() => props.navigate("/user/promywithdraw")}
                  className="yubox flexCenter cursor"
                >
                  <span>{info?.balance?.value || 0}</span>
                  <i className="iconfont icon-a-21 font12 marginl10"></i>
                </div>
                <p className="font12 color9">
                  {t("累计金额")}(CNY)：{info?.totalIncomes?.value || 0}
                </p>
              </div>
              <Button
                type="primary"
                shape="round"
                disabled={info?.balance?.value <= 0}
                className="border0 weight600"
                onClick={() => {
                  form?.resetFields?.();
                  setwithdrawopen(true);
                }}
                style={{ width: 100, marginLeft: 40, height: 36 }}
              >
                {t("提现")}
              </Button>
            </div>
          </div>
        </div>
        <div
          className="margint20 flow_chart bgbai"
          style={{ marginBottom: 40 }}
        >
          <h1 className="center">{t("联盟流程")}</h1>
          <div className="flex">
            <div className="rowFlex center font12">
              <img alt="" src={require("../../imgs/step_1.png")} />
              <p>{t("分享邀请码给好友")}</p>
            </div>
            <div className="rowFlex center font12">
              <img alt="" src={require("../../imgs/step_2.png")} />
              <p>{t("好友下单并收到包裹")}</p>
            </div>
            <div className="rowFlex center font12">
              <img alt="" src={require("../../imgs/step_3.png")} />
              <p>{t("获得佣金")}</p>
            </div>
          </div>
        </div>
        <div className="qrcodebox">
          <h1 className="center">{t("推广方式")}</h1>
          <p className="font12 center">
            {t("通过该链接点击进入Bccbuy注册的用户会自动绑定为您的推广用户。")}
          </p>
          <div className="flex">
            {/* <div className="item">
              <Tooltip
                color="#fff"
                title={
                  <img
                    alt=""
                    src={require("../../imgs/pic.png")}
                    style={{ width: 95 }}
                  />
                }
              >
                <div className="btn flexAllCenter">
                  <span>{t("方法一")}:</span>
                  <p>
                    {t("下载二维码")}
                    <i className="iconfont icon-xiazai1 marginl10"></i>
                  </p>
                </div>
              </Tooltip>
              <span
                onClick={() => {
                  setTitle("下载二维码");
                  setOpen(true);
                }}
              >
                {t("查看方式介绍")}
              </span>
            </div> */}
            <div className="item">
              <Tooltip
                color="#fff"
                title={
                  <p style={{ color: "#333" }}>
                    {`${window.location.origin}/home?invitationCode=${
                      BusinessCard?.cardCode || ""
                    }`}
                  </p>
                }
              >
                <div
                  className="btn flexAllCenter"
                  onClick={() => {
                    copyUrl(
                      `${window.location.origin}/home?invitationCode=${
                        BusinessCard?.cardCode || ""
                      }`
                    );
                  }}
                >
                  <span>{t("方法一")}:</span>
                  <p>
                    {t("复制链接")}
                    <i className="iconfont icon-a-bianzu20 marginl10"></i>
                  </p>
                </div>
              </Tooltip>
              <span
                onClick={() => {
                  setTitle("复制链接");
                  setOpen(true);
                }}
              >
                {t("查看方式介绍")}
              </span>
            </div>
            <div className="item">
              <Tooltip
                color="#fff"
                title={
                  <p style={{ color: "#333" }}>
                    {BusinessCard?.cardCode || ""}
                  </p>
                }
              >
                <div
                  className="btn flexAllCenter"
                  onClick={() => {
                    copyUrl(BusinessCard?.cardCode || "");
                  }}
                >
                  <span>{t("方法二")}:</span>
                  <p>
                    {t("复制邀请码")}
                    <i className="iconfont icon-a-bianzu20 marginl10"></i>
                  </p>
                </div>
              </Tooltip>
              <span
                onClick={() => {
                  setTitle("复制邀请码");
                  setOpen(true);
                }}
              >
                {t("查看方式介绍")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="qybox">
        <div className="flexCenter margint20">
          <span className="font18">{t("我的权益")}</span>
          <span className="font12 marginl10 flexCenter">
            {t("佣金率")}
            <Tooltip
              title={
                <p className="font12">
                  <span className="block">{t("什么是佣金率？")}</span>
                  {t(
                    "您的推广用户成功收到包裹后，Bccbuy会给您发放该包裹运费总额*佣金率的奖励。"
                  )}
                </p>
              }
            >
              <i className="iconfont icon-a-bangzhu14px font14 marginl4"></i>
            </Tooltip>
          </span>
        </div>
        <div className="margint10 flex list">
          {levellist.map((item, index) => {
            return (
              <div className="rowFlex" key={index}>
                <p
                  className={
                    item.level == info.level ? "level bgyellow" : "level"
                  }
                >
                  {item.name}
                </p>
                <p className="color6">{item.referees}</p>
                <p className=" weight600 margint10 font18">
                  {t("固定额")}:<span>{item.fixedRebate}</span>
                </p>
                <p className=" weight600 margint10 font18">
                  {t("佣金率")}:
                  <span>{convertToPercentage(item.calcRebate)}</span>
                </p>
              </div>
            );
          })}
        </div>
      </div>
      {/* 推广方式 */}
      <Modal
        title={t(title)}
        open={open}
        width={420}
        centered
        destroyOnClose
        footer={null}
        onCancel={onCancel}
        className="tgmask"
      >
        {title == "下载二维码" && (
          <React.Fragment>
            <p>
              {t("步骤")} 1：{t("分享你的专属二维码给好友")}
            </p>
            <p>
              {t("步骤")} 2：{t("好友进入Bccbuy，扫描二维码")}
            </p>
          </React.Fragment>
        )}
        {title == "复制链接" && (
          <React.Fragment>
            <p>
              {t("步骤")} 1：{t("分享你的推广链接给好友")}
            </p>
            <p>
              {t("步骤")} 2：{t("好友通过点击链接进入Bccbuy")}
            </p>
          </React.Fragment>
        )}
        {title == "复制邀请码" && (
          <React.Fragment>
            <p>
              {t("步骤")} 1：{t("分享你的邀请码给好友")}
            </p>
            <p>
              {t("步骤")} 2：{t("好友进入Bccbuy并填写你的邀请码进行注册")}
            </p>
          </React.Fragment>
        )}
        <p>
          {t("步骤")} 3：{t("好友注册成功，自动绑定成为您的推广用户")}
        </p>
        {/* {title == "复制邀请码" && (
          <p>
            {t(
              "注：通过您的邀请码成功注册Bccbuy的新用户将额外获得70元优惠券礼包"
            )}
          </p>
        )} */}
        <Button
          type="primary"
          shape="round"
          className="block marginlauto margint20"
          onClick={onCancel}
        >
          {t("确定")}
        </Button>
      </Modal>
      {/* 推广规则 推广协议 */}
      <Modal
        footer={null}
        centered
        title={agreementContent.name}
        width={1000}
        destroyOnClose
        open={madol.visize}
        onCancel={() => {
          setmadol({
            ...madol,
            visize: false,
          });
        }}
        className="disclaimer"
        maskClosable={false}
      >
        <div
          style={{ height: "50vh", minHeight: "150px", overflowY: "scroll" }}
        >
          <p dangerouslySetInnerHTML={{ __html: agreementContent.content }}></p>
        </div>
      </Modal>
      <Modal
        title={t("提现")}
        open={withdrawopen}
        width={500}
        centered
        destroyOnClose={true}
        maskClosable={false}
        footer={null}
        onCancel={onCancel}
        className="huihead CustomerWithdrawpage"
      >
        <Form
          colon={false}
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            label={t("收款类型")}
            name="payType"
            rules={[{ required: true, message: t("请选择收款类型") }]}
          >
            <Radio.Group
              options={[
                { label: t("账户余额"), value: 1 },
                { label: t("支付宝"), value: 2 },
                { label: "PayPal", value: 3 },
              ]}
              onChange={(e) => {
                settype(e.target.value);
              }}
            />
          </Form.Item>
          {type != 1 && (
            <Form.Item
              label={t("账号")}
              name="wallet"
              rules={[{ required: true, message: t("请输入提现账号") }]}
            >
              <Input placeholder={t("请输入")} />
            </Form.Item>
          )}
          <Form.Item
            label={t("提现金额")}
            name="amount"
            rules={[{ required: true, message: t("请输入提现金额") },
              {
                type: "number",
                validator: (rule, value) => {
                  return Helper.numbermessage(
                    rule,
                    value,
                    0.01,
                    parseFloat(info?.balance?.value || "0"),
                    t
                  );
                }, // 自定义提示语
              },]}
            className="maginb0"
          >
            <Input
              placeholder={t("请输入")}
              type="number"
              suffix="CNY"
              step={0.01}
            />
          </Form.Item>
          <span>
            {t("本次提现最高金额是")}&ensp;{info?.balance?.value}
          </span>
          <Form.Item
            label={t("备注")}
            className="margint19"
            name="remarks"
            rules={[{ required: type != 1, message: t("请输入提现金额") }]}
          >
            <Input placeholder={t("可填写您的备注信息")} />
          </Form.Item>
          <span>{t("您可将收款币种填写到备注")}</span>
          <Button
            type="primary"
            shape="round"
            htmlType="submit"
            loading={loading}
            className="block marginlauto"
          >
            {t("确定")}
          </Button>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default forwardRef(Index);
