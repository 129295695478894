import { Button, Form, Input, Modal, Table } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Price from "../../Common/Price";
import Helper from "../../class/Helper";
import "./userhome.css";

const Index = (props, _ref) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { currency, symbol, rate } = useSelector((state) => {
    return state.infos;
  });
  const [coupontotal, setcoupontotal] = useState(0);
  const [ordertotal, setordertotal] = useState(0);
  const [getSearchArr] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [balanceInfo, setBalanceInfo] = useState({
    balance: {
      value: "0.00",
    },
  });
  const columns = [
    {
      title: t("商品名称"),
      dataIndex: "products",
      key: "products",
      align: "center",
      width: "36%",
      render: (_, item) => {
        let products = item.products[0];
        return (
          <div className="flex goods_table">
            <img alt="" src={products.picUrl} className="width80 height80" />
            <div
              className="rowFlex font12 marginl10"
              style={{ textAlign: "left" }}
            >
              <p
                className="title line2 cursor"
                onClick={() => {
                  window.open(
                    `/product?platform=${item.platForm}&num_iid=${products.productId}`,
                    "_blank",
                    "noreferrer"
                  );
                }}
              >
                {products.title}
              </p>
              <p className="color9 line2 margint4">{products.propertiesName}</p>
            </div>
          </div>
        );
      },
    },
    {
      title: t("提交时间"),
      dataIndex: "createdTime",
      key: "createdTime",
      align: "center",
      render: (date) => (
        <span className="font12">{Helper.formatDateTime(date, "/")}</span>
      ),
    },
    {
      title: t("订单状态"),
      dataIndex: "orderState",
      key: "orderState",
      align: "center",
      render: () => <span className="font12">{t("待支付")}</span>,
    },
    {
      title: t("操作"),
      dataIndex: "orderNumber",
      key: "orderNumber",
      align: "center",
      width: 120,
      render: (orderNumber) => (
        <Button
          onClick={() => {
            props.navigate(`/checkout?orderNumber=${orderNumber}`);
          }}
          type="primary"
          size="small"
          className="font12"
        >
          {t("去支付")}
        </Button>
      ),
    },
  ];
  useEffect(() => {
    document.title = t("个人中心");
    setOpen(getSearchArr.get("showModal") == "true" ? true : false);
    global.Ajax.GET("api/Member/MemberInfo").then((res) => {
      if (res.isSuccess) {
        setUserInfo(res.result);
      }
    });
    global.Ajax.GET("api/Balance/BalanceForMemberId").then((res) => {
      if (res.isSuccess) {
        setBalanceInfo(res.result);
      }
    });
    //
    getList();
    // 获取优惠券
    getcoupons();
    //eslint-disable-next-line
  }, []);
  const onCancel = () => {
    setOpen(false);
  };
  // 获取优惠券
  const getcoupons = () => {
    global.Ajax.GET(`api/Member/Coupons?Status=${10}`).then((res) => {
      if (res.isSuccess) {
        setcoupontotal(res.result.length);
      } else {
        setcoupontotal(0);
      }
    });
  };
  // 获取列表
  const getList = (pageindex = 1, pagesize = 10) => {
    global.Ajax.GET(
      `api/SaleOrder/GetHomePageSaleOrders?PageSize=${pagesize}&PageIndex=${pageindex}`
    ).then((res) => {
      if (res.isSuccess) {
        setordertotal(res.result.pagination.availCnt);
        setList(res.result.items);
      } else {
        setList([]);
        setordertotal(0);
      }
    });
  };
  return (
    <React.Fragment>
      <div className="userhome">
        <div className="info flexCenter">
          <div className="lbox">
            <div
              className="avatarbox cursor"
              onClick={() => props.navigate("/user/account/userinfo")}
            >
              <img
                alt=""
                src={
                  userInfo.headPortrait && userInfo.headPortrait != ""
                    ? userInfo.headPortrait
                    : require("../../imgs/avatar_hui.png")
                }
                className="avatar"
              />
              <span>{userInfo.levelName}</span>
            </div>
            <p className="nick">{userInfo.name}</p>
            <p>ID：{userInfo.memberId}</p>
          </div>
          <div className="rowFlex flex rbox">
            <div className="item32">
              <div
                className="cursor"
                onClick={() => {
                  props.navigate("/user/balance/moneyBag");
                }}
              >
                <p className="p1">{t("我的余额")}</p>
                <p className="p2 usermybalance">
                  <Price
                    className="font18 weight600"
                    value={balanceInfo.balance.value}
                  />
                </p>
              </div>
              <div className="flexAllCenter">
                <Button onClick={() => setOpen(true)} size="small">
                  {t("充值")}
                </Button>
                <Button size="small" className="marginl10" onClick={()=>{
                  props.navigate("/user/balance/withdraw");
                }}>
                  {t("提现")}
                </Button>
              </div>
            </div>
            <div
              className="rowFlex cursor"
              onClick={() => {
                props.navigate("/user/integral");
              }}
            >
              <p className="p1">{t("我的积分")}</p>
              <p className="p2">{userInfo.points}</p>
            </div>
            <div
              className="rowFlex cursor"
              onClick={() => {
                props.navigate("/user/balance/coupon/10");
              }}
            >
              <p className="p1">{t("我的优惠券")}</p>
              <p className="p2">{coupontotal}</p>
            </div>
          </div>
        </div>
        <Table
          dataSource={list}
          columns={columns}
          rowKey={"orderNumber"}
          pagination={{
            // pageSizeOptions: [5,10,20,30,50],
            showSizeChanger: true,
            showQuickJumper: true,
            total: ordertotal,
            showTotal: () => {
              return `${t("共条", {
                num: ordertotal,
              })}`;
            },
          }}
          onChange={(e) => {
            getList(e.current, e.pageSize);
          }}
        />
      </div>
      {/* 充值 */}
      <Modal
        title={t("充值")}
        width={600}
        open={open}
        centered
        destroyOnClose
        footer={null}
        onCancel={onCancel}
        className="huihead"
      >
        <Form
          form={form}
          colon={false}
          autoComplete="off"
          style={{ marginTop: 40, marginInline: 60 }}
          onFinish={(data) => {
            props.navigate(`/checkout?value=${data.PayAmount}`);
          }}
        >
          <Form.Item
            label={t("充值金额")}
            name="PayAmount"
            rules={[{ required: true, message: t("请输入金额") }]}
          >
            <Input placeholder={t("请输入金额")} suffix="CNY" />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, cur) => prev.PayAmount != cur.PayAmount}
          >
            {({ getFieldValue }) => {
              let PayAmount = getFieldValue("PayAmount");
              return (
                currency != "CNY" &&
                PayAmount > 0 && (
                  <p className="font14 colorr3" style={{ marginLeft: 81 }}>
                    ≈ {symbol} {Helper.convertPrice(PayAmount, rate)}
                  </p>
                )
              );
            }}
          </Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            shape="round"
            className="block marginlauto"
            style={{ marginTop: 60, marginRight: -40 }}
          >
            {t("确定")}
          </Button>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default forwardRef(Index);
