import {
  Button,
  Carousel,
  Empty,
  Input,
  Select,
  Upload,
  message,
  Modal,
  Tabs,
  Form,
} from "antd";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "dayjs";
import Loading from "../../Common/Loading";
import { useSearchParams } from "react-router-dom";
import PlatformIcon from "../../Common/PlatformIcon";
import { langObj } from "../../class/lange";
import AgreementModal from "../Agreement/AgreementModal";
// import Goodslist from "../Goodslist/Goodslists"
import { useDispatch } from "react-redux";
import Price from "../../Common/Price";

const Index = (props, _ref) => {
  const { t } = useTranslation();
  const tabs = [
    { label: t("注册"), key: "reg" },
    // { label: t("绑定"), key: "bind" },
  ];
  const sliderRef = useRef(null);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const infoRef = useRef();
  const [search] = useSearchParams();
  const [hasMore, setHasMore] = useState(true);
  const [list, setproList] = useState([]);
  const [is_error, setIsError] = useState(false);
  const [page, setPage] = useState(1);
  const [total, settotal] = useState(0);
  const [activeKey, setActiveKey] = useState("reg");
  const [agreement, setAgreement] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleAgreementChange = (data) => setAgreement(data);
  const [options] = useState([
    { label: t("商品"), value: 1 },
    // { label: t('店铺'), value: 2 },
  ]);
  const [obj, setObj] = useState({
    type: 1,
    searchValue: "",
  });
  const [wlList, setList] = useState([]);
  const [form] = Form.useForm();
  const [coderesponse, setcoderesponse] = useState({});
  const [showcode, setshowcode] = useState(false);
  const [hdList, setHdList] = useState([]);
  const [otherList, setOtherList] = useState([]);
  const [banner, setBanner] = useState([]);
  useEffect(() => {
    document.title = t("首页");
    // 轮播图
    global.Ajax.GET("api/Common/SlideShow").then((res) => {
      if (res.isSuccess) {
        setBanner(res.result);
      }
    });
    getList(1);
    getList(2);
    getList(3);
    refresh();
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (search.get("invitationCode")) {
      localStorage.setItem("invitationCode", search.get("invitationCode"));
    } //eslint-disable-next-line
  }, [search.get("invitationCode")]);
  const [disabled, setdisabled] = useState(true);
  const getDiscount = () => {
    global.Ajax.GET("api/Member/Discount").then((res) => {
      if (res.isSuccess) {
        localStorage.setItem("userDiscount", res.result);
      }
    });
    global.Ajax.GET("api/Member/MemberInfo").then((res) => {
      if (res.isSuccess) {
        res.result.nation = res.result.nation.toUpperCase();
        dispatch({
          type: "setUserInfo",
          data: res.result,
        });
        localStorage.setItem("mycnUserInfo", JSON.stringify(res.result));
      }
    });
  };
  useEffect(() => {
    if (search.get("code")) {
      global.Ajax.GET(`api/Tokens/${search.get("code")}`).then((res) => {
        localStorage.removeItem("mycnToken");
        if (res.isSuccess) {
          if (res?.result?.authCode) {
            setshowcode(true);
            setdisabled(!!res?.result?.email);
            setcoderesponse(res?.result);
          } else {
            localStorage.setItem(
              "mycnToken",
              `${res.result.tokenType} ${res.result.accessToken}`
            );
          }
        } else {
          message.error(res.message);
        }
      });
    }
    //eslint-disable-next-line
  }, [search.get("code")]);
  useEffect(() => {
    if (showcode) {
      const timer = setTimeout(() => {
        clearTimeout(timer);
        form.setFieldsValue({
          ...coderesponse,
        });
      }, 100);
    }
    //eslint-disable-next-line
  }, [showcode, coderesponse]);
  // useEffect(() => {
  //   if (localStorage.getItem("mycnToken") && search.get("code")) {
  //     getDiscount();
  //   }
  //   //eslint-disable-next-line
  // }, [localStorage.getItem("mycnToken"), search.get("code")]);
  const onFinish = (data) => {
    if (!agreement && activeKey == "reg") {
      message.error(t("请先同意协议"));
      return;
    }
    setLoading(true);
    data.authCode = coderesponse.authCode || "";
    global.Ajax.hhtc_request(
      "api/Member/Register/External?api-version=1.0",
      data
    ).then((res) => {
      setLoading(false);
      if (res.isSuccess) {
        localStorage.setItem(
          "mycnToken",
          `${res.result.tokenType} ${res.result.accessToken}`
        );
        getDiscount();
        setshowcode(false);
        message.success(t("成功"), 1.5, () => {
          props.navigate("/home", { replace: true });
        });
      } else {
        message.error(res.message);
      }
    });
  };
  const loadMore = () => {
    if (!infoRef.current) return;
    global.Ajax.GET(
      `api/Recommend/ProductItem?PageIndex=${page}&PageSize=${20}`
    ).then((res) => {
      setHasMore(false);
      settotal(res?.result?.pagination?.availCnt || 0);
      if (res.isSuccess) {
        setIsError(false);
        if (res.result.items && page <= res.result.pagination.availPage) {
          let arr = [...list, ...res.result.items];
          if (page == 1) {
            arr = res.result.items;
          }
          setproList([...arr]);
        }
      } else {
        setIsError(true);
      }
    });
  };
  const refresh = () => {
    window.scroll({ top: 0, behavior: "smooth" });
    const timer = setTimeout(() => {
      clearTimeout(timer);
      window.scrollTo(0, 0);
    }, 100);
    setHasMore(true);
    infoRef.current = true;
    setIsError(false);
    setPage(1);
    setList([]);
  };
  useEffect(() => {
    loadMore();
    //eslint-disable-next-line
  }, [page]);
  const platform = (detailUrl) => {
    if (detailUrl.includes("taobao")) {
      return 1;
    } else if (detailUrl.includes("1688")) {
      return 2;
    } else {
      return 3;
    }
  };
  const getList = (type) => {
    global.Ajax.GET(`api/Common/AnnouncementList?type=${type}`).then((res) => {
      if (res.isSuccess) {
        const list =
          res.result?.map?.((item) => {
            return {
              ...item,
              title: item[langObj[i18n.language]] || item.title,
            };
          }) || [];
        if (type == 1) {
          setList(list);
        } else if (type == 2) {
          setHdList(list);
        } else if (type == 3) {
          setOtherList(list);
        }
      }
    });
  };
  return (
    <div className="homepage">
      <div className="searchBanner">
        <div className="w1200">
          <h1>{t(`放心淘 无忧购 一站寄全球`)}</h1>
          <div className="searchBox flexCenter">
            <Select
              placeholder={t("请选择")}
              options={options}
              bordered={false}
              defaultValue={1}
              onChange={(type) => {
                setObj({
                  ...obj,
                  type,
                });
              }}
            />
            <Input
              onChange={(e) => {
                setObj({
                  ...obj,
                  searchValue: e.target.value,
                });
              }}
              onPressEnter={(e) => {
                let value = e.target.value;
                if (value && value != "") {
                  // 检测是否已同意搜索条款
                  if (localStorage.getItem("searchTips") != "show") {
                    props.searchShow && props.searchShow(obj.searchValue);
                    return;
                  }
                  if (obj.type == 1) {
                    // 商品
                    props.navigate("/goods", {
                      state: {
                        text: obj.searchValue,
                      },
                    });
                    // 清除图片文件源
                    dispatch({
                      type: "setSearchFile",
                      data: {
                        file: "",
                      },
                    });
                  } else if (obj.type == 2) {
                    // 店铺
                  }
                }
              }}
              className="rowFlex"
              placeholder={t("请输入商品链接或者名称/店铺链接/PI")}
              bordered={false}
            />
            <Upload
              maxCount={1}
              showUploadList={false}
              beforeUpload={(file) => {
                // 检测是否已同意搜索条款
                if (localStorage.getItem("searchTips") != "show") {
                  props.searchShow && props.searchShow(obj.searchValue);
                  return;
                }
                // 清除图片文件源
                dispatch({
                  type: "setSearchFile",
                  data: {
                    file: "",
                  },
                });
                const reader = new FileReader();
                reader.onload = (e) => {
                  let url = e.target.result;
                  dispatch({
                    type: "setSearchFile",
                    data: {
                      file: url,
                    },
                  });
                };
                reader.readAsDataURL(file);
                // 跳转页面
                if (obj.type == 1) {
                  // 商品
                  props.navigate("/goods?type=img", {
                    state: {
                      text: "",
                    },
                  });
                }
                return false;
              }}
            >
              <div className="upbtn flexAllCenter cursor">
                <i className="iconfont icon-tupian"></i>
              </div>
            </Upload>
            <Button
              onClick={() => {
                if (obj.searchValue == "") {
                  return;
                }
                // 检测是否已同意搜索条款
                if (!localStorage.getItem("searchTips") != "show") {
                  props.searchShow && props.searchShow(obj.searchValue);
                  return;
                }
                if (obj.type == 1) {
                  // 商品
                  props.navigate("/goods", {
                    state: {
                      text: obj.searchValue,
                    },
                  });
                } else if (obj.type == 2) {
                  // 店铺
                }
              }}
              type="primary"
            >
              {t("搜索")}
            </Button>
          </div>
          <div className="searchDesc flex">
            <div className="item">
              <p className="round"></p>
              <p>{t("下单")}</p>
              <p>{t("挑选商品下单")}</p>
            </div>
            <div className="item">
              <p className="round"></p>
              <p>{t("提包")}</p>
              <p>{t("核实包裹")}</p>
            </div>
            <div className="item">
              <p className="round"></p>
              <p>{t("收货")}</p>
              <p>{t("签收包裹")}</p>
            </div>
          </div>
        </div>
      </div>
      {/* 滚动轮播 */}
      {banner.length > 0 && (
        <div className="w1200 banners">
          <div style={{ width: 1170 }}>
            <Carousel
              ref={sliderRef}
              className="w1200"
              slidesToShow={4}
              infinite
              autoplay
              autoplaySpeed={6000}
              dots={false}
            >
              {banner.map((item, index) => (
                <div
                  className="item"
                  key={String(index)}
                  onClick={() => {
                    if (item.activeUrl && item.activeUrl != "#") {
                      props.navigate(item.activeUrl);
                    }
                  }}
                >
                  <img alt="" src={item.picurl} className="pic" />
                </div>
              ))}
            </Carousel>
            <img
              onClick={() => {
                sliderRef.current.prev();
              }}
              alt=""
              src={require("../../imgs/jt.png")}
              className="jt prev"
            />
            <img
              onClick={() => {
                sliderRef.current.next();
              }}
              alt=""
              src={require("../../imgs/jt.png")}
              className="jt next"
            />
          </div>
        </div>
      )}
      {/* <Goodslist /> */}
      {/* 一站式服务 */}
      <div className="w1200 serviceBox">
        <h1>{t("一站式服务")}</h1>
        <div className="list flex">
          <div className="item rowFlex">
            <img
              alt=""
              src={require("../../imgs/caigoumaidantubiao.png")}
              className="icon-box"
            />
            <p className="title">{t("订单购买")}</p>
            <p className="desc">
              {t(
                "我们将帮助您购买中国所有线上、线下渠道的商品，您只需粘贴商品链接完成下单，我们将提供全方位的代购服务保障。"
              )}
            </p>
          </div>
          <div className="item rowFlex">
            <img
              alt=""
              src={require("../../imgs/shangjiafahuo.png")}
              className="icon-box"
            />
            <p className="title">{t("商家发货至仓库")}</p>
            <p className="desc">
              {t(
                "您可以通过我们购买不同商家的商品，这些商品都会汇聚至Bccbuy中国仓库。"
              )}
            </p>
          </div>
          <div className="item rowFlex">
            <img
              alt=""
              src={require("../../imgs/zhiliangchengzhong.png")}
              className="icon-box"
            />
            <p className="title">{t("质量称重")}</p>
            <p className="desc">
              {t(
                "您的商品到达仓库后，我们将会为您的商品进行验收质检并将结果反馈给您，您将在Bccbuy得到商品售后服务保障。"
              )}
            </p>
          </div>
          <div className="item rowFlex">
            <img
              alt=""
              src={require("../../imgs/guojiyunshu.png")}
              className="icon-box"
            />
            <p className="title">{t("国际运输")}</p>
            <p className="desc">
              {t(
                "您可以将寄存在仓库里的商品自由选择拼装成一个包裹，通过选择Bccbuy提供的可靠的物流商寄送到您的家里。"
              )}
            </p>
          </div>
        </div>
      </div>
      {/* 公告 */}
      <div className="w1200 noticeBox">
        <h1>{t("公告")}</h1>
        <div className="flexAllCenter">
          <p className="desc">{t("最新资讯")}</p>
          <p onClick={() => props.navigate("/ntcenter")} className="link">
            {t("查看更多")}&gt;
          </p>
        </div>
        <div className="list">
          <div className="item rowFlex">
            <div className="item_top bglv">
              <p className="bgbai">
                <i className="colorlv iconfont icon-a-LogisticsUpdates"></i>
              </p>
              <span>{t("物流资讯")}</span>
            </div>
            <div className="msglist">
              {wlList.length == 0 && <Empty />}
              {wlList.map((item, index) => (
                <div className="msg_item" key={String(index)}>
                  <p className="time">
                    {moment(item.createdTime).format("YYYY-MM-DD HH:mm:ss")}
                  </p>
                  <p
                    className="cont line2"
                    onClick={() =>
                      props.navigate("/ntdetail", {
                        state: {
                          id: item.id,
                        },
                      })
                    }
                  >
                    {item.title}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="item rowFlex">
            <div className="item_top bgzi">
              <p className="bgbai">
                <i className="colorzi iconfont icon-a-SpecialPlanning"></i>
              </p>
              <span>{t("活动专栏")}</span>
            </div>
            <div className="msglist">
              {hdList.length == 0 && <Empty />}
              {hdList.map((item, index) => (
                <div className="msg_item" key={String(index)}>
                  <p className="time">
                    {moment(item.createdTime).format("YYYY-MM-DD HH:mm:ss")}
                  </p>
                  <p
                    className="cont line2"
                    onClick={() =>
                      props.navigate("/ntdetail", {
                        state: {
                          id: item.id,
                        },
                      })
                    }
                  >
                    {item.title}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="item rowFlex">
            <div className="item_top bgyellow">
              <p className="bgbai">
                <i className="coloryellow iconfont icon-a-FeaturedFunctions"></i>
              </p>
              <span>{t("其他")}</span>
            </div>
            <div className="msglist">
              {otherList.length == 0 && <Empty />}
              {otherList.map((item, index) => (
                <div className="msg_item" key={String(index)}>
                  <p className="time">
                    {moment(item.createdTime).format("YYYY-MM-DD HH:mm:ss")}
                  </p>
                  <p
                    className="cont line2"
                    onClick={() =>
                      props.navigate("/ntdetail", {
                        state: {
                          id: item.id,
                        },
                      })
                    }
                  >
                    {item.title}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* 推荐商品 */}
      <div className="w1200 tjbox">
        <h1 className="pubh1 center">{t("大家在看什么")}</h1>
        <div className="flwp list">
          <div className="list" style={{ minHeight: 400 }}>
            <InfiniteScroll
              dataLength={list.length}
              refreshFunction={refresh}
              hasMore={hasMore}
              endMessage={
                <p className="row10 center font14 color9 paddb12">
                  {list.length < total ? (
                    <b
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setPage(page + 1);
                        setHasMore(true);
                      }}
                    >
                      {t("点击加载更多")}
                    </b>
                  ) : is_error ? (
                    <b
                      onClick={() => {
                        refresh();
                      }}
                      className="paddb12"
                    >
                      {t("数据加载失败")}，
                      <Button
                        className="link"
                        type="link"
                        style={{ paddingLeft: 0 }}
                      >
                        {t("重新获取")}
                      </Button>
                    </b>
                  ) : (
                    <b className="paddb12">{t("没有更多了")}~</b>
                  )}
                </p>
              }
              loader={<Loading />}
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                padding: 10,
              }}
            >
              {list.map((item, index) => (
                <div
                  onClick={() => {
                    window.open(
                      `/product?platform=${platform(item.detailUrl)}&num_iid=${
                        item.productId
                      }`,
                      "_blank"
                    );
                  }}
                  className="item"
                  key={String(index)}
                >
                  <img alt="" src={item.picUrl} className="pic" />
                  <div className="user_cont flexCenter margint4">
                    <PlatformIcon platform={platform(item.detailUrl)} />
                    <div className="tcont">
                      <p className="rowFlex line2" style={{ color: "black" }}>
                        {item.title}
                      </p>
                    </div>
                  </div>
                  <p className="font12 color9 marginl4 margint4">
                    {t("评分")}：{item.score}
                  </p>
                  <Price className="block" value={item?.price?.value} />
                </div>
              ))}
            </InfiniteScroll>
          </div>
          {/* {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((item, index) => (
                        <div onClick={() => props.navigate('/product')} className="item" key={String(index)}>
                            <img alt='' src={require('../../imgs/pic.png')} className="pic" />
                            <div className="flexCenter tcont">
                                <img alt='' src={require('../../imgs/tao.png')} className="icon" />
                                <p className="rowFlex line1">Carhartt卡哈特加厚夹克棉衣灯芯绒羊羔绒外套情侣宽松复古男女款</p>
                            </div>
                            <p className="pri">CNY 155</p>
                            <div className="user_cont flex">
                                <p>m****yy</p>
                                <p className="marginlauto">{t("分钟前浏览", {
                                    num: 4,
                                })}</p>
                            </div>
                        </div>
                    ))} */}
        </div>
      </div>
      <Modal
        footer={null}
        centered
        width={400}
        open={showcode}
        maskClosable={false}
        onCancel={() => setshowcode(false)}
        className="loginbox"
      >
        <Tabs
          items={tabs}
          activeKey={activeKey}
          onTabClick={(key) => {
            setdisabled(true);
            setActiveKey(key);
          }}
        />
        <Form
          className="margint10"
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Form.Item
              name="email"
              style={{ flex: 1 }}
              rules={[{ required: true, message: t("请输入邮箱") }]}
            >
              <Input
                placeholder={`${t("邮箱")}(${t("请填写真实可用的邮箱")})`}
                bordered={false}
                prefix={
                  <i
                    className="iconfont icon-youxiang1"
                    style={{ color: "#c0c4cc" }}
                  ></i>
                }
                disabled={disabled}
              />
            </Form.Item>
            <p
              style={{
                marginBottom: "21px",
                paddingLeft: "5px",
                paddingRight: "5px",
                cursor: "pointer",
                backgroundColor: "#f8f8f8",
                height: "48px",
                lineHeight: "48px",
              }}
              onClick={() => {
                setdisabled(false);
              }}
            >
              <i
                className="iconfont icon-bianji"
                style={{ fontSize: "22px" }}
              ></i>
            </p>
          </div>
          {activeKey == "reg" && (
            <Form.Item name="invitationCode">
              <Input
                placeholder={`${t("请输入邀请码")}（${t("选填")}）`}
                bordered={false}
                prefix={
                  <i
                    className="iconfont icon-a-bianzu5"
                    style={{ color: "#c0c4cc" }}
                  ></i>
                }
              />
            </Form.Item>
          )}
          {activeKey == "reg" && (
            <AgreementModal
              className="row mt-1"
              onChange={handleAgreementChange}
              code="privacy"
            />
          )}
          <Button
            loading={loading}
            type="primary"
            block
            htmlType="submit"
            className="weight600"
            style={{ height: 40 }}
          >
            {activeKey == "reg" ? t("注册") : t("绑定")}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default forwardRef(Index);
