import React from 'react';
import ReactDOM from 'react-dom/client';
import "core-js/es";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import './reset.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'dayjs/locale/zh-cn';
import './lang/i18n';
import { Provider } from 'react-redux';
import { persistor, store } from './Redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

// global.httpUrl = "https://daigou.sandbox.golads.com/";
global.httpUrl = "/";
// global.httpUrl = "http://requestbin.cn/1oxq4t11/";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
