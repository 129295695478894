import { Affix, App, Button, Checkbox, Empty, Input, Pagination, Select, Tabs } from "antd";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Price from "../../Common/Price";
import PlatformIcon from "../../Common/PlatformIcon";

const Index = (props, _ref) => {
    const { t } = useTranslation();
    const itemRef = useRef();
    const { modal, message } = App.useApp();
    const [obj, setObj] = useState({});
    const [type, setType] = useState('2');
    const [goodsList, setGoodsList] = useState([]);
    const [imgHeight, setImgHeight] = useState(0);
    const [searchObj, setSearchObj] = useState({
        page: 1,
        size: 10,
    })
    useEffect(() => {
        document.title = t('收藏夹');
        // 
        refresh();
        //eslint-disable-next-line
    }, [type, searchObj]);
    const refresh = () => {
        if (type == 2) {
            getGoods();
        }
    }
    // 获取收藏商品
    const getGoods = () => {
        global.Ajax.GET(`api/Favorite/List?productName=${searchObj.productName || ''}&platForm=${searchObj.platForm || 0}&pageIndex=${searchObj.page}&pageSize=${searchObj.size}`).then(res => {
            if (res.isSuccess) {
                setGoodsList(res.result);
                setTimeout(() => {
                    imgHeight == 0 && setImgHeight(itemRef.current.offsetWidth)
                }, 10);
            }
        })
    }
    // 取消收藏
    const del = (platForm, id) => {
        modal.confirm({
            title: t("提示"),
            content: t("确定要删除商品？"),
            centered: true,
            autoFocusButton: false,
            onOk: () => {
                let url = `api/Favorite/Delete/${platForm}/${id}`,
                    data = {},
                    method = "DELETE";
                if (platForm == 'del') {  // 批量删除
                    url = 'api/Favorite/BatchDelete';
                    data = {
                        Ids: id,
                    }
                    method = 'POST'
                }
                global.Ajax.hhtc_request(url, data, method).then(res => {
                    if (res.isSuccess) {
                        message.success(res.message);
                        refresh();
                    } else {
                        message.error(res.message);
                    }
                })
            }
        })
    }
    // 选择商品
    const selectGoods = (index) => {
        let list = JSON.parse(JSON.stringify(goodsList));
        list[index].checked = !list[index].checked;
        setGoodsList(list);
    }
    // 判断全选是否全选
    const isAllChecked = () => {
        let list = JSON.parse(JSON.stringify(goodsList));
        // 判断一级是否全部选中
        let res = list.every(item => item.checked);
        return res;
    }
    // 判断全选是否半选
    const isAllIndeterminate = () => {
        let list = JSON.parse(JSON.stringify(goodsList));
        let res = false;
        res = list.some((item) => item.checked);
        res = isAllChecked() ? false : res;
        return res;
    }
    // 点击全选
    const checkAll = () => {
        let list = JSON.parse(JSON.stringify(goodsList));
        list.forEach(item => {
            item.checked = isAllChecked() ? false : true;
        });
        setGoodsList(list);
    }
    // 批量删除
    const batchDel = () => {
        if (isAllChecked() || isAllIndeterminate()) {
            let list = JSON.parse(JSON.stringify(goodsList));
            let ids = [];
            list.forEach(item => {
                item.checked && ids.push(item.id)
            });
            del('del', ids);
        }
    }
    return (
        <React.Fragment>
            <Tabs
                items={[
                    // { key: '1', label: t('店铺') },
                    { key: '2', label: t('商品') },
                ]}
                className="sctabs"
                onTabClick={(key) => {
                    setType(key);
                }}
            />
            <div className="flexCenter sc_searchbox">
                <Select
                    placeholder={t('选择平台')}
                    options={[
                        { label: t('淘宝'), value: 1 },
                        { label: t('1688'), value: 2 },
                        { label: t('京东'), value: 3 },
                    ]}
                    className="marginr10"
                    allowClear
                    style={{ width: 150 }}
                    onChange={(e) => {
                        setSearchObj({
                            ...searchObj,
                            platForm: e,
                        })
                    }}
                />
                {type == 1 && <Input placeholder={t("搜索备注名/店铺名")} style={{ width: 280 }} suffix={(<i className="iconfont icon-dingbusousuo"></i>)} />}
                {type == 2 && <Input onChange={(e) => {
                    setSearchObj({
                        ...searchObj,
                        productName: e.target.value,
                    })
                }} placeholder={t("输入收藏商品关键词")} style={{ width: 280 }} suffix={(<i className="iconfont icon-dingbusousuo"></i>)} allowClear />}
            </div>
            <div className="sclist flwp">
                {/* 商品 */}
                {type == 2 && <React.Fragment>
                    {/* 空数据 */}
                    {goodsList.length == 0 && <Empty style={{ margin: '100px auto 0' }} />}
                    {goodsList.map((item, index) => (
                        <div ref={index == 0 ? itemRef : null} className="goodsItem" key={String(index)}>
                            <Checkbox checked={item.checked} onClick={() => selectGoods(index)} />
                            <img alt='' src={item.picUrl} className="pic" style={{ height: imgHeight }} />
                            <p onClick={() => {
                                window.open(`/product?platform=${item.platForm}&num_iid=${item.productId}`, '_blank', "noreferrer")
                            }} className="font12 title line2">
                                <PlatformIcon platform={item.platForm} />
                                {item.title}
                            </p>
                            <Price className='block font18 weight600' value={item.price.value} />
                            {/* <p className="font18 weight600 pri">¥ 0.14</p> */}
                            <i onClick={() => del(item.platForm, item.productId)} className="iconfont icon-dingdanhuishouzhan cursor"></i>
                        </div>
                    ))}
                </React.Fragment>}
                {/* 店铺 */}
                {type == 1 && <React.Fragment>
                    {[1, 1, 1, 1, 1].map((item, index) => (
                        <div className="item49 shopItem flex" key={String(index)}>
                            <img alt='' src={require('../../imgs/pic.png')} className="pic" />
                            <div className="rowFlex rinfo">
                                <div className="flexCenter">
                                    <img alt='' src={require('../../imgs/tao.png')} className="width16 marginr10" />
                                    <h4 className="rowFlex line1">Perfect Soul完美的灵魂</h4>
                                    <i className="iconfont icon-dingdanhuishouzhan cursor"></i>
                                </div>
                                <div className="flexCenter samebox">
                                    <Input
                                        prefix={(<i className="iconfont icon-a-biaoqiannei font18 weight600"></i>)}
                                        suffix={(obj.id == index && obj.value ? <i className="iconfont icon-xuanzhong font14"></i> : null)}
                                        bordered={false}
                                        className="rowFlex marginr10 inptbox"
                                        onChange={(e) => {
                                            setObj({
                                                id: index,
                                                value: e.target.value,
                                            })
                                        }}
                                    />
                                    <div className="flexCenter marginl20">
                                        <span className="color6">{t("查找相似店铺")}</span>
                                        <i className="iconfont icon-liuchengjiantou color6"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </React.Fragment>}
            </div>
            {/* 底部操作栏 */}
            {type == 2 && goodsList.length > 0 && <Affix offsetBottom={0}>
                <div className="fixbot flexCenter bgbai">
                    <Checkbox onClick={() => checkAll()} checked={isAllChecked()} indeterminate={isAllIndeterminate()}>{t("全选")}</Checkbox>
                    <Button onClick={() => batchDel()} type="link" size="small" className={`${(isAllChecked() || isAllIndeterminate()) ? 'colorlv' : 'color9'}`}>{t("删除")}</Button>
                    <Button disabled type="link" size="small" className="colorlv">{t("查看仓库照片")}</Button>
                    <Pagination className="marginlauto" total={goodsList.length}
                        showSizeChanger
                        showQuickJumper
                        showTotal={(total) => {
                            return `${t("共条", {
                                num: total,
                            })}`
                        }}
                        pageSize={searchObj.size}
                        onChange={(page, size) => {
                            setSearchObj({
                                ...searchObj,
                                page, size
                            })
                        }} onShowSizeChange={(page, size) => {
                            setSearchObj({
                                ...searchObj,
                                page, size
                            })
                        }}
                    />
                </div>
            </Affix>}
        </React.Fragment>
    )
}

export default forwardRef(Index);