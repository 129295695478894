import moment from "moment-timezone";
export default class Helper {
  static getCurrentDate(showHMS) {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    if (hour < 10) {
      hour = "0" + hour;
    }
    if (minute < 10) {
      minute = "0" + minute;
    }
    if (second < 10) {
      second = "0" + second;
    }
    if (showHMS) {
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    }
    return year + "-" + month + "-" + day;
  }
  static getorderpaystate = (PayState, payScenario, tradeType) => {
    const OrderPayStateEnum = {
      0: "",
      2: (payScenario, tradeType) => {
        return payScenario == 1 || payScenario == 3
          ? "已支付"
          : tradeType == 1
          ? "已充值"
          : "已扣款";
      },
      3: "退款中",
      4: "已退款",
      6: "申请退款",
      25: "失败",
      HavePaid: (payScenario, tradeType) => {
        return payScenario == "SaleOrderPay" || payScenario == "ParcelOrderPay"
          ? "已支付"
          : tradeType == "Pay"
          ? "已充值"
          : "已扣款";
      },
      BeingRefunded: "退款中",
      AlreadyRefund: "已退款",
      Fail: "失败",
      RefundRequested: "申请退款",
    };
    const description = OrderPayStateEnum[PayState];
    if (typeof description === "function") {
      return description(payScenario, tradeType);
    } else {
      return description || PayState;
    }
  };
  // 格式化时间
  static formatDateTime = (time, separator = "-") => {
    // let timestamp = Date.parse(`${time}Z`);
    const date = new Date(time);
    const YY = date.getFullYear() + separator;
    const MM =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + separator;
    const DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const hh =
      (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    const mm =
      (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
      ":";
    const ss =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return YY + MM + DD + " " + hh + mm + ss;
  };
  // 去掉富文本中的标签和图片
  static removeLableAndImage(html) {
    let msg = html
      .replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, "\n")
      .replace(/<br\/?>/gi, "\n")
      .replace(/<[^>/]+>/g, "")
      .replace(/(\n)?<\/([^>]+)>/g, "")
      .replace(/\u00a0/g, " ")
      .replace(/&nbsp;/g, " ")
      .replace(/<\/?(img)[^>]*>/gi, "")
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&#39;/g, "'")
      .replace(/&quot;/g, '"')
      .replace(/<\/?.+?>/g, "");
    return msg;
  }
  static colorList() {
    return new Promise((resolve, reject) => {
      global.Ajax.GET("api/Common/Colours").then((res) => {
        if (res.isSuccess) {
          let result = res.result;
          resolve(result);
        } else {
          reject([]);
        }
      });
    });
  }
  // 计算总价
  static calcTotal(list) {
    let totalPrice = 0;
    for (let i in list) {
      let item = list[i];
      let price = item.num * 10000 * item.price.value;
      totalPrice += price;
    }
    totalPrice = totalPrice / 10000;
    return totalPrice;
  }
  static getplatFormKey(str) {
    let arr = ["", "TaoBao", "Alibaba", "JD"];
    return arr[str];
  }
  static getpaytype(str) {
    let paytypeoption = {
      1: "余额",
      2: "Paypal",
      3: "支付宝",
    };
    return paytypeoption[str] || "";
  }
  // 获取热门城市数组
  static getHot(list) {
    const arr = [
      "美国",
      "英国",
      "加拿大",
      "澳大利亚",
      "德国",
      "法国",
      "荷兰",
      "波兰",
      "西班牙",
      "爱尔兰",
      "意大利",
      "新西兰",
      "瑞典",
      "挪威",
      "比利时",
      "墨西哥",
      "日本",
      "巴西",
      "以色列",
      "丹麦",
      "瑞士",
      "罗马尼亚",
    ];
    let hot = [];
    for (let i in arr) {
      let obj = list.find((item) => {
        return item.name == arr[i];
      });
      if (obj) {
        hot.push(obj);
      }
    }
    return hot;
  }
  // 获取中国城市
  static getChinaList() {
    let list = [
      { value: "Other", label: "其他省市", icon: "cn" },
      { value: "Guangdong", label: "广东", icon: "cn" },
      { value: "Xinjiang", label: "新疆", icon: "cn" },
      { value: "Guangxi", label: "广西", icon: "cn" },
      { value: "Fujian", label: "福建", icon: "cn" },
      { value: "Hubei", label: "湖北", icon: "cn" },
      { value: "Shaanxi", label: "陕西", icon: "cn" },
      { value: "Sichuan", label: "四川", icon: "cn" },
      { value: "Chongqing", label: "重庆", icon: "cn" },
      { value: "Henan", label: "河南", icon: "cn" },
      { value: "Anhui", label: "安徽", icon: "cn" },
      { value: "Jiangxi", label: "江西", icon: "cn" },
      { value: "Shanxi", label: "山西", icon: "cn" },
      { value: "Yunnan", label: "云南", icon: "cn" },
      { value: "Guizhou", label: "贵州", icon: "cn" },
      { value: "Xizang", label: "西藏", icon: "cn" },
      { value: "Jiangsu", label: "江苏", icon: "cn" },
      { value: "Zhejiang", label: "浙江", icon: "cn" },
      { value: "Shanghai", label: "上海", icon: "cn" },
      { value: "Beijing", label: "北京", icon: "cn" },
      { value: "Tianjin", label: "天津", icon: "cn" },
      { value: "Hebei", label: "河北", icon: "cn" },
      { value: "Liaoning", label: "辽宁", icon: "cn" },
      { value: "Shandong", label: "山东", icon: "cn" },
      { value: "Gansu", label: "甘肃", icon: "cn" },
      { value: "Qinghai", label: "青海", icon: "cn" },
      { value: "Neimenggu", label: "内蒙古", icon: "cn" },
      { value: "Ningxia", label: "宁夏", icon: "cn" },
      { value: "Heilongjiang", label: "黑龙江", icon: "cn" },
      { value: "Jilin", label: "吉林", icon: "cn" },
      { value: "Hunan", label: "湖南", icon: "cn" },
      { value: "Hainan", label: "海南", icon: "cn" },
    ];
    let arr = [];
    for (let i in list) {
      arr.push({
        ...list[i],
        nameEn: list[i].value,
        name: list[i].label,
      });
    }
    return arr;
  }
  // 获取其他城市
  static getCountry() {
    let list = [];
    return new Promise((resolve, reject) => {
      global.Ajax.GET("api/Common/Nations").then((res) => {
        if (res.isSuccess) {
          let result = res.result;
          for (let i in result) {
            let label = result[i].label.split("-");
            let en = label[0],
              cn = label[1];
            list.push({
              nameEn: en,
              name: cn,
              icon: result[i].icon,
              value: result[i].value,
              title: result[i].label,
            });
          }
          resolve(list);
        } else {
          reject([]);
        }
      });
    });
  }
  // 币种符号
  static getUnit(num) {
    let list = [
      "",
      "$",
      "¥",
      "€",
      "￡",
      "C$",
      "JPY¥",
      "₩",
      "A$",
      "HK$",
      "RM",
      "NT$",
      "kr",
    ];
    return list[num];
  }
  // 根据编码查找图片
  static getPic(code, list) {
    let row = list.find((item) => item.properties == code);
    return row ? row.url : null;
  }
  // 二维数组转换成一维数组
  static arr2Toarr1(arr) {
    let arr2 = [];
    for (let i in arr) {
      arr2 = [...arr2, ...arr[i].products];
    }
    return arr2;
  }
  // 计算单个商品总价
  static getSumPrice(price, num) {
    let sum = (price * 10000 * num) / 10000;
    return sum;
  }
  // 根据汇率换算金额
  static convertPrice(price, rate) {
    let res = (price * 10000 * rate) / 10000;
    return res.toFixed(2);
  }
  // 保留两位小数且不四舍五入
  static keepTwoDecimals(num) {
    let str = num.toString(); // 转换成字符串形式
    if (str.indexOf(".") == -1) {
      // 没有小数点
      return str;
    }
    let result = str.substring(0, str.indexOf(".") + 3);
    return result;
  }
  // 获取随机字符串
  static getRandomString(length) {
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"; // 包含所有大写、小写字母及数字的字符集合
    let result = "";

    for (let i = 0; i < length; i++) {
      let randomIndex = Math.floor(Math.random() * chars.length); // 生成一个介于 0 到 chars.length-1 之间的随机索引值
      result += chars[randomIndex]; // 根据索引从字符集合中选择对应的字符添加到结果字符串中
    }

    return result;
  }
  // 剔除数组中的某些元素
  static filterArray(arr, removeArr) {
    for (let i in removeArr) {
      arr = arr.filter((item) => item.id != removeArr[i].tagId);
    }
    return arr;
  }
  // 生成guid字符串
  static getGUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  static utc2time(utc) {
    const localTime = moment.utc(utc).local();
    // 格式化为YYYYMMDD
    const formattedTime = localTime.format("YYYY-MM-DD HH:mm:ss");
    return formattedTime;
  }
  static numbermessage(rule, value,min, max, t) {
    if(parseFloat(value) < min){
      return Promise.reject(t("最小值为")  + min)
    }else if (parseFloat(value) > parseFloat(max)) {
      return Promise.reject(t("最大值为")  + max);
    } else {
      return Promise.resolve();
    }
  }
}
