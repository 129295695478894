import { Button, Form, Input, Radio, Select, theme } from "antd";
import React, { forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
const country = require('../country.json');

const Index = (props, _ref) => {
    const {t}=useTranslation();
    const { token: {
        colorLink
    } } = theme.useToken();
    useEffect(() => {
        document.title = t('收款设置');
        //eslint-disable-next-line
    }, []);
    return (
        <React.Fragment>
            <span onClick={()=>props.navigate(-1)} className="font12 cursor" style={{ color: colorLink }}>{t("返回推广联盟")}</span>
            <h1 className="margint20">{t("提现账号")}</h1>
            <Form
                colon={false}
                layout="vertical"
            >
                <Form.Item className="item49" label={t('国家1')} name='country' rules={[{ required: true, message: t('请选择国家') }]}>
                    <Select
                        placeholder={t('请选择')}
                        options={country}
                        optionRender={(option) => {
                            const { icon, label } = option.data;
                            return (
                                <div>
                                    <img alt='' src={require(`../../imgs/icon/${icon}.png`)} className="width16 marginr4" />
                                    {label}
                                </div>
                            )
                        }}
                    />
                </Form.Item>
                <Form.Item className="item49" label={t('银行卡类型')} name='type' rules={[{ required: true, message: t('请选择银行卡类型') }]}>
                    <Radio.Group
                        options={[
                            { label: t('国际'), value: 1 },
                            { label: t('中国'), value: 2 },
                        ]}
                    />
                </Form.Item>
                <div className="flwp">
                    <Form.Item className="item49" label={t('币种')} name='type' rules={[{ required: true, message: t('请选择币种') }]}>
                        <Select
                            options={[
                                { label: 'CNY', value: 'CNY' }
                            ]}
                            placeholder={t('请选择')}
                        />
                    </Form.Item>
                    <Form.Item className="item49" label={t('收款人姓名')} name='type' rules={[{ required: true, message: t('请输入收款人姓名') }]}>
                        <Input placeholder={t("请输入")} />
                    </Form.Item>
                    <Form.Item className="item49" label={t('收款账号')} name='type' rules={[{ required: true, message: t('请输入收款账号') }]}>
                        <Input placeholder="请输入" />
                    </Form.Item>
                    <Form.Item className="item49" label={t('收款行名称')} name='type' rules={[{ required: true, message: t('请输入收款行名称') }]}>
                        <Input placeholder={t("请输入" )}/>
                    </Form.Item>
                    {/* <Form.Item className="item49" label={t('所在分行名称')} name='type' rules={[{ required: true, message: t('请输入所在分行名称') }]}>
                        <Input placeholder={t("请输入")} />
                    </Form.Item> */}
                    <Form.Item className="item49" label={t('收款行ABA')} name='type' rules={[{ required: true, message: t('请输入收款行ABA') }]}>
                        <Input placeholder={t("请输入")} />
                    </Form.Item>
                    <Form.Item className="item49" label={t('银行地址')} name='type' rules={[{ required: true, message: t('请输入银行地址') }]}>
                        <Input placeholder={t("请输入")} />
                    </Form.Item>
                    <Form.Item className="item49" label={`${t('SWIFT代码')}(BIC)`} name='type' rules={[{ required: true, message: t('请输入SWIFT代码') }]}>
                        <Input placeholder={t("请输入")} />
                    </Form.Item>
                    <Form.Item className="item49" label={t('收款人地址')} name='type' rules={[{ required: true, message: t('请输入收款人地址') }]}>
                        <Input placeholder={t("请输入")} />
                    </Form.Item>
                </div>
                <Form.Item label={t('收款人备注')} name='type'>
                    <Input.TextArea rows={2} placeholder={t("请输入")} />
                </Form.Item>
                <Button type="primary" htmlType="submit">{t("保存")}</Button>
            </Form>
        </React.Fragment>
    )
}

export default forwardRef(Index);