import { Table, Tabs, theme } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Index = (props, _ref) => {
    const {t}=useTranslation();
    const { token: {
        colorLink
    } } = theme.useToken();
    const [activeKey, setActiveKey] = useState('1');
    // 我的奖金
    const columns = [{
        title: t('时间'),
        dataIndex: 'time',
    }, {
        title: t('包裹'),
        dataIndex: 'time',
    }, {
        title: t('预估佣金'),
        dataIndex: 'time',
    }, {
        title: t('实际佣金'),
        dataIndex: 'time',
    }, {
        title: t('结算状态'),
        dataIndex: 'time',
    }, {
        title: t('包裹状态'),
        dataIndex: 'time',
    }, {
        title: t('退包状态'),
        dataIndex: 'time',
    }]
    // 额外奖金
    const columns_ew = [{
        title: t('时间'),
        dataIndex: 'time',
    }, {
        title: t('包裹'),
        dataIndex: 'time',
    }, {
        title: `${t("活动奖金")}CNY`,
        dataIndex: 'time',
    }, {
        title: t('奖金状态'),
        dataIndex: 'time',
    }]
    const columns_js = [{
        title: t('结算时间'),
        dataIndex: 'time',
    }, {
        title: t('结算金额'),
        dataIndex: 'time',
    }, {
        title: t('可领取奖金'),
        dataIndex: 'time',
    }, {
        title: t('过期时间'),
        dataIndex: 'time',
    }, {
        title: t('包裹'),
        dataIndex: 'time',
    }]
    // 结算记录
    useEffect(() => {
        document.title = t('奖金');
        //eslint-disable-next-line
    }, [])
    return (
        <React.Fragment>
            <span onClick={() => props.navigate(-1)} className="font12 cursor" style={{ color: colorLink }}>{t("返回推广联盟")}</span>
            <Tabs
                items={[
                    { label: t('我的奖金'), key: '1' },
                    { label: t('额外奖金'), key: '2' },
                    { label: t('结算记录'), key: '3' },
                ]}
                className="margint10 pl_tabs"
                activeKey={activeKey}
                onTabClick={(key) => {
                    setActiveKey(key)
                }}
            />
            <Table
                dataSource={[]}
                columns={activeKey == 1 ? columns : (activeKey == 2 ? columns_ew : columns_js)}
                pagination={false}
            />
        </React.Fragment>
    )
}

export default forwardRef(Index);