import React, { forwardRef } from "react";

const PlatformIcon = (props, _ref) => {
  const imgurl = () => {
    if (props.platform == 1) {
      return require("../imgs/tao.png");
    } else if (props.platform == 2) {
      return require("../imgs/1688.png");
    } else if (props.platform == 3) {
      return require("../imgs/jingdong.png");
    } else {
      return "";
    }
  };
  return <img alt="" src={imgurl()} className={`width16 ${props.className}`} />;
};

export default forwardRef(PlatformIcon);
