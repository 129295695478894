import { Menu } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import ScrollToTop from "../ScrollToTop";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const Index = (props, _ref) => {
  const { t } = useTranslation();
  const items = [
    getItem(t("个人首页"), "percenter"),
    getItem(t("购物车"), "cart"),
    getItem(t("收藏夹"), "collection"),
    { type: "divider", key: "-1" },
    getItem(t("我的订单"), "orderlist"),
    getItem(t("我的仓库"), "storage"),
    // getItem(t('预演包裹'), 'preview'),
    getItem(t("我的包裹"), "parcel"),
    { type: "divider", key: "-2" },
    // getItem(t('我的评论'), 'comments'),
    getItem(t("我的资产"), "balance/moneyBag"),
    getItem(t("我的积分"), "integral"),
    // getItem(t('我的咨询'), 'consult'),
    // getItem(t('站内信'), 'message'),
    { type: "divider", key: "-3" },
    getItem(t("账户设置"), "account/userinfo"),
    // getItem(t('自定义功能'), 'mcenter'),
    { type: "divider" },
    getItem(t("推广联盟"), "promy"),
  ];
  const [selectedKeys, setSelectedKeys] = useState(["percenter"]);
  useEffect(() => {
    let pathname = props.location.pathname;
    let arr = pathname.split("/");
    let path = arr.at(-1);
    if (pathname.indexOf("account") != -1) {
      setSelectedKeys(["account/userinfo"]);
    } else if (pathname.indexOf("balance") != -1) {
      setSelectedKeys(["balance/moneyBag"]);
    } else if (pathname.indexOf("promy") != -1) {
      setSelectedKeys(["promy"]);
    } else {
      if (path) {
        let row = items.find((item) => item.key == path);
        row && setSelectedKeys([path]);
      }
    }
    //eslint-disable-next-line
  }, [props.location.pathname]);
  return (
    <React.Fragment>
      <div className="bgbai userbox">
        <div className="bgf5">
          <div
            className="w1200 flexCenter cursor tips"
            onClick={() => {
              props.navigate("/help/hpcenter");
            }}
          >
            <img alt="" src={require("../../imgs/ling.png")} />
            {t("以图搜物使用指南")}
          </div>
        </div>
        <div className="w1200 flex cen">
          <Menu
            items={items}
            selectedKeys={selectedKeys}
            style={{ width: "15%" }}
            className="usermenu"
            onClick={(e) => {
              setSelectedKeys(e.keyPath);
              props.navigate(`/user/${e.key}`);
            }}
          />
          <ScrollToTop>
            <div className="content rowFlex">
              <Outlet />
            </div>
          </ScrollToTop>
        </div>
      </div>
    </React.Fragment>
  );
};

export default forwardRef(Index);
