import { Button, Form, Input, Modal, Table } from "antd";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

// 上传图片
const CustomUpload = () => {
    return (
        <div className="cursor dashbtn marginl10">
            {/* <i className="iconfont icon-tupian"></i> */}
            <img alt='' src={require('../../imgs/jia.png')} className="width16" />
        </div>
    )
}
// 数量步进器
const NumberStep = () => {
    // const triggerChange = (url) => {
    //     onChange?.(url);
    // };
    return (
        <div className="flexCenter bgf5" style={{ width: '50%', paddingInline: 15, }}>
            <img alt='' src={require('../../imgs/jian.png')} className="width16 cursor" />
            <Input readOnly bordered={false} defaultValue={1} className="rowFlex" style={{ textAlign: 'center', height: 36 }} />
            <img onClick={() => {
                // btnRef.current.click();
            }} alt='' src={require('../../imgs/jia.png')} className="width16 cursor" />
        </div>
    )
}

const Index = () => {
    const {t}=useTranslation();
    const btnRef = useRef(null);
    const [open, setOpen] = useState(false);
    const columns=[{
        title: t('模板'),
        dataIndex: 'name',
        className: 'font12',
    },{
        title: t('照片数量'),
        dataIndex: 'name',
        className: 'font12',
    },{
        title: t('操作'),
        dataIndex: 'name',
        className: 'font12',
        width: 150,
        render:()=>(
            <div className="flexCenter pubbtn">
                <p className="weight600">{t("编辑")}</p>
                <p className="weight600">{t("删除")}</p>
            </div>
        )
    }]
    const onCancel = () => {
        setOpen(false);
    }
    return (
        <React.Fragment>
            <Button onClick={() => setOpen(true)} type="primary" className="margint20 weight600">{t("新增模板")}</Button>
            {/* 空数据 */}
            {/* <div className="empty">
                <img className="pic" alt='' src={require('../../imgs/finePhoto.png')} />
                <div className="flexAllCenter">
                    <p>没有找到您查找的定制拍照模板，</p>
                    <span onClick={() => setOpen(true)} className="colorlv cursor">去添加精细照片模板</span>
                </div>
                <div className="flex">
                    <div className="rowFlex">
                        <h4>1.定制拍照模板的作用</h4>
                        <p className="font12">在结算、我的订单、我的仓库环节中添加定制拍照模板，用于商品到仓库后工作人员对您商品如模板要求进行拍摄</p>
                    </div>
                    <div className="rowFlex">
                        <h4>2.定制拍照模板可添加的信息</h4>
                        <p className="font12">当您编辑好照片模板后，只需选择您的模板即可同步照片模板中的水印、照片数量和对应的拍摄细节要求</p>
                    </div>
                    <div className="rowFlex">
                        <h4>3.如何添加定制拍照模板</h4>
                        <p className="font12">在购物车、我的订单、我的仓库环节可自定义添加标签</p>
                    </div>
                </div>
            </div> */}
            <Table
                dataSource={[1]}
                columns={columns}
                pagination={false}
                className="margint10"
            />
            <Modal
                title={t('定制拍照模板')}
                open={open}
                width={800}
                centered
                destroyOnClose
                footer={null}
                onCancel={onCancel}
                className="huihead"
            >
                <Form
                    colon={false}
                >
                    <Form.Item label={t('模板名称')} name='name' rules={[{ required: true, message: t('请输入模板名称') }]}>
                        <Input placeholder={t("请输入")} />
                    </Form.Item>
                    <Form.Item label={t('照片数量')} name='num' rules={[{ required: true }]}>
                        <NumberStep />
                    </Form.Item>
                    <p>{t("拍照要求")}</p>
                    <div className="bgf5 margint10" style={{padding: '20px 20px 0'}}>
                        <Form.List name='info' initialValue={[{}, {}]}>
                            {(fields, { add, remove }) => (
                                <React.Fragment>
                                    {fields.map((field) => (
                                        <div className="flexCenter" key={field.key}>
                                            <Form.Item style={{ width: '60%' }} label={`${t("照片")}1`} name={[field.name, 'name']} rules={[{ required: true, message: t('请输入备注内容') }]}>
                                                <Input.TextArea className='font12' rows={2} placeholder={t("仅支持输入1个拍照要求，例如：拍摄logo近照、测量手链尺寸之类的")} />
                                            </Form.Item>
                                            <Form.Item className="btn" label=' ' name={[field.name, 'img']}>
                                                <CustomUpload />
                                            </Form.Item>
                                            {fields.length > 1 && <i onClick={() => remove(field.name)} className="iconfont icon-dingdanhuishouzhan cursor marginl10" style={{ fontSize: 32, marginTop: -24 }}></i>}
                                        </div>
                                    ))}
                                    <p ref={btnRef} onClick={() => add()}></p>
                                </React.Fragment>
                            )}
                        </Form.List>
                    </div>
                    <Button type="primary" className="block marginlauto margint20" htmlType="submit">{t("确定")}</Button>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default Index;