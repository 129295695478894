import { LoadingOutlined } from "@ant-design/icons";
import { App, Button, DatePicker, Form, Input, Radio, Select, Upload, theme } from "antd";
import dayjs from "dayjs";
import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const CustomUpload = ({ value = '', onChange }) => {
    const { token: {
        colorPrimary
    } } = theme.useToken();
    const [loading, setLoading] = useState(false);
    const triggerChange = (url) => {
        onChange?.(url);
    }
    return (
        <Upload
            accept="image/png,image/jpg,image/jpeg"
            action={`${global.httpUrl}api/Common/UploadFile`}
            maxCount={1}
            showUploadList={false}
            onChange={(e) => {
                if (e.file.status == 'uploading') {
                    setLoading(true);
                }
                if (e.file.status == 'done') {
                    let res = e.file.response;
                    triggerChange(res.result.imageUrl);
                    setLoading(false);
                }
            }}
        // beforeUpload={(file)=>{
        //     let formData=new FormData();
        //     formData.append('file',file);
        //     global.Ajax.POST('api/Common/UploadFile',formData).then(res=>{
        //         if(res.isSuccess){
        //             triggerChange(res.result.imageUrl)
        //         }
        //     })
        //     return false;
        // }}
        >
            <div>
                {loading ?
                    <div className="flexAllCenter" style={{ width: 100, height: 100, }}>
                        <LoadingOutlined style={{ color: colorPrimary, fontSize: 40 }} />
                    </div>
                    :
                    <img alt='' src={value == '' ? require('../../imgs/add.png') : value} className="cursor" style={{ width: 100, height: 100, borderRadius: '100%' }} />
                }

            </div>
        </Upload>
    )
}

const Index = (props, _ref) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        // 获取国家
        global.Ajax.GET('api/Common/Nations').then(res => {
            if (res.isSuccess) {
                setList(res.result);
            }
        })
        // 
        const data = props.data;
        form.setFieldsValue({
            headPortrait: (data.headPortrait && data.headPortrait != '') ? data.headPortrait : undefined,
            name: data.name,
            phoneNumber: data.phoneNumber,
            birthday: data.birthday != '' ? dayjs(data.birthday, 'YYYY-MM-DD') : undefined,
            nation: data.nation,
            language: data.language,
            sex: data.sex > 0 ? data.sex : undefined,
        });
        //eslint-disable-next-line
    }, []);
    const onFinish = (data) => {
        data.birthday = data.birthday?.format?.('YYYY-MM-DD');
        data.memberId = props.data.memberId;
        global.Ajax.hhtc_request('api/Member/UpdateMemberInfo', data).then(res => {
            if (res.isSuccess) {
                message.success(res.message);
                props.onOk?.();
            } else {
                message.error(res.message);
            }
            setLoading(false);
        })
    }
    return (
        <Form
            form={form}
            colon={false}
            autoComplete="off"
            labelCol={{ flex: '100px' }}
            onFinish={onFinish}
        >
            <Form.Item label={t('头像')} name='headPortrait'>
                <CustomUpload />
            </Form.Item>
            <Form.Item label={t('用户名')} name='name'>
                <Input placeholder={t("请输入用户名")} />
            </Form.Item>
            <Form.Item label={t('手机')} name='phoneNumber'>
                <Input placeholder={t("请输入手机")} />
            </Form.Item>
            <Form.Item label={t('生日')} name='birthday'>
                <DatePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label={t('国家1')} name='nation'>
                <Select
                    placeholder={t('请选择')}
                    options={list}
                    optionRender={(option) => {
                        const { icon, label } = option.data;
                        return (
                            <div>
                                <img alt='' src={require(`../../imgs/icon/${icon}.png`)} className="width16 marginr4" />
                                {label}
                            </div>
                        )
                    }}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                />
            </Form.Item>
            <Form.Item label={t('语言')} name='language'>
                <Radio.Group
                    options={[
                        { label: 'English', value: 'es' },
                        { label: '中文', value: 'zh-CN' },
                        { label: 'Español', value: 'es-ES' },
                        { title: 'Русский язык', key: 'ru' },
                    ]}
                />
            </Form.Item>
            <Form.Item label={t('性别')} name='sex'>
                <Radio.Group
                    options={[
                        { label: t('男'), value: 1 },
                        { label: t('女'), value: 2 },
                    ]}
                />
            </Form.Item>
            <Button loading={loading} type="primary" shape="round" htmlType="submit" className="marginlauto" style={{ display: 'block' }}>{t("确定")}</Button>
        </Form>
    )
}

export default forwardRef(Index);