import React from "react";
import { useSelector } from "react-redux";
import Helper from "../class/Helper";

const Price = ({ className, value }) => {
    const { symbol, rate } = useSelector((state) => {
        return state.infos;
    })
    return (
        <React.Fragment>
            <span className={`pri ${className}`}>{symbol} {Helper.convertPrice(value, rate)}</span>
        </React.Fragment>
    )
}

export default Price;