import { DatePicker, Table, theme, Breadcrumb } from "antd";
import React, { forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Index = (props, _ref) => {
    const { t } = useTranslation();
    const { token: {
        colorError
    } } = theme.useToken();
    const columns = [{
        title: t("时间"),
        dataIndex: 'time'
    }, {
        title: t("订单号"),
        dataIndex: 'time'
    }, {
        title: t("成长值"),
        dataIndex: 'time'
    }, {
        title: t("当前等级"),
        dataIndex: 'time'
    }]
    useEffect(() => {
        document.title = t("成长值记录");
        //eslint-disable-next-line
    }, []);
    return (
        <React.Fragment>
            <Breadcrumb
                items={[
                    {
                        title: <p style={{ cursor: "pointer" }} onClick={() => { props.navigate('/user/account/myprivilege') }}>{t("我的特权")}</p>,
                    },
                    {
                        title: '成长值记录',
                    },

                ]}
            />
            <p className="color9 margint20">{t("我的成长值")}：<span style={{ color: colorError }}>0</span></p>
            <div className="margint20">
                <DatePicker.RangePicker />
            </div>
            <Table
                dataSource={[]}
                columns={columns}
                className="margint20"
            />
        </React.Fragment>
    )
}

export default forwardRef(Index);