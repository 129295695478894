import { Button } from "antd";
import React, { forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import "./proapp.css";

const Index = (props, _ref) => {
  const { t,i18n } = useTranslation();
  const [search] = useSearchParams();
  const formatlocal = (locale)=>{
    if(locale.includes('zh')){
        return "zh-CN"
    }else if(locale.includes('en')){
        return "en"
    }else if(locale.includes('es')){
        return "es"
    }else if(locale.includes('ru')){
        return "ru"
    }
    return "en"
  }
  useEffect(() => {
    document.title = t("中转");
    const currentLanguage = navigator.language || navigator.userLanguage;  
    localStorage.setItem("lang", formatlocal(currentLanguage));
    i18n.changeLanguage(formatlocal(currentLanguage));
    //eslint-disable-next-line
  }, []);
  
//   当前语言  zh en es ru ko 

  const download = ()=>{
    const a = document.createElement('a')
    a.href = 'https://apps.apple.com/us/app/bccbuy/id6630378705'
    a.click()
  }
  const open = ()=>{
    const a = document.createElement('a')
    a.href = `mycn://home?invitationCode=${search.get("invitationCode")}`
    a.click()
  }
  return (
    <div className="PromyApp">
      <div className="w1200">
        <img
          alt=""
          src={require("../../imgs/logo.jpg")}
          className="logo cursor"
        />
        <p className="title">{t(`放心淘 无忧购 一站寄全球`)}</p>
      </div>
      <div className="openbtnbox">
        <Button
          className="openbtn btn"
          onClick={open}
          type="primary"
          shape="round"
        >
          {t("打开Bccbuy")}
        </Button>
      </div>
      <div className="downloadbtnbox">
        <Button
          className="downloadbtn btn"
          onClick={download}
          shape="round"
        >
          {t("下载Bccbuy")}
        </Button>
      </div>
      <p className="continue" onClick={() => props.navigate("/home")}>
        {t("继续浏览")}
      </p>
    </div>
  );
};

export default forwardRef(Index);
