import { App, Button, Empty, Form, Radio } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import Price from "../../Common/Price";
import { useTranslation } from "react-i18next";

const ServiceSelect = ({ value = 0, onChange }) => {

    const triggerChange = (value) => {
        onChange?.(value);
    };
    const [lines, setLines] = useState([])
    const getServices = () => {
        global.Ajax.GET("api/Hub/GetService").then(res => {
            if (res.isSuccess) {
                setLines(res.result)
            }
        })
    }
    useEffect(() => {
        getServices();
    }, [])
    return (
        <React.Fragment>
            <div className="linesbox flwp">
                {lines.map((item, index) => (
                    <div onClick={() => {
                        triggerChange(item.id)
                        // setRow({
                        //     ...row,
                        //     ServiceId: item.id,
                        // })
                    }} className={`item font12 ${value == item.id ? 'active' : ''}`} style={{ width: 95 }} key={String(index)}>
                        <img alt='' src={require('../../imgs/kd.png')} className="icon" />
                        <p className="color3 center">{item.name}</p>
                        <span className="block color9 center">{item.remarks}</span>
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
}
const QdSelect = ({ value = 0, onChange, serviceId, id,message }) => {
    const [datas, setDatas] = useState([])
    const triggerChange = (value) => {
        onChange?.(value);
    };
    useEffect(() => {
        if (value != 0) {
            triggerChange(null)
        }
        getQdList();
        //eslint-disable-next-line
    }, [serviceId])
    const getQdList = () => {
        global.Ajax.GET(`api/ParcelOrder/GetParcelRoutes/${id}/${serviceId}`).then(res => {
            if (res.isSuccess) {
                setDatas(res.result)
                // setServices(res.result.items);
            }else{
                setDatas([])
                message.error(res.message)
            }
            // setListLoading(false);
        })
    }
    return (
        <div className={"qdList"}>
            {datas.length == 0 &&
                <Empty />
            }
            {datas.length > 0 && datas.map((item, k) => (
                <div key={k + ""} onClick={() => {
                    triggerChange(item.id)
                }
                } className={`qdListItem `}>
                    {/*<span className={`qdRadio ${value == item.id ? 'qdActive' : ''}`}></span>*/}
                    <Radio checked={value == item.id} />
                    <div className={"qdInfo"}>
                        <p className={"qdTitle"}>{item.name}</p>
                        <Price value={item.rate.value} className='font18 weight600 color3' />
                        <p className={"qdDesc"} dangerouslySetInnerHTML={{ __html: item.remarks }}></p>
                    </div>
                </div>
            ))}
        </div>
    );
}
const Index = (props, _ref) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const [loading] = useState(false);


    const onFinish = (data) => {
        global.Ajax.hhtc_request(`api/ParcelOrder/UpdateParcelForId/${props.data.id}/${data.rId}`, {}, "POST").then(res => {
            if (res.isSuccess) {
                message.success(res.message)
                props.onOk();
            } else {
                message.error(res.message)
            }
            // setListLoading(false);
        })
    }
    return (
        <Form
            form={form}
            autoComplete="off"
            labelCol={{ flex: '82px' }}
            onFinish={onFinish}
        >
            <Form.Item label={t('服务类型')} name='serviceId' rules={[{ required: true, message: t("请选择服务类型") }]}>
                <ServiceSelect />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prev, cur) => prev.serviceId != cur.serviceId}>
                {({ getFieldValue }) => (
                    <React.Fragment>
                        {getFieldValue('serviceId') > 0 &&
                            <Form.Item label={t('选择渠道')} name='rId' rules={[{ required: true, message: t('请选择配送渠道') }]}>
                                <QdSelect id={props.data.id} serviceId={getFieldValue('serviceId')} message={message}/>
                            </Form.Item>
                        }
                    </React.Fragment>
                )}
            </Form.Item>



            <Button loading={loading} type="primary" htmlType="submit" className="block marglauto">{t("确定")}</Button>
        </Form>
    )
}

export default forwardRef(Index);