import { Button, DatePicker, Dropdown, Select, Table, theme } from "antd";
import React, { forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Index = (props, _ref) => {
    const {t}=useTranslation();
    const { token: {
        colorPrimaryBg
    } } = theme.useToken();
    const items = [
        { label: 'CVS', key: '1' },
    ]
    const columns = [{
        title: t('日期'),
        dataIndex: 'time'
    }, {
        title: t('金额'),
        dataIndex: 'time'
    }, {
        title: t('业务类型'),
        dataIndex: 'time'
    }, {
        title: t('交易编号'),
        dataIndex: 'time'
    }, {
        title: t('交易方式'),
        dataIndex: 'time'
    }, {
        title: t('备注'),
        dataIndex: 'time'
    }];
    useEffect(()=>{
        document.title=t("我的账单");
        //eslint-disable-next-line
    },[])
    return (
        <React.Fragment>
            <div className="flexCenter">
                <h1>{t("我的账单")}</h1>
                <div className="rowFlex" style={{ textAlign: 'right' }}>
                    <Button className="colorlv borderlv" style={{ background: colorPrimaryBg }}>{t("我的账单")}</Button>
                    <Button onClick={() => props.navigate("/user/report")} className="marginl10 bgf5 color6" style={{ border: 0 }}>{t("消费分析")}</Button>
                </div>
            </div>
            <div className="flwp margint10">
                <DatePicker.RangePicker className="marginr10 margint10" />
                <Select
                    placeholder={t('类型')}
                    options={[
                        { label: '订单退款', value: 1 },
                        { label: '提现扣款', value: 2 },
                    ]}
                    style={{ width: 180 }}
                    className="marginr10 margint10"
                />
                <Dropdown
                    menu={{
                        items,
                    }}
                >
                    <Button type="primary" className="marginr10 margint10">{t("下载账单")}</Button>
                </Dropdown>
            </div>
            <Table
                dataSource={[]}
                columns={columns}
                pagination={false}
            />
        </React.Fragment>
    )
}

export default forwardRef(Index);