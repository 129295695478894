import { Tabs } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import ScrollToTop from "../ScrollToTop";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
const Index = (props, _ref) => {
  const { t } = useTranslation();
  const { key } = useParams();
  const tabs = [
    { label: t("余额"), key: "moneyBag" },
    { label: t("优惠券"), key: "coupon" },
  ];
  const [activeKey, setActiveKey] = useState(key || "moneyBag");
  useEffect(() => {
    document.title = t("我的资产");
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    let pathname = props.location.pathname;
    let arr = pathname.split("/");
    let path = arr.at(-1);
    if(pathname.includes("/user/balance/coupon")){
        setActiveKey("coupon");
    }else{
        setActiveKey(path);
    }
    //eslint-disable-next-line
  }, [props.location.pathname]);
  return (
    <React.Fragment>
      <Tabs
        className="pl_tabs"
        items={tabs}
        activeKey={activeKey}
        onTabClick={(key) => {
          setActiveKey(key);
          props.navigate(`/user/balance/${key}`);
        }}
      />
      {/* {path != "" && list(path, props)} */}
      <ScrollToTop>
        <div className="content rowFlex">
          <Outlet />
        </div>
      </ScrollToTop>
    </React.Fragment>
  );
};

export default forwardRef(Index);
