import { message } from 'antd';
import fetch from "isomorphic-fetch";

export default class Ajax {
    hhtc_request(url, data, type = 'POST') {
        var requrl = global.httpUrl + url;
        var init = {
            method: type,
            headers: new Headers({
                'Content-Type': 'application/json;',
                // "Accept": "application/json",
                'Authorization': localStorage.getItem('mycnToken') || "",
                "Accept-Language": localStorage.getItem('lang'),
            }),
            body: JSON.stringify(data),
        }
        return fetch(requrl, init).then(
            (response) => {
                if (response.status == 401) {
                    message.error('登录失效', 1, () => {
                        // window.location.href = '';
                        if (localStorage.getItem('mycnToken')) {
                            localStorage.removeItem('mycnToken');
                        }
                        window.location.href = '/login';
                    });
                }
                return response.json()
            }
        ).then(
            (data) => {
                // if (data.code === 999) {
                //     if (localStorage.getItem('mycnToken')) {
                //         localStorage.removeItem('mycnToken')
                //     }
                //     message.error(data.msg, 1, () => {
                //         window.location.href = ''
                //     })
                //     return data;
                // }
                return data;
            }
        ).catch(
            (err) => {
                // console.log("执行一次网络请求:请求地址=》", requrl, "返回结果=>网络异常", err)
                var data = {
                    code: 0,
                    msg: '网络异常!'
                }
                return data;
            }
        )
    }
    GET(url, data) {
        var requrl = global.httpUrl + url;
        let init = {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json; charset=utf-8',
                // "Accept": "application/json",
                'Authorization': localStorage.getItem('mycnToken') || "",
                "Accept-Language": localStorage.getItem('lang'),
                "cache-control": "no-cache"
            }),
        }
        return fetch(requrl, init).then(
            (response) => {
                if (response.status == 401) {
                    message.error('登录失效', 1, () => {
                        // window.location.href = '';
                        if (localStorage.getItem('mycnToken')) {
                            // window.location.href = '';
                            localStorage.removeItem('mycnToken')
                        }
                        window.location.href = '/login';
                    });
                }
                return response.json()
            }
        ).then(
            (data) => {
                // if (data.code === 999) {
                //     if (localStorage.getItem('mycnToken')) {
                //         localStorage.removeItem('mycnToken')

                //     }
                //     message.error(data.msg, 1, () => {
                //         window.location.href = ''
                //     })
                //     return data;
                // }
                return data;
            }
        ).catch(
            (err) => {
                // console.log("执行一次网络请求:请求地址=》", requrl, "返回结果=>网络异常", err)
                var data = {
                    code: 0,
                    msg: '网络异常!'
                }
                return data;
            }
        )

    }
    POST(url, data) {
        var requrl = global.httpUrl + url;
        var init = {
            method: "POST",
            // headers: new Headers({
            //     "content-type": "multipart/form-data",
            //     'Authorization': localStorage.getItem('mycnToken') || "",
            // }),
            body: data,
        }
        return fetch(requrl, init).then(
            (response) => {
                if (response.status == 401) {
                    message.error('登录失效', 1, () => {
                        // window.location.href = '';
                        if (localStorage.getItem('mycnToken')) {
                            localStorage.removeItem('mycnToken');
                        }
                        window.location.href = '/login';
                    });
                }
                return response.json()
            }
        ).then(
            (data) => {
                return data;
            }
        ).catch(
            (err) => {
                // console.log("执行一次网络请求:请求地址=》", requrl, "返回结果=>网络异常", err)
                var data = {
                    code: 0,
                    msg: '网络异常!'
                }
                return data;
            }
        )
    }
}