import { Button, Modal, Popconfirm, Table, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import EditAddress from '../../Common/EditAddress';
import { useTranslation } from "react-i18next";
import copy from 'copy-to-clipboard';

const Index = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [list, setList] = useState([]);
    const [type, setType] = useState('');
    const [row, setRow] = useState({});
    const columns = [{
        title: t('收件人'),
        dataIndex: 'name',
        width: 200,
        render: (_, item) => (
            <p className="font12 weight600">{item.name} {item.sname}</p>
        )
    }, {
        title: t('详细地址'),
        dataIndex: 'name',
        render: (_, item) => (
            <div className="font12">
                <p>{item.postCode} {item.phone}</p>
                <p>{item.address1}, {item.city}, {item.province}, {item.nation}</p>
                {/* <Tag color="green">{t("工作")}</Tag> */}
                <Tag color={item.tag == 1 ? "blue" : "green"}>{item.tag == 1 ? t('家') : t("工作")}</Tag>
            </div>
        )
    },
     {
        title: t('操作'),
        dataIndex: 'id',
        align: 'center',
        width: 300,
        render: (_, item) => (
            <div className="flexAllCenter pubbtn font12 weight600">
                <p onClick={() => {
                    setType('edit');
                    setRow(item);
                    setOpen(true);
                }}>{t("编辑")}</p>
                <Popconfirm
                    title={t('温馨提示')}
                    description={t('是否确认删除？')}
                    onConfirm={() => {
                        global.Ajax.hhtc_request('api/Address/Del', [item.id], 'DELETE').then(res => {
                            if (res.isSuccess) {
                                message.success(res.message);
                                getList()
                            } else {
                                message.error(res.message);
                            }
                        })
                    }}
                >
                    <p>{t("删除")}</p>
                </Popconfirm>
                <p onClick={()=>copyUrl(item)}>{t("复制")}</p>
                {!item.default && <p onClick={() => {
                    global.Ajax.hhtc_request(`api/Address/SetDefault/${item.id}`, { id: item.id }, 'PUT').then(res => {
                        if (res.isSuccess) {
                            message.success(res.message);
                            getList()
                        } else {
                            message.error(res.message);
                        }
                    })
                }}>{t("设为默认")}</p>}
                {item.default&&<Tag color="red">{t("默认地址")}</Tag>}
            </div>
        )
    }]
    useEffect(() => {
        document.title = t('收货地址');
        getList()
        //eslint-disable-next-line
    }, []);
    const onCancel = () => {
        setOpen(false);
        setType('');
        setRow({});
    }
    // 获取列表
    const getList = () => {
        global.Ajax.GET("api/Address/List").then(res => {
            if (res.isSuccess) {
                setList(res.result);
            }
        })
    }
    // 复制
    const copyUrl=(data)=>{
        copy(`${data.name} ${data.sname} ${data.nation} ${data.province} ${data.city} ${data.address1} ${data.postCode} ${data.phone}`);
        message.success(`Copied ${data.name} ${data.sname} ${data.nation} ${data.province} ${data.city} ${data.address1} ${data.postCode} ${data.phone}`)
    }
    return (
        <React.Fragment>
            <div className="flexCenter">
                <Button onClick={() => setOpen(true)} type="primary" size="small" className="font12 weight600">+ {t("新增收货地址")}</Button>
                <p className="font12 color9 marginl10">{t("您已创建个收货地址", {
                    num: list.length
                })}</p>
            </div>
            <Table
                dataSource={list}
                columns={columns}
                rowKey={'id'}
                className="dztable"
            />
            {/* 新增、编辑 */}
            <Modal
                title={t(`${type == 'edit' ? '修改' : '新增'}地址`)}
                open={open}
                width={800}
                centered
                destroyOnClose
                onCancel={onCancel}
                footer={null}
                className="huihead addrbox"
            >
                <EditAddress pageType={type} data={row} onOk={() => {
                    onCancel();
                    getList();
                }} />
            </Modal>
        </React.Fragment>
    )
}

export default Index;