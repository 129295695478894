import { Checkbox } from "antd";
import React from "react";

const Index = () => {
    const list = [
        {
            title: '账户', child: [
                { label: '资金授权开启', value: 1 },
                { label: '获得优惠券', value: 2 },
                { label: '优惠券到期', value: 3 },
                { label: '优惠券使用', value: 4 },
                { label: '开箱奖励申请已通过', value: 5 },
                { label: '开箱奖励申请已拒绝', value: 6 },
            ]
        },
        {
            title: '财务', child: [
                { label: '充值到账', value: 7 },
                { label: '提现成功', value: 8 },
                { label: '授权资金被扣款', value: 9 },
            ]
        },
        {
            title: '包裹', child: [
                { label: '包裹运费退款通知', value: 10 },
                { label: '预演完成', value: 11 },
                { label: '提交成功', value: 12 },
                { label: '包裹风控审核', value: 13 },
                { label: '包裹取消通知', value: 14 },
                { label: '包裹发货通知', value: 15 },
                { label: '运费补款通知', value: 16 },
                { label: '运费支付成功', value: 17 },
                { label: '包裹运费补款成功', value: 18 },
                { label: '包裹预演已取消', value: 19 },
                { label: '保险理赔到账', value: 20 },
                { label: '保险理赔拒绝', value: 21 },
                { label: '保险理赔申请接收通知', value: 22 },
            ]
        },
        {
            title: '订单', child: [
                { label: '订单进入风控', value: 23 },
                { label: '邮费待补款', value: 24 },
                { label: '订单待补款', value: 25 },
                { label: '信息待确认', value: 26 },
                { label: '订单已入库', value: 27 },
                { label: '分开发货', value: 28 },
                { label: '退货申请已受理', value: 29 },
                { label: '退货申请已取消', value: 30 },
                { label: '换货申请已受理', value: 31 },
                { label: '换货申请已取消', value: 32 },
                { label: '订单退货成功', value: 33 },
                { label: '您有商品即将超期', value: 34 },
                { label: '您的商品已超期', value: 35 },
                { label: '精细拍照完成', value: 36 },
                { label: '订单补款成功', value: 37 },
                { label: '运费补款成功', value: 38 },
                { label: '订单运费退款成功', value: 39 },
                { label: '订单支付成功', value: 40 },
                { label: '取消订单', value: 41 },
                { label: '撤销订单', value: 42 },
                { label: '质检提醒', value: 43 },
                { label: '采购提醒', value: 44 },
                { label: '您有商品即将销毁', value: 45 },
                { label: '商品已经被销毁', value: 46 },
            ]
        }
    ]
    return (
        <React.Fragment>
            {list.map((item, index) => (
                <div className="flex pushlist" key={String(index)}>
                    <h4>{item.title}</h4>
                    <Checkbox.Group
                        options={item.child}
                        className="row10"
                    />
                </div>
            ))}
        </React.Fragment>
    )
}

export default Index;