import  { useEffect } from 'react';
import { useLocation, } from "react-router-dom";

let prev_pathname;
export default function ScrollToTop(props) {
    const { pathname } = useLocation();
    useEffect(() => {
        // 组建挂载-----请求数据
        if (pathname !== prev_pathname) {
            window.scrollTo(0, 0)
            prev_pathname=pathname;
        }
    });
    return (
        props.children
    )
}
