import {
  Button,
  Divider,
  Statistic,
  Radio,
  Input,
  Space,
  Modal,
  Progress,
  message,
} from "antd";
import Helper from "../../class/Helper";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import LoginRecordList from "./LoginRecordList"; // 访问记录
import { useTranslation } from "react-i18next";

const Index = (props, _ref) => {
  const { t } = useTranslation();
  const [cancelShow, setCancelShow] = useState(false);
  const [Radiovalue, setRadiovalue] = useState("");
  const { Countdown } = Statistic;
  const [logoffstep, setlogoffstep] = useState(1);
  const [obj, setObj] = useState({
    disabled: true,
  });
  const [codeObj, setCodeObj] = useState({
    loading: false,
    codeTxt: t("获取验证码"),
    deadline: Date.now(),
    disabled: false,
  });
  const [remark, setremark] = useState("");
  const [recordOpen, setRecordOpen] = useState(false);
  const [info, setInfo] = useState({});
  useEffect(() => {
    document.title = t("账户安全");
    global.Ajax.GET("api/Member/MemberInfo").then((res) => {
      if (res.isSuccess) {
        setInfo(res.result);
      }
    });
    //eslint-disable-next-line
  }, []);
  const onCancel = () => {
    setremark("");
    setRadiovalue("");
    setlogoffstep(1);
    setRecordOpen(false);
    setCancelShow(false);
    setCodeObj({
      loading: false,
      codeTxt: t("获取验证码"),
      deadline: Date.now(),
      disabled: false,
    });
  };
  const onChange = (e) => {
    setremark("");
    setRadiovalue(e.target.value);
  };
  const optionlist = [
    t("安全/隐私问题"),
    t("糟糕的客户服务"),
    t("费用太高了"),
    t("复杂的购物过程"),
    t("其他原因"),
  ];
  const disabled = useMemo(() => {
    if (logoffstep === 1) {
      const isOtherReason = Radiovalue == t("其他原因");
      const isRadiovalueValid = !!Radiovalue; // 检查是否有选中值
      return !isRadiovalueValid || (isOtherReason && !remark);
    } else {
      return !obj.captchaCode; // 如果没有验证码，返回 true
    }
    //eslint-disable-next-line
  }, [logoffstep, Radiovalue, remark, obj.captchaCode]);
  const sendEmail = () => {
    if (codeObj.loading) {
      return;
    }
    setCodeObj({ ...codeObj, disabled: true });
    const guid = Helper.getGUID();
    global.Ajax.GET(`api/Member/DeactivationSendEmail/${guid}`).then((res) => {
      if (res.isSuccess) {
        message.success(res.message);
        setCodeObj({
          ...codeObj,
          loading: true,
          deadline: Date.now() + 60000,
        });
        setObj({
          ...obj,
          disabled: false,
          captchaId: guid,
        });
      } else {
        setCodeObj({ ...codeObj, loading: false, disabled: false });
        message.error(res.message);
      }
    });
  };
  const onOk = () => {
    if (logoffstep == 1) {
      global.Ajax.hhtc_request("api/Member/ApplyDeactivation", {
        remark: remark || Radiovalue,
      }).then((res) => {
        if (res.isSuccess) {
          setlogoffstep(2);
          setRadiovalue("");
          setremark("");
        } else {
          message.error(res.message);
        }
      });
    } else {
      global.Ajax.hhtc_request(
        `api/Member/${obj.captchaId}/Deactivation/${obj.captchaCode}`,
        {}
      ).then((res) => {
        if (res.isSuccess) {
          message.success(res.message, 1.5, () => {
            onCancel();
            localStorage.removeItem("mycnToken");
            props.navigate("/home");
          });
        } else {
          message.error(res.message);
        }
      });
    }
  };
  return (
    <React.Fragment>
      <div className="userhome paddb12">
        <div
          className="info flex"
          style={{ position: "relative", alignItems: "flex-start" }}
        >
          <div className="lbox margint10">
            <div className="avatarbox">
              <img
                alt=""
                src={
                  info.headPortrait && info.headPortrait != ""
                    ? info.headPortrait
                    : require("../../imgs/avatar_hui.png")
                }
                className="avatar"
              />
              <span>V{info.levelId || 0}</span>
            </div>
            <p className="margint4">ID：{info.memberId || ""}</p>
          </div>
          <div className="rowFlex secInfo font12">
            <div
              className="flexCenter"
              style={{ justifyContent: "space-between", paddingRight: "20px" }}
            >
              <div className="flexCenter">
                <span className="lw">{t("用户名")}：</span>
                <p className="rowFlex weight600">{info.name || ""}</p>
              </div>
              <div
                onClick={() => {
                  setCancelShow(true);
                }}
              >
                <span
                  className="lw"
                  style={{ color: "#999", cursor: "pointer" }}
                >
                  {t("注销账号")}
                </span>
              </div>
            </div>
            <div className="flexCenter margint10">
              <span className="lw">{t("邮箱")}：</span>
              <p className="yxdz weight600">{info.email || ""}</p>
              {/* <span onClick={() => setOpen(true)} className="marginl10 cursor">
                                <i className="iconfont icon-bianji marginr4 font12"></i>
                                {t("修改邮箱")}
                            </span> */}
            </div>
            <p className="color9 htips">
              ({t("建议仅在邮箱设置错误或出现问题时修改")})
            </p>
            <div className="flexCenter margint10">
              <span className="lw">{t("安全级别")}：</span>
              <Progress
                percent={8}
                showInfo={false}
                trailColor="#fff"
                strokeColor={"#f00"}
                style={{ width: 210, height: 16 }}
              />
              <span className="marginl10">{t("低")}</span>
            </div>
            <p className="color9 htips">
              {t("您的安全级别为 “低” 为了保障您的账户安全 请设置支付密码，")}
            </p>
            <div className="flexCenter margint10 zjboxxx">
              <span className="lw">{t("最近登录")}：</span>
              <span>101.206.167.38</span>
              <span className="color9 marginl4" style={{ background: "#333" }}>
                APP
              </span>
              <span className="marginl10">2024-01-18 17:39:15</span>
              <span
                onClick={() => setRecordOpen(true)}
                className="marginl10 coloryellow cursor"
              >
                {t("更多记录")}
              </span>
            </div>
            <div className="flexCenter margint10">
              <span className="lw">{t("授权绑定")}</span>
              <Button shape="round" className="flexCenter font12 border0">
                <img
                  alt=""
                  src={require("../../imgs/google.png")}
                  className="width16 marginr4"
                />
                {t("绑定")}
              </Button>
              <Button
                shape="round"
                className="marginl10 flexCenter font12 border0"
              >
                <img
                  alt=""
                  src={require("../../imgs/apple.png")}
                  className="marginr4"
                  style={{ width: 14, display: "block", marginBottom: 1 }}
                />
                {t("绑定")}
              </Button>
            </div>
          </div>
          {/* <span className="font12 color9 cursor" style={{ marginRight: 40 }}>{t("注销账号")}</span> */}
        </div>
      </div>
      <div className="flexCenter margint20">
        <CheckCircleOutlined
          color="#11ba66"
          style={{ fontSize: 36, color: "#11ba66" }}
        />
        <div className="rowFlex marginl20">
          <h4>{t("修改登录密码")}</h4>
          <p className="font12 color9 margint10">
            {t(
              "安全性高的密码可以使账号更安全。建议您定期更换密码，且设置一个包含数字和字母，并长度超过6位以上的密码。"
            )}
          </p>
        </div>
        <Button
          onClick={() => props.navigate("/user/editpwd")}
          type="primary"
          size="small"
          className="font12 h28 marginl20"
        >
          {t("修改密码")}
        </Button>
      </div>
      <Divider />
      <div className="flexCenter margint20">
        <ExclamationCircleOutlined style={{ fontSize: 36, color: "#f70" }} />
        <div className="rowFlex marginl20">
          <h4>{t("支付密码")}</h4>
          <p className="font12 color9 margint10">
            {t(
              "使用 Bccbuy 余额支付时需要输入的密码，设置支付密码以保障支付安全"
            )}
          </p>
        </div>
        <Button
          onClick={() => props.navigate("/user/setpaypwd")}
          type="primary"
          size="small"
          className="font12 h28 marginl20"
        >
          {t("设置密码")}
        </Button>
      </div>
      <Divider />
      <div className="flexCenter margint20">
        <ExclamationCircleOutlined style={{ fontSize: 36, color: "#f70" }} />
        <div className="rowFlex marginl20">
          <h4>{t("Authenticator验证")}</h4>
          <p className="font12 color9 margint10">
            {t("开启了虚拟Authenticator验证，让账号更安全。请备份您的密钥。")}
          </p>
        </div>
        <div className="center marginl20">
          <Button
            type="primary"
            size="small"
            className="font12"
            style={{ width: 60 }}
          >
            {t("开启")}
          </Button>
          <p className="colorju cursor">{t("解除绑定")}</p>
        </div>
      </div>
      {/* 访问记录 */}
      <Modal
        title={t("访问记录")}
        width={800}
        open={recordOpen}
        centered
        destroyOnClose
        footer={null}
        onCancel={onCancel}
        className="huihead"
      >
        <LoginRecordList />
      </Modal>
      <Modal
        open={cancelShow}
        width={560}
        title={`${t("注销账号")}？`}
        centered={true}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={onCancel}
        okButtonProps={{ disabled: disabled }}
        onOk={onOk}
      >
        {logoffstep == 1 && (
          <div>
            <Radio.Group onChange={onChange} value={Radiovalue}>
              <Space direction="vertical">
                {optionlist.map((item, index) => {
                  return (
                    <Radio key={index} value={item}>
                      {item}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
            {Radiovalue == t("其他原因") && (
              <Input.TextArea
                style={{ marginTop: 16 }}
                value={remark}
                onChange={(e) => {
                  setremark(e.target.value);
                }}
              />
            )}
          </div>
        )}
        {/* 第二步 */}
        {logoffstep == 2 && (
          <div>
            <p className="margint10">
              {t("为了您的账户安全，需要先通过“邮箱验证码”进行安全校验")}
            </p>
            <p className="margint10">
              {t("当前邮箱")}: {info.email}
            </p>
            <Input
              value={obj.captchaCode}
              placeholder={t("请输入")}
              style={{
                marginBottom: 16,
                marginTop: 16,
                height: 40,
                backgroundColor: "#f8f8f8",
              }}
              onInput={(e) => {
                setObj({
                  ...obj,
                  captchaCode: e.target.value,
                });
              }}
            />
            <Button
              onClick={() => {
                sendEmail();
              }}
              type="primary"
              className="font12"
              style={{
                minWidth: 92,
                backgroundColor: "#333",
                borderRadius: 2,
                color: "white",
              }}
              disabled={codeObj.disabled}
            >
              {codeObj.loading ? (
                <Countdown
                  value={codeObj.deadline}
                  format="s"
                  suffix="s"
                  valueStyle={{ fontSize: "12px", color: "white" }}
                  onFinish={() => {
                    setCodeObj({
                      ...codeObj,
                      loading: false,
                      disabled: false,
                      codeTxt: "重发",
                    });
                  }}
                />
              ) : (
                codeObj.codeTxt
              )}
            </Button>
          </div>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default forwardRef(Index);
