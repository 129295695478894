import { App, Breadcrumb, Button, Form, Input, Statistic } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Helper from "../../class/Helper";

const Index = (props, _ref) => {
    const { t } = useTranslation();
    const { Countdown } = Statistic;
    const [form] = Form.useForm();
    const { message, modal } = App.useApp();
    const [obj, setObj] = useState({
        disabled: true,
    });
    const [loading, setLoading] = useState(false);
    const [codeObj, setCodeObj] = useState({
        loading: false,
        codeTxt: t('获取验证码'),
        deadline: Date.now(),
        disabled: false
    })
    useEffect(() => {
        document.title = t('修改登录密码');
        //eslint-disable-next-line
    }, []);
    // 发送邮箱
    const sendEmail = () => {
        if (codeObj.loading) {
            return
        }
        setCodeObj({ ...codeObj, disabled: true })
        const guid = Helper.getGUID();
        global.Ajax.GET(`api/Member/UpdatePasswordSendEmail/${guid}`).then(res => {
            if (res.isSuccess) {
                message.success(res.message);
                setCodeObj({ ...codeObj, loading: true, deadline: Date.now() + 60000 })
                setObj({
                    ...obj,
                    disabled: false,
                    captchaId: guid,
                })
            } else {
                setCodeObj({ ...codeObj, loading: false, disabled: false })
                message.error(res.message);
            }
        })
    }
    const onFinish = (data) => {
        setLoading(true);
        delete data.checkPassword;
        data.captchaId = obj.captchaId;
        global.Ajax.hhtc_request('api/Member/UpdatePassword', data).then(res => {
            if (res.isSuccess) {
                message.success(res.message);
                modal.warning({
                    title: t('温馨提示'),
                    content: t('密码修改成功后，系统会自动退出'),
                    centered: true,
                    autoFocusButton: null,
                    okText: t('确定'),
                    onOk: () => {
                        localStorage.removeItem('mycnToken');
                        setTimeout(() => {
                            props.navigate('/login', { replace: true });
                        }, 1200);
                    }
                })
            } else {
                message.error(res.message);
            }
            setLoading(false);
        })
    }
    return (
        <React.Fragment>
            <Breadcrumb
                separator=">"
                items={[{
                    title: t('账户安全'),
                    className: 'cursor weight600',
                    onClick: () => {
                        props.navigate(-1);
                    }
                }, {
                    title: t('修改密码'),
                    className: 'weight600'
                }]}
            />
            <Form
                form={form}
                colon={false}
                autoComplete="off"
                style={{ width: 560, marginTop: 40 }}
                labelCol={{ flex: '190px' }}
                onFinish={onFinish}
                className="editformbox"
            >
                <Form.Item label={t('登录密码')} name='oldPassWord' rules={[{ required: true, message: t('请输入登录密码') }]}>
                    <Input.Password placeholder={t("请输入")} />
                </Form.Item>
                <Form.Item label={t('新的密码')} name='newPassWord' rules={[{ required: true, message: t('请输入新的密码') }]}>
                    <Input.Password placeholder={t("请输入")} />
                </Form.Item>
                <Form.Item label={t('重复密码')} name='checkPassword' rules={[{ required: true, message: t('请确认密码') }, ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('newPassWord') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error(t('两次密码不一致')));
                    },
                }),]}>
                    <Input.Password placeholder={t("请输入")} />
                </Form.Item>
                <Form.Item label={t('邮箱验证码')} name='captchaCode' rules={[{ required: true, message: t('请输入邮箱验证码') }]}>
                    <div className="flex">
                        <Input disabled={obj.disabled} placeholder={t("请输入")} className="rowFlex" />
                        <Button onClick={() => {
                            sendEmail()
                        }} type="primary" className="marginl20 font12" style={{ minWidth: 92 }} disabled={codeObj.disabled}>
                            {codeObj.loading ? <Countdown value={codeObj.deadline} format="s" suffix="s" valueStyle={{ fontSize: '12px',color:'white' }} onFinish={()=>{
                                setCodeObj({
                                    ...codeObj,
                                    loading: false,
                                    disabled: false,
                                    codeTxt:'重发'
                                })
                            }}/> : codeObj.codeTxt}
                        </Button>
                    </div>
                </Form.Item>
                <Button loading={loading} type="primary" htmlType="submit" block style={{ width: 'calc(100% - 190px)', marginLeft: 190 }}>{t("提交")}</Button>
            </Form>
        </React.Fragment>
    )
}

export default forwardRef(Index);