import {  Input,  Table } from "antd";
import React, { forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Index = (props, _ref) => {
    const { t } = useTranslation();
    const searchList = [
        { text: t('全部'), value: 1 },
        { text: t('未处理'), value: 2 },
        { text: t('打印出库单'), value: 3 },
        { text: t('已出库'), value: 4 },
        { text: t('重量已计算'), value: 5 },
        { text: t('已寄送'), value: 6 },
        { text: t('已失效'), value: 7 },
        { text: t('已取消'), value: 8 },
    ];
    const columns = [{
        title: t('预演包裹信息'),
        dataIndex: 'name',
        className: 'font12',
    }, {
        title: t('意向路线'),
        dataIndex: 'name',
        className: 'font12',
    }, {
        title: `${t("重量")}(g)`,
        dataIndex: 'name',
        className: 'font12',
    }, {
        title: `${t("尺寸")}(cm)`,
        dataIndex: 'name',
        className: 'font12',
    }, {
        title: t('增值'),
        dataIndex: 'name',
        className: 'font12',
    }, {
        title: ({ filters }) => {
            let val = filters.name ? filters.name[0] : null;
            let row;
            if (val) {
                row = searchList.find(item => item.value == val);
            }
            return `${row ? row.text : t('全部')}`;
        },
        dataIndex: 'name',
        className: 'font12',
        filters: searchList,
        filterMultiple: false,
        width: 150,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close, filters }) => {
            return (
                <div style={{ padding: 10 }}>
                    {filters.map((item, index) => (
                        <p className={`center cursor font12 ${selectedKeys[0] == item.value ? 'bgf5' : ''}`} style={{ padding: '4px 0' }} onClick={() => {
                            setSelectedKeys([item.value])
                            confirm()
                        }} key={String(index)}>{item.text}</p>
                    ))}
                </div>
            )
        }
    }, {
        title: t('操作'),
        dataIndex: 'name',
        className: 'font12',
    }];
    useEffect(() => {
        document.title = t('预演包裹');
        //eslint-disable-next-line
    }, [])
    return (
        <React.Fragment>
            <div className="top_search_order">
                <Input placeholder={`${t("输入PR/PO/PI查找")}`} style={{ width: 280 }} suffix={(<i className="iconfont icon-dingbusousuo"></i>)} />
            </div>
            <Table
                dataSource={[]}
                columns={columns}
                pagination={false}
            />
        </React.Fragment>
    )
}

export default forwardRef(Index);