export const langObj = {
    "zh-CN": 'title',
    "en": 'eTitle',
    "es-ES": 'title',
    "ru": 'rTitle'
}
export const desclangObj = {
    "zh-CN": 'desc',
    "en": 'eDesc',
    "es-ES": 'desc',
    "ru": 'rDesc'
}