import { Empty, Input, Tabs } from "antd";
import React, { forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Index = (props, _ref) => {
    const { t } = useTranslation();
    useEffect(() => {
        document.title = t('我的评论')
        //eslint-disable-next-line
    }, [])
    return (
        <React.Fragment>
            <Tabs
                className="pl_tabs"
                items={[
                    { key: '1', label: t('待评论') },
                    { key: '2', label: t('已评论') },
                ]}
                tabBarExtraContent={{
                    right: (
                        <Input placeholder={t("搜索商品PI")} style={{ width: 280 }} bordered={false} suffix={(<i className="iconfont icon-dingbusousuo"></i>)} />
                    )
                }}
            />
            <Empty />
        </React.Fragment>
    )
}

export default forwardRef(Index);