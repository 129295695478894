import { Table } from "antd";
import { DesktopOutlined } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";

const Index = () => {
    const {t}=useTranslation();
    const columns = [{
        title: `${t("IP地址")}&${t("设备类型")}`,
        dataIndex: 'ip',
        render: () => (
            <div className="flex" style={{alignItems: 'flex-start'}}>
                {/* <MobileOutlined className="marginr10" style={{fontSize: 40,color: '#999'}} /> */}
                <DesktopOutlined className="marginr10" style={{fontSize: 40,color: '#999'}} />
                <div className="rowFlex">
                    <p className="weight600">101.206.167.38</p>
                    <span className="bgf2 color9 font12">PC</span>
                    <span className="marginl4 color9 font12">MSEdge/121.0.0.0</span>
                    <p className="font12 color9">Windows 10 or Windows Server 2016</p>
                </div>
            </div>
        )
    }, {
        title: t('位置'),
        dataIndex: 'localtion',
        width: 200,
        render: ()=>(
            <p className="font12 color9">China,Shanghai,Shanghai</p>
        )
    }, {
        title: t('访问时间'),
        dataIndex: 'time',
        width: 200,
        render: ()=>(
            <p className="font12 color9">2024-01-12 17:41:39</p>
        )
    }]
    return (
        <Table
            dataSource={[1, 1, 1]}
            columns={columns}
            scroll={{x: 300}}
        />
    )
}

export default Index;