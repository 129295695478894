import { Tabs } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import ScrollToTop from "../ScrollToTop";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Index = (props, _ref) => {
    const {t}=useTranslation();
    const [tabs] = useState([
        { label: t('个人信息'), key: 'userinfo' },
        { label: t('收货地址'), key: 'address' },
        { label: t('账户安全'), key: 'secenter' },
        // { label: t('信用卡账单地址'), key: 'cardaddress' },
    ]);
    // const [path, setPath] = useState('UserInfo');
    const [activeKey, setActiveKey] = useState('userinfo')
    useEffect(() => {
        // document.title = '账户设置';
        let pathname=props.location.pathname;
        let arr=pathname.split('/');
        let path=arr.at(-1);
        setActiveKey(path);
        //eslint-disable-next-line
    }, []);
    return (
        <React.Fragment>
            <Tabs
                items={tabs}
                className="zxtabs"
                activeKey={activeKey}
                onTabClick={(key) => {
                    // setPath(key);
                    setActiveKey(key);
                    props.navigate(`/user/account/${key}`)
                }}
            />
            <ScrollToTop>
                <Outlet />
            </ScrollToTop>
            {/* {path != '' && list(path, props)} */}
        </React.Fragment>
    )
}

export default forwardRef(Index);