import { App, Button, Modal } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import EditAddress from '../../Common/EditAddress';
import { useTranslation } from "react-i18next";

const Index = (props, _ref) => {
    const {t}=useTranslation();
    const {  modal } = App.useApp();
    const [open,setOpen]=useState(false);
    useEffect(() => {
        document.title = t('信用卡地址');
        //eslint-disable-next-line
    }, []);
    const del = () => {
        modal.confirm({
            title: t('温馨提示'),
            content: t('是否确认删除？'),
            centered: true,
            onOk: () => { }
        })
    }
    const onCancel=()=>{
        setOpen(false);
    }
    return (
        <React.Fragment>
            <div className="cadrbox">
                {/* 有地址 */}
                {/* <React.Fragment>
                    <div className="flexCenter">
                        <h1>san li</h1>
                        <span className="marginl10 color6">19999999999</span>
                        <span className="marginlauto color6">000000</span>
                    </div>
                    <p>US CA 洛杉矶</p>
                    <p>34号</p>
                </React.Fragment> */}
                {/* 空地址 */}
                <div className="no-data">
                    <p className="font14 weight600">{t("当您使用信用卡支付时，需要设置信用卡账单地址。")}</p>
                    <Button onClick={()=>setOpen(true)} type="primary" shape="round" className="margint10 font12 weight600">{t("添加账单地址")}</Button>
                </div>
            </div>
            <div className="flex">
                <Button onClick={del} shape="round" className="font12 weight600">{t("删除账单地址")}</Button>
                <Button type="primary" shape="round" className="marginl10 font12 weight600">{t("修改账单地址")}</Button>
            </div>
            {/* 信用卡地址 */}
            <Modal
                title={t('信用卡地址')}
                open={open}
                width={800}
                centered
                destroyOnClose
                onCancel={onCancel}
                className="huihead addrbox"
                footer={null}
            >
                <EditAddress type='card' />
            </Modal>
        </React.Fragment>
    )
}

export default forwardRef(Index);