import { DatePicker, Table, theme } from "antd";
import React, { forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Index = (props, _ref) => {
    const { t } = useTranslation();
    const { token: {
        colorLink
    } } = theme.useToken();
    const columns = [{
        title: t('创建时间'),
        dataIndex: 'time',
        align: 'center',
    }, {
        title: t('成长值'),
        dataIndex: 'time',
        align: 'center',
    }, {
        title: t('当前等级'),
        dataIndex: 'time',
        align: 'center',
    }]
    useEffect(() => {
        document.title = t('积分记录');
        //eslint-disable-next-line
    }, []);
    return (
        <React.Fragment>
            <span onClick={() => props.navigate(-1)} className="font12 cursor" style={{ color: colorLink }}>{t("返回推广联盟")}</span>
            <div className="flexCenter margint10">
                <h1 className="marginrauto">{t("我的成长值")}</h1>
                <DatePicker.RangePicker />
            </div>
            <Table
                dataSource={[]}
                columns={columns}
                pagination={false}
                className="margint10"
            />
        </React.Fragment>
    )
}

export default forwardRef(Index);