import { Affix, App, Button, Checkbox, Empty, Form, Input, Modal, Pagination, Select, Table, Tooltip, theme } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import Helper from "../../class/Helper";
import { useTranslation } from "react-i18next";
import Loading from "../../Common/Loading";
import PlatformIcon from "../../Common/PlatformIcon";
import Price from "../../Common/Price";
import Jian from "../../Common/Jian";
import EditGoodsRemark from "../../Common/EditGoodsRemark";

const ColorPicker = ({ value = '', onChange }) => {
    const [colors, setColors] = useState([]);
    useEffect(() => {
        getColors()
    }, []);
    // 获取颜色
    const getColors = async () => {
        let data = await Helper.colorList();
        setColors(data)
    }
    const triggerChange = (url) => {
        onChange?.(url);
    };

    return (
        <div className="flex">
            {colors.map((item, index) => (
                <span onClick={() => triggerChange(item.value)} className={`colorkk marginr10 cursor ${value == item.value ? 'active' : ''}`} style={{ background: item.key }} key={String(index)}></span>
                // <span onClick={() => triggerChange(item)} className={`colorkk marginr10 cursor ${value == item ? 'active' : ''}`} style={{ background: item }} key={String(index)}></span>
            ))}
        </div>
    )
}

const Index = (props, _ref) => {
    const { t } = useTranslation();
    const { message, modal } = App.useApp();
    const { token: {
        colorPrimary
    } } = theme.useToken();
    const [list, setList] = useState([]);
    const [remarkOpen, setRemarkOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [bsOpen, setBsOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [obj, setObj] = useState({
        num: 0,
        sumPrice: '0.00',
        ids: [],
    })
    const [row, setRow] = useState({
        id: '',
        remark: ''
    })
    const [total, setTotal] = useState(0);
    const [info, setInfo] = useState({
        page: 1,
        size: 10,
    })
    const columns = [{
        title: t('商品名称'),
        dataIndex: 'name',
        ellipsis: true,
        render: () => (
            <Tooltip title={`哈哈红红火火恍恍惚惚`}>
                <p className="line1">REPRESENT SS21 OwnersCLUB休闲fog字母LOGO连帽卫衣男女款帽衫潮</p>
            </Tooltip>
        )
    }, {
        title: t('商品详情'),
        dataIndex: 'name',
        ellipsis: true,
        render: () => (
            <Tooltip title={`哈哈红红火火恍恍惚惚`}>
                <p className="line1">REPRESENT SS21 OwnersCLUB休闲fog字母LOGO连帽卫衣男女款帽衫潮</p>
            </Tooltip>
        )
    }, {
        title: t('单价'),
        dataIndex: 'num',
        width: 120,
        align: 'center'
    }, {
        title: t('数量'),
        dataIndex: 'num',
        width: 120,
        align: 'center'
    }, {
        title: t('操作'),
        dataIndex: 'name',
        width: 80,
        render: () => (
            <p className="colorlv cursor">{t("添加")}</p>
        )
    }]
    useEffect(() => {
        document.title = t('购物车');
        getColor();
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        getList();
        //eslint-disable-next-line
    }, [info])
    // 获取列表
    const getList = () => {
        setLoading(true);
        global.Ajax.GET(`api/ShopCart/List?pageIndex=${info.page}&pageSize=${info.size}`).then(res => {
            if (res.isSuccess) {
                setList(res.result.items);
                info.page == 1 && setTotal(res.result.pagination.availCnt);
            }
            setLoading(false);
        })
    }
    // 标识颜色
    const getColor = () => {
        let colors = Helper.colorList();
        let arr = [];
        for (let i in colors) {
            arr.push({
                label: colors[i],
                value: colors[i],
            })
        }
        setItems([
            ...arr,
            { label: colorPrimary, value: colorPrimary }
        ]);
    }
    // 判断一级是否全选
    function isFirstCheckAll(index) {
        let data = JSON.parse(JSON.stringify(list));
        let res = data[index].checked || true;
        let childs = data[index].products;
        if (childs.length > 0) {
            res = childs.every((item) => item.checked)
        } else {
            res = data[index].checked;
        }
        return res;
    }
    // 判断一级是否显示半选
    function isFirstIndeterminate(index) {
        let data = JSON.parse(JSON.stringify(list));
        let res = false;
        let childs = data[index].products;
        if (childs.length > 0) {
            res = childs.some((item) => item.checked);
            if (childs.every((item) => item.checked)) {
                res = false;
            }
        }
        return res;
    }
    // 一级选项切换
    const firstChange = (index) => {
        let data = JSON.parse(JSON.stringify(list));
        if (data[index].checked) {  // 一级已全选
            // 改变所有子级为不选
            let secChild = data[index].products;
            for (let i in secChild) {
                // 改变二级
                secChild[i].checked = false;
            }
            // 改变自己
            data[index].checked = false;
        } else {  // 一级未全选
            // 改变所有子级为选中
            let secChild = data[index].products;
            for (let i in secChild) {
                // 改变二级
                secChild[i].checked = true;
            }
            // 改变自己
            data[index].checked = true;
        }
        setList(data)
        // 计算选中商品信息
        calcGoods(data);
    }
    // 二级选项切换
    const secChange = (index1, index2) => {
        let data = JSON.parse(JSON.stringify(list));
        data[index1].products[index2].checked = !data[index1].products[index2].checked;
        let secChild = data[index1].products;
        // 如果二级全部选中改变一级的checked
        data[index1].checked = secChild.every((item) => item.checked)
        setList(data)
        // 计算选中商品信息
        calcGoods(data);
    }
    // 判断是否全部选中
    const isAllChecked = () => {
        let data = JSON.parse(JSON.stringify(list));
        // 判断一级是否全部选中
        let res = data.every(item => item.checked);
        return res;
    }
    // 判断全选是否部分选中
    const isAllIndeterminate = () => {
        let data = JSON.parse(JSON.stringify(list));
        let res = false;
        for (let index in data) {
            let childs = data[index].products;
            if (childs.length > 0) {
                res = childs.some((item) => item.checked);
                if (res) {
                    break;
                }
            }
        }
        res = isAllChecked() ? false : res;
        return res;
    }
    // 全选
    const checkAll = () => {
        let data = JSON.parse(JSON.stringify(list));
        for (let i in data) {
            data[i].checked = !isAllChecked();
            let secChild = data[i].products;
            for (let k in secChild) {
                // 改变二级
                secChild[k].checked = !isAllChecked();
            }
        }
        setList(data);
        // 计算选中商品信息
        calcGoods(data);
    }
    // 删除商品
    const del = (ids) => {
        modal.confirm({
            title: t('提示'),
            content: t('确定要删除商品？'),
            centered: true,
            maskClosable: true,
            autoFocusButton: false,
            onOk: () => {
                global.Ajax.hhtc_request('api/ShopCart/Del', ids, "DELETE").then(res => {
                    if (res.isSuccess) {
                        message.success(res.message);
                        getList();
                        // 刷新悬浮购物车列表
                        props.onRefresh && props.onRefresh();
                    } else {
                        message.error(res.message);
                    }
                })
            },
        })
    }
    const onCancel = () => {
        setRemarkOpen(false);
        setOpen(false);
        setBsOpen(false);
        setRow({
            id: '',
            remark: undefined,
        })
    }
    // 计算选中商品信息
    const calcGoods = (list) => {
        let num = 0,
            sumPrice = 0,
            ids = [];
        for (let i in list) {
            let products = list[i].products;
            for (let j in products) {
                if (products[j].checked) {
                    num += 1;
                    // let sum = (Helper.getSumPrice(products[j].price.value, products[j].num)) * 1000;
                    let amount = products[j].amount.value * 1000;
                    sumPrice += amount;
                    ids.push(products[j].id)
                }
            }
        }
        sumPrice = sumPrice / 1000;
        setObj({
            num,
            sumPrice,
            ids,
        })
    }
    // 结算
    const checkOut = () => {
        let key = Helper.getRandomString(20);
        sessionStorage.setItem(`${key}`, JSON.stringify(obj.ids));
        props.navigate(`/checkorder?sp=${key}`);
    }
    // 修改商品数量
    const changeCartNum = (id, num) => {
        global.Ajax.hhtc_request('api/ShopCart/EditNum', {
            id, num
        }, 'PUT').then(res => {
            if (res.isSuccess) {
                getList()
                // 刷新悬浮购物车列表
                props.onRefresh && props.onRefresh();
            } else {
                message.error(res.result);
            }
        })
    }
    return (
        <React.Fragment>
            <div className="cart_search flexCenter paddb12">
                <Select
                    popupClassName="colorboxs"
                    className="colorselt marginr10"
                    placeholder={t('标识颜色')}
                    popupMatchSelectWidth={false}
                    options={items}
                    style={{ width: 144 }}
                    mode="multiple"
                    optionRender={(option) => (
                        <span className="colorkk" style={{ background: option.data.value }}></span>
                    )}
                    tagRender={(props) => (
                        <span className="colorkk" style={{ background: props.value, marginLeft: -10 }}></span>
                    )}
                />
                <Select placeholder={t('商品标签')} options={[
                    { label: '质检提醒', value: 1 },
                    { label: '订单提醒', value: 2 },
                ]} mode='multiple' style={{ width: 240 }} />
                <Button type="primary" className="marginl10">{t("搜索")}</Button>
            </div>
            <div className="cart_table">
                {/* 表头 */}
                <div className="head flex center weight600 borderb1f2">
                    <div className="infos">{t("商品信息")}</div>
                    <div className="remarkbox">{t("备注")}</div>
                    <div className="pribox">{t("单价")}</div>
                    <div className="goods_num">{t("数量")}</div>
                    <div className="totalbox">{t("金额")}</div>
                    <div className="czl">{t("操作")}</div>
                </div>
                {/* 加载页 */}
                {loading && <Loading style={{ paddingTop: 100 }} />}
                {/* 空数据 */}
                {!loading && list.length == 0 && <Empty style={{ marginTop: 100 }} />}
                {/* 表内容 */}
                {!loading && list.map((item, index) => (
                    <div className="card_item" key={String(index)}>
                        <div className="flexCenter tbox">
                            <Checkbox
                                indeterminate={isFirstIndeterminate(index)}
                                checked={isFirstCheckAll(index)}
                                onChange={() => {
                                    firstChange(index)
                                }}
                            >
                            </Checkbox>
                            <PlatformIcon platform={item.platForm} />
                            <span>{item.shopName}</span>
                        </div>
                        {/* 商品列表 */}
                        <div className="box">
                            {item.products && item.products.map((row, k) => (
                                <div className="child" key={String(k)}>
                                    <div className="flex">
                                        <div className="flexCenter infos">
                                            <Checkbox
                                                className="marginr10 marginl10"
                                                checked={row.checked}
                                                onChange={() => secChange(index, k)}
                                            ></Checkbox>
                                            <img alt='' src={row.picUrl} className="pic" />
                                            <div className="wzk rowFlex">
                                                <p onClick={() => {
                                                    window.open(`/product?platform=${row.platForm}&num_iid=${row.productId}`, '_blank', "noreferrer")
                                                }} className="line2 title cursor">{row.title}</p>
                                                <p className="color_type">{row.propertiesName}</p>
                                            </div>
                                        </div>
                                        <div className="remarkbox">
                                            {row.remark && row.remark != '' && <p className="font12 color9">{row.remark}</p>}
                                            <i onClick={() => {
                                                // setId(row.id);
                                                setRow({
                                                    id: row.id,
                                                    remark: row.remark,
                                                })
                                                setRemarkOpen(true);
                                            }} className="iconfont icon-bianji cursor"></i>
                                        </div>
                                        <div className="pribox">
                                            <Price value={row.price.value} />
                                            {/* <span className="pri">¥ 108.00</span> */}
                                        </div>
                                        <div className="flexCenter goods_num bgf5">
                                            <Jian value={row.num} onChange={(num) => changeCartNum(row.id, num)} />
                                            {/* <img alt='' src={require('../../imgs/jian.png')} className="width16 cursor" /> */}
                                            <Input onChange={(e) => {
                                                let num = e.target.value;
                                                let data = JSON.parse(JSON.stringify(list));
                                                data[index].products[k].num = num;
                                                setList(data);
                                            }} onPressEnter={(e) => {
                                                let num = e.target.value;
                                                if (num != '' && !isNaN(num)) {
                                                    num = num < 1 ? 1 : num;
                                                    changeCartNum(row.id, num);
                                                } else {
                                                    getList();
                                                }
                                            }} bordered={false} value={row.num} className="rowFlex" style={{ textAlign: 'center' }} />
                                            <img onClick={() => {
                                                let num = row.num + 1;
                                                changeCartNum(row.id, num)
                                            }} alt='' src={require('../../imgs/jia.png')} className="width16 cursor" />
                                        </div>
                                        <div className="totalbox">
                                            <Price className='total' value={row.amount.value} />
                                            {/* <span className="total">¥ 108.00</span> */}
                                        </div>
                                        <div className="czl">
                                            {/* {row.num > 1 && <p onClick={() => {
                                                setOpen(true)
                                            }}>{t("拆分")}</p>} */}
                                            <p onClick={() => del([row.id])}>{t("删除")}</p>
                                        </div>
                                    </div>
                                    {/* <div className="flexCenter bqbox">
                                        <span>{t("标签：")}</span>
                                        <Popover
                                            content={(
                                                <React.Fragment>
                                                    <Button onClick={() => {
                                                        setBsOpen(true);
                                                    }} type="default" size="small" shape="round" className="font12" style={{ lineHeight: '22px' }}>+{t("创建新标签")}</Button>
                                                    <Button type="primary" size="small" className="font12 margint10" style={{ display: 'block', background: '#fe3a2f', lineHeight: '22px' }}>哈哈</Button>
                                                    <Button type="primary" size="small" className="font12 margint10" style={{ display: 'block', background: '#ff950f', lineHeight: '22px' }}>哈哈</Button>
                                                </React.Fragment>
                                            )}
                                        >
                                            <i className="iconfont icon-tianjiabiaoqian cursor"></i>
                                        </Popover>
                                        <Dropdown
                                            menu={{
                                                items: [{ key: '1', label: (<span className="font12"><i className="iconfont icon-dingdanhuishouzhan font14"></i>{t("删除")}</span>) }]
                                            }}
                                            overlayClassName='delboxxx'
                                            arrow={{
                                                pointAtCenter: true,
                                            }}
                                            placement="bottom"
                                        >
                                            <Button type="primary" shape="round" size="small" className="font12 marginl10" style={{ background: '#fe3a2f' }}>哈哈</Button>
                                        </Dropdown>
                                        <Button type="primary" shape="round" size="small" className="font12 marginl10" style={{ background: '#ff950f' }}>哈哈</Button>
                                    </div> */}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            {/* 结算栏 */}
            {list.length > 0 && <Affix offsetBottom={0}>
                <div className="fixbot bgbai">
                    <div className="flexCenter selt">
                        <Checkbox
                            checked={isAllChecked()}
                            indeterminate={isAllIndeterminate()}
                            onClick={checkAll}
                        >{t("全选")}</Checkbox>
                        <Button onClick={() => del(obj.ids)} disabled={(!isAllChecked() && !isAllIndeterminate()) ? true : false} type="link" size="small" className={(!isAllChecked() && !isAllIndeterminate()) ? '' : 'colorlv'}>{t("删除选中的商品")}</Button>
                        <Button disabled={(!isAllChecked() && !isAllIndeterminate()) ? true : false} type="link" size="small" className={(!isAllChecked() && !isAllIndeterminate()) ? '' : 'colorlv'}>{t("编辑标签")}</Button>
                        <Button disabled={(!isAllChecked() && !isAllIndeterminate()) ? true : false} type="link" size="small" className={(!isAllChecked() && !isAllIndeterminate()) ? '' : 'colorlv'}>{t("查看仓库照片")}</Button>
                        {list.length > 0 && <Pagination className="marginlauto" total={total} showSizeChanger showQuickJumper showTotal={() => {
                            return `${t("共条", {
                                num: total,
                            })}`
                        }} pageSize={info.size} onChange={(page, size) => {
                            setInfo({
                                page, size
                            })
                        }} onShowSizeChange={(page, size) => {
                            setInfo({
                                page, size
                            })
                        }} />}
                    </div>
                    <div className="flexCenter bot">
                        <div className="total_pri">
                            <Price className='block' value={obj.sumPrice} />
                            {/* <p>¥ 0.00</p> */}
                            <span>{t("已选中商品")}<span className="colorlv"> {obj.num} </span>{t("件(不含运费)")}</span>
                        </div>
                        <Button type="default" style={{ borderWidth: 2, borderColor: colorPrimary, color: colorPrimary, fontWeight: 500 }}>{t("预估国际运费")}</Button>
                        <Button onClick={checkOut} disabled={(!isAllChecked() && !isAllIndeterminate()) ? true : false} type="primary" className="marginl10">{t("结算")}</Button>
                    </div>
                </div>
            </Affix>}
            {/* 备注 */}
            <Modal
                title={t('商品备注信息')}
                width={420}
                centered
                destroyOnClose
                onCancel={onCancel}
                open={remarkOpen}
                footer={null}
            >
                <EditGoodsRemark pathType='Cart' data={row} onCancel={onCancel} onOk={() => {
                    onCancel();
                    getList();
                }} />
            </Modal>
            {/* 拆分商品 */}
            <Modal
                title={t('拆分商品')}
                width={800}
                centered
                onCancel={onCancel}
                open={open}
                footer={null}
                className="disclaimer"
            >
                <Table
                    dataSource={[{ num: 2 }]}
                    columns={columns}
                    pagination={false}
                />
                <div style={{ marginTop: 30, textAlign: 'right' }}>
                    <Button type="primary">{t("确定")}</Button>
                </div>
            </Modal>
            {/* 订单标识 */}
            <Modal
                title={t('订单标识')}
                width={600}
                centered
                onCancel={onCancel}
                open={bsOpen}
                footer={null}
                className="disclaimer"
                destroyOnClose={true}
            >
                <Form
                    colon={false}
                    labelCol={{ flex: '81px' }}
                >
                    <Form.Item label={t('标识名称')} name='name' rules={[{ required: true, message: t('请输入标签名称') }]}>
                        <Input placeholder={t("请输入")} />
                    </Form.Item>
                    <Form.Item label={t('标识颜色')} name='color' rules={[{ required: true, message: t('请选择标签颜色') }]}>
                        <ColorPicker />
                    </Form.Item>
                    <Form.Item label={t('标识备注')} name='remarks'>
                        <Input.TextArea rows={2} placeholder={t("请输入")} />
                    </Form.Item>
                    <Button type="primary" htmlType="submit" shape="round" className="marginlauto" style={{ display: 'block' }}>{t("确定")}</Button>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default forwardRef(Index);