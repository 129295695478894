import {
  App,
  Button,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Pagination,
  Select,
  Image,
  Tabs,
} from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Price from "../../Common/Price";
import Loading from "../../Common/Loading";
import EditQd from "./EditQd";
import OrderGj from "./OrderGj";
import Helper from "../../class/Helper";
const Index = (props, _ref) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const statusMap = {
    1: t("已删除"),
    10: t("已创建"),
    20: t("已确认"),
    30: t("已支付"),
    40: t("已出库"),
    100: t("已交付"),
    200: t("已完成"),
  };
  const [tabs] = useState([
    { label: t("全部包裹"), key: 0 },
    { label: t("打包中"), key: 1 },
    // { label: t('待补款'), key: 6 },
    { label: t("待支付"), key: 2 },
    { label: t("寄送中"), key: 3 },
    { label: t("已完成"), key: 4 },
    { label: t("已取消"), key: 10 },
  ]);
  const [otherSearch, setOtherSearch] = useState(true);
  const [listLoading, setListLoading] = useState(false);
  const [list, setList] = useState([]);
  const [qdOpen, SetQdOpen] = useState(false);
  const [gjOpen, setGjOpen] = useState(false);
  const [mic, setMic] = useState("");
  const [gjOrderState, setGjOrderState] = useState("");
  const [editQdInfo, setEditQdInfo] = useState({});
  const [obj, setObj] = useState({
    State: 0,
    OrderNumber: 0,
    PeriodMin: "",
    PeriodMax: "",
    page: 1,
    size: 10,
  });
  const [open, setOpen] = useState(false);
  const [orderObj, setOrderObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    document.title = t("我的包裹");
    refresh();
    //eslint-disable-next-line
  }, [obj.State, obj.OrderNumber, obj.PeriodMin, obj.page, obj.size]);
  const refresh = () => {
    setList([]);
    getList();
  };
  function toQueryString(obj, page) {
    // 创建一个新的 URLSearchParams 对象
    const params = new URLSearchParams();
    // 遍历对象的属性并添加到 URLSearchParams 对象中
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== undefined) {
        params.append(key, obj[key]);
      }
    });
    // 处理单独的 page 参数
    if (page !== undefined) {
      params.append("PageIndex", page);
    }

    return params.toString();
  }
  // 获取列表
  const getList = () => {
    setListLoading(true);
    const params = {
      State: obj.State,
      OrderNumber: obj.OrderNumber || undefined,
      PeriodMin: obj.PeriodMin || undefined,
      PeriodMax: obj.PeriodMax || undefined,
      PageSize: obj.size,
    };
    global.Ajax.GET(
      `api/ParcelOrder/GetParcelOrder?${toQueryString(params, obj.page)}`,
      {}
    ).then((res) => {
      if (res.isSuccess) {
        setList(res.result.items);
        obj.page == 1 && setTotal(res.result.pagination.availCnt);
      }
      setListLoading(false);
    });
  };
  //
  const onCancel = () => {
    setOpen(false);
    SetQdOpen(false);
    setGjOpen(false);
    setOrderObj({});
    setGjOrderState("");
  };
  // 取消
  const cancelOrder = (data) => {
    setLoading(true);
    data.orderNumber = orderObj.orderNumber;
    global.Ajax.hhtc_request("api/ParcelOrder/CancelOrder", data, "PUT").then(
      (res) => {
        if (res.isSuccess) {
          message.success(res.message);
          onCancel();
          refresh();
        } else {
          message.error(res.message);
        }
        setLoading(false);
      }
    );
  };
  return (
    <React.Fragment>
      <div className="par_nav flex">
        <Tabs
          activeKey={obj.State}
          items={tabs}
          style={{ width: "100%" }}
          tabPosition="top"
          onChange={(key) => {
            setObj({
              ...obj,
              State: key,
            });
          }}
        />
      </div>
      <div>
        <Input
          onChange={(e) => {
            setObj({
              ...obj,
              OrderNumber: e.target.value,
            });
          }}
          placeholder={`${t("名称")}/${t("主订单")}/${t("商品单号")}`}
          style={{ width: 280 }}
          suffix={<i className="iconfont icon-dingbusousuo"></i>}
        />
        <span
          onClick={() => setOtherSearch(!otherSearch)}
          className="marginl10 cursor otherLock"
        >
          {t("精简筛选条件")}
          <i
            className={`iconfont ${
              otherSearch ? "icon-xuankuangshouqi" : "icon-xuankuangzhankai"
            }`}
          ></i>
        </span>
        <div className={`row10 flwp others ${otherSearch ? "active" : ""}`}>
          <DatePicker.RangePicker
            className="margint10 marginr10"
            onChange={(_, date) => {
              setObj({
                ...obj,
                PeriodMin: date[0],
                PeriodMax: date[1],
              });
            }}
          />
          <Select
            placeholder={t("配送目的国家")}
            className="margint10 marginr10"
            style={{ width: 200 }}
          />
          <Select
            placeholder={t("寄送路线")}
            className="margint10 marginr10"
            style={{ width: 200 }}
          />
        </div>
      </div>
      <div className="parcelList margint20">
        {/* 表头 */}
        <div className="flex font14 weight600 color3 center head">
          <p className="rowFlex">{t("订单信息")}</p>
          {/* <p className="row1">重量(g)</p>
                      <p className="row15">尺寸(cm)</p> */}
          {/* <p className="row1">{t("附加费")}</p> */}
          <p className="row1">{t("金额")}</p>
          {/*<p className="row15">{t("配送方式")}</p>*/}
          <p className="row1">{t("状态")}</p>
          <p className="row15">{t("操作")}</p>
        </div>
        {/* 加载中 */}
        {listLoading && <Loading style={{ paddingTop: 100 }} />}
        {/* 空 */}
        {!listLoading && list.length == 0 && (
          <Empty style={{ marginTop: 50 }} />
        )}
        {/* 表内容 */}
        {!listLoading &&
          list.map((item, index) => (
            <div className="item margint10 borderf2 font12" key={String(index)}>
              <div className="flexCenter child paddv10 font12 center">
                <div className="rowFlex">
                  <span>{t("订单编号")}：</span>
                  <span>{item.orderNumber}</span>
                  <span className="marginl10">{t("创建时间")}</span>
                  <span className="marginl4">
                    {Helper.utc2time(item.createdTime)}
                  </span>
                </div>
                <div className="row1">
                  <Price value={item.payAmount.value} className="font12" />
                </div>
                <div className="row1">
                  {item.state == 1 && t("打包中")}
                  {item.state == 2 && t("待支付")}
                  {item.state == 3 && t("运输中")}
                  {item.state == 4 && t("已完成")}
                  {item.state == 5 && t("已取消")}
                </div>
                <div className="row15">
                  {/* 待支付 */}
                  {item.state == 2 && (
                    <React.Fragment>
                      <p
                        onClick={() => {
                          props.navigate(
                            `/checkout?orderNumber=${item.orderNumber}&type=3`
                          );
                        }}
                        className="weight600 cursor"
                        style={{ color: "red" }}
                      >
                        {t("去支付")}
                      </p>
                      <p
                        onClick={() => {
                          setOrderObj({
                            orderNumber: item.orderNumber,
                          });
                          setOpen(true);
                        }}
                        className="weight600 margint4 cursor"
                        style={{ color: "red" }}
                      >
                        {t("取消")}
                      </p>
                    </React.Fragment>
                  )}
                </div>
              </div>
              {/* 物流单 */}
              <div className="entryBox" style={{ marginTop: 0 }}>
                {/* 表头 */}
                <div className="flex center font12 weight600 borderb1f2">
                  <p className="rowFlex paddl12" style={{ textAlign: "left" }}>
                    {t("国内包裹")}
                  </p>

                  <p className="row2">{t("重量")}(g)</p>
                  <p className="row2">{t("尺寸")}(cm)</p>
                  <p className="row15">{t("商品类型")}</p>
                  <p className="row15">{t("状态")}</p>
                </div>
                {/* 表内容 */}
                {(!item.entryNotices || item.entryNotices.length == 0) && (
                  <Empty imageStyle={{ height: 50 }} className="font12" />
                )}
                {item.entryNotices &&
                  item.entryNotices.map((row, k) => (
                    <div className="" key={String(k)}>
                      <div className="flexCenter center">
                        <p
                          className="rowFlex paddl12"
                          style={{ textAlign: "left" }}
                        >
                          {row.trackingNumber}
                        </p>

                        <p className="row2">{row.weight}</p>
                        <p className="row2">
                          {row.length} * {row.width} * {row.height}
                        </p>
                        <p className="row15">{row.nature}</p>
                        <p className="row15">
                          {row.stage == 1 && t("已作废")}
                          {row.stage == 10 && t("创建")}
                          {row.stage == 100 && t("已预报")}
                          {row.stage == 110 && t("已签入")}
                          {row.stage == 120 && t("已清点")}
                          {row.stage == 130 && t("已提交打包")}
                          {row.stage == 220 && t("异常")}
                          {row.stage == 250 && t("退货")}
                        </p>
                      </div>
                      {/* 商品照片 */}
                      {row.pictures.length > 0 && (
                        <div className="goods_photo bgf5 flexCenter">
                          <p className="color9 marginr10">{t("普通拍照")}</p>
                          <div className="rowFlex flex">
                            <Image.PreviewGroup>
                              {row.pictures.map(
                                (m, n) =>
                                  m != "" && (
                                    <div className="marginr4" key={String(n)}>
                                      <Image src={m} width={40} height={40} />
                                    </div>
                                  )
                              )}
                            </Image.PreviewGroup>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
              {/* 包裹单 */}
              <div className="entryBox" style={{ marginTop: 0 }}>
                {/* 表头 */}
                <div className="flex center font12 weight600 borderb1f2">
                  <p className="row2">{t("国际包裹")}(g)</p>
                  <p className="row2">{t("末端单号")}(g)</p>
                  <p className="row2">{t("重量")}(g)</p>
                  <p className="row2">{t("尺寸")}(cm)</p>
                  <p className="row15">{t("商品类型")}</p>
                  <p className="row1">{t("状态")}</p>
                  <p className="row1">{t("操作")}</p>
                </div>
                {/* 表内容 */}
                {(!item.parcelsGroupBy || item.parcelsGroupBy.length == 0) && (
                  <Empty imageStyle={{ height: 50 }} className="font12" />
                )}
                {}
                {item.parcelsGroupBy &&
                  item.parcelsGroupBy.map((groups, k1) => (
                    <>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ paddingLeft: 12 }}>{groups.route}</p>
                      </div>
                      {groups.parcels.map((row, k) => (
                        <div className="" key={String(k)}>
                          <div className="flexCenter center ">
                            <p className="row2">{row.mic}</p>
                            <p className="row2">{row.trackingNbr}</p>
                            <p className="row2">{row.weight}</p>
                            <p className="row2">
                              {row.length} * {row.width} * {row.height}
                            </p>
                            <p className="row15">{row.tags}</p>
                            <p className="row1">
                              {row.status == 1 && t("已删除")}
                              {row.status == 10 && t("已创建")}
                              {row.status == 20 && t("已确认")}
                              {row.status == 30 && t("已支付")}
                              {row.status == 40 && t("已出库")}
                              {row.status == 100 && t("已交付")}
                              {row.status == 200 && t("已完成")}
                            </p>
                            <div
                              className={"row1"}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <p
                                onClick={() => {
                                  setGjOrderState(statusMap[row.status] || "");
                                  setMic(row.mic);
                                  setGjOpen(true);
                                }}
                                className=" cursor"
                                style={{ color: "red" }}
                              >
                                {t("轨迹")}
                              </p>
                              {item.state == 2 && (
                                <p
                                  onClick={() => {
                                    setEditQdInfo({
                                      id: row.id,
                                    });
                                    SetQdOpen(true);
                                  }}
                                  className={"editQd"}
                                >
                                  {t("修改渠道")}
                                </p>
                              )}
                            </div>
                          </div>
                          {/* 商品照片 */}
                          {row.pictures.length > 0 && (
                            <div className="goods_photo bgf5 flexCenter">
                              <p className="color9 marginr10">
                                {t("普通拍照")}
                              </p>
                              <div className="rowFlex flex">
                                <Image.PreviewGroup>
                                  {row.pictures.map(
                                    (m, n) =>
                                      m != "" && (
                                        <div
                                          className="marginr4"
                                          key={String(n)}
                                        >
                                          <Image
                                            src={m}
                                            width={40}
                                            height={40}
                                          />
                                        </div>
                                      )
                                  )}
                                </Image.PreviewGroup>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  ))}
              </div>
            </div>
          ))}
      </div>
      {/* 分页 */}
      {total > 0 && (
        <Pagination
          total={total}
          pageSize={obj.size}
          className="marginlauto"
          showSizeChanger
          showQuickJumper
          style={{ textAlign: "right", marginTop: 30 }}
          showTotal={() => {
            return `${t("共条", {
              num: total,
            })}`;
          }}
          onChange={(page, size) => {
            setObj({
              ...obj,
              page,
              size,
            });
          }}
        />
      )}
      {/* 取消 */}
      <Modal
        title={t("取消包裹")}
        open={open}
        width={500}
        centered
        destroyOnClose
        onCancel={onCancel}
        footer={null}
        className="huihead"
      >
        <p>{t("确定要取消此包裹吗？取消后将无法恢复。")}</p>
        <Form onFinish={cancelOrder} className="margint20">
          <Form.Item
            name="remark"
            rules={[{ required: true, message: t("请输入取消原因") }]}
          >
            <Input.TextArea rows={4} placeholder={t("请输入内容")} />
          </Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            shape="round"
            className="block marginlauto"
          >
            {t("确定")}
          </Button>
        </Form>
      </Modal>
      <Modal
        title={t("修改渠道")}
        open={qdOpen}
        width={530}
        centered
        destroyOnClose
        onCancel={onCancel}
        footer={null}
        className="huihead"
      >
        <EditQd
          data={editQdInfo}
          onOk={() => {
            SetQdOpen(false);
            getList();
          }}
        />
      </Modal>
      <Modal
        title={t("订单轨迹")}
        open={gjOpen}
        width={800}
        centered
        destroyOnClose
        onCancel={onCancel}
        footer={null}
        className="huihead"
      >
        <OrderGj mic={mic} state={gjOrderState} />
      </Modal>
    </React.Fragment>
  );
};

export default forwardRef(Index);
