import { Breadcrumb, Button, Form, Input } from "antd";
import React, { forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Index = (props, _ref) => {
    const {t}=useTranslation();
    useEffect(() => {
        document.title = t('修改支付密码');
        //eslint-disable-next-line
    }, [])
    return (
        <React.Fragment>
            <Breadcrumb
                separator=">"
                items={[{
                    title: t('账户安全'),
                    className: 'cursor weight600',
                    onClick: () => {
                        props.navigate(-1);
                    }
                }, {
                    title: t('修改支付密码'),
                    className: 'weight600'
                }]}
            />
            <Form
                colon={false}
                autoComplete="off"
                style={{ width: 540, marginTop: 40 }}
                labelCol={{flex: '190px'}}
            >
                <Form.Item label={t('支付密码')} name='pwd' rules={[{ required: true, message: t('不能为空') }]}>
                    <Input placeholder={t("请输入")} />
                </Form.Item>
                <Form.Item label={t('重复密码')} name='pwd' rules={[{ required: true, message: t('请确认密码') }]}>
                    <Input placeholder={t("请输入")} />
                </Form.Item>
                <Form.Item label={t('邮箱验证码')} name='pwd' rules={[{ required: true, message: t('请输入邮箱验证码') }]}>
                    <div className="flex">
                        <Input placeholder={t("请输入")} className="rowFlex" />
                        <Button type="primary" className="marginl20 font12">{t("获取验证码")}</Button>
                    </div>
                </Form.Item>
                <Button type="primary" htmlType="submit" block style={{width: 'calc(100% - 190px)',marginLeft: 190}}>{t("提交")}</Button>
            </Form>
        </React.Fragment>
    )
}

export default forwardRef(Index);