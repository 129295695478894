import { Button, Form, Input, Modal, Table } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Helper from "../../class/Helper";

const Index = (props, _ref) => {
    const { t } = useTranslation();
    const [tabs] = useState([
        { label: t('积分明细'), value: 1 },
        { label: t('兑换记录'), value: 2 },
    ]);
    const [activeKey, setActiveKey] = useState(1);
    const [open, setOpen] = useState(false);
    const [list, setList] = useState([]);
    const [points, setPoints] = useState(0);
    // 积分明细列表
    const columns = [
        {
            title: t('相关编号'),
            dataIndex: 'activityId',
            align: 'center',
        },
        {
            title: t('积分'),
            dataIndex: 'points',
            align: 'center',
            render: (points, record) => (
                record.isAddition ? `+${points}` : `-${points}`
            )
        },
        {
            title: t('时间'),
            dataIndex: 'activityDate',
            align: 'center',
            render: (time) => (
                Helper.utc2time(time)
            )
        },

    ];
    // 兑换记录
    const recordColumns = [{
        title: t('商品名称'),
        dataIndex: 'time',
        align: 'center',
    }, {
        title: t('相关编号'),
        dataIndex: 'time',
        align: 'center',
    }, {
        title: t('兑换方式'),
        dataIndex: 'time',
        align: 'center',
    }, {
        title: t('积分'),
        dataIndex: 'time',
        align: 'center',
    }, {
        title: t('仓储费'),
        dataIndex: 'time',
        align: 'center',
    }, {
        title: t('兑换时间'),
        dataIndex: 'time',
        align: 'center',
        render: (time) => (
            Helper.utc2time(time)
        )
    }, {
        title: t('状态'),
        dataIndex: 'time',
        align: 'center',
    }, {
        title: t('操作'),
        dataIndex: 'time',
        align: 'center',
    }]
    useEffect(() => {
        document.title = t('我的积分');
        // 
        global.Ajax.GET("api/Point/PointForId", {}).then(res => {
            if (res.isSuccess) {
                setPoints(res.result.points);
            }
        })
        // const userInfo=JSON.parse(localStorage.getItem('mycnUserInfo'));
        getList();
        //eslint-disable-next-line
    }, []);
    const onCancel = () => {
        setOpen(false);
    }
    // 积分记录
    const getList = () => {
        global.Ajax.GET("api/PointActivitie/PointActivitieForId").then(res => {
            if (res.isSuccess) {
                setList(res.result);
            }
        })
    }
    return (
        <React.Fragment>
            <div className="bglv intgl_top flexCenter">
                <div className="left rowFlex">
                    <p>{t("我的积分")}:</p>
                    <span>{points}</span>
                    <p className="font12">{t("本月积分兑换")}：0 / 500000</p>
                </div>
                <Button onClick={() => setOpen(true)} type="primary" shape="round" className="font14" style={{ background: '#333' }}>{t("商品兑换")}</Button>
            </div>
            <div className="par_nav flex coupon_nav font12 margint10">
                {tabs.map((item, index) => (
                    <p onClick={() => {
                        setActiveKey(item.value);
                    }} className={`${item.value == activeKey ? 'active' : ''}`} key={String(index)}>{item.label}</p>
                ))}
            </div>
            <Table
                dataSource={activeKey == 1 ? list : []}
                columns={activeKey == 1 ? columns : recordColumns}
                className="jftable"
            />
            {/* 商品兑换 */}
            <Modal
                title={t("兑换商品")}
                open={open}
                width={418}
                centered
                destroyOnClose
                footer={null}
                onCancel={onCancel}
            >
                <Form
                    className="margint20 paddt12"
                >
                    <Form.Item name='pro' rules={[{ required: true, message: t('请输入您的兑换码') }]}>
                        <Input className="bgf5" placeholder={t("请输入您的兑换码")} bordered={false} style={{ height: 40 }} />
                    </Form.Item>
                    <Button type="primary" block htmlType="submit" style={{ height: 40 }}>{t("使用积分兑换")}</Button>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default forwardRef(Index);