// import { createSlice } from '@reduxjs/toolkit';

// const data = JSON.parse(localStorage.getItem('currency')) || {};
// const infoSlice = createSlice({
//     name: 'infos',
//     initialState: {
//         currency: data.currency || 'CNY',  // 币种
//         unit: data.unit || 2,  // id或者索引
//         symbol: data.symbol || '¥',  // 符号
//         rate: 1,  // 汇率--基于人民币
//         file: ''
//     },
//     reducers:{
//         setCurrency: (state,action)=>{
//             console.log(state);
//             console.log(action);
//         },
//         setSearchFile: (state,action)=>{
//             console.log(action);
//         }
//     }
// })
// const {setCurrency,setSearchFile}=infoSlice.actions;

// export default infoSlice.reducers;

const data = JSON.parse(localStorage.getItem('currency')) || {};
// 定义 Store 的初始值
const initialState = {
    currency: data.currency || 'CNY',  // 币种
    unit: data.unit || 2,  // id或者索引
    symbol: data.symbol || '¥',  // 符号
    rate: 1,  // 汇率--基于人民币
    file: '',   //通过图片搜索商品的图片源---base64格式
    headPortrait: '',
    email: '',
    name: '',
    phoneNumber: '',
}

// Reducer，处理 Action 返回新的 State
export default function counterReducer(state = initialState, action) {
    switch (action.type) {
        case 'setCurrency':
            return { ...state, ...action.data }
        case 'setSearchFile':
            return { ...state, ...action.data }
        case "setUserInfo":
            return { ...state, ...action.data }
        default:
            return state
    }
}
