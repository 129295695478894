import { App, Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import Helper from "../class/Helper";
import { useTranslation } from "react-i18next";

const ColorPicker = ({ value = '', onChange }) => {
    const [colors, setColors] = useState([]);
    useEffect(() => {
        getColors()
    }, []);
    // 获取颜色
    const getColors = async () => {
        let data = await Helper.colorList();
        setColors(data)
    }
    const triggerChange = (url) => {
        onChange?.(url);
    };
    return (
        <div className="flex">
            {colors.map((item, index) => (
                <span onClick={() => triggerChange(item.value)} className={`colorkk marginr10 cursor ${value == item.value ? 'active' : ''}`} style={{ background: item.key }} key={String(index)}></span>
            ))}
        </div>
    )
}
const Index = (props, _ref) => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    const [loading, setLoading] = useState(false);
    const onFinish = (data) => {
        setLoading(true)
        global.Ajax.hhtc_request('api/Tag/Add', data).then(res => {
            if (res.isSuccess) {
                message.success(res.message);
                props.onOk?.();
            } else {
                message.error(res.message);
            }
            setLoading(false);
        })
    }
    return (
        <Form
            colon={false}
            labelCol={{ flex: '81px' }}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item label={t('标识名称')} name='name' rules={[{ required: true, message: t('请输入标签名称') }]}>
                <Input placeholder={t("请输入")} />
            </Form.Item>
            <Form.Item label={t('标识颜色')} name='colour' rules={[{ required: true, message: t('请选择标签颜色') }]}>
                <ColorPicker />
            </Form.Item>
            <Form.Item label={t('标识备注')} name='remark'>
                <Input.TextArea rows={2} placeholder={t("请输入")} />
            </Form.Item>
            <Button loading={loading} type="primary" htmlType="submit" shape="round" className="marginlauto" style={{ display: 'block' }}>{t("确定")}</Button>
        </Form>
    )
}

export default Index;