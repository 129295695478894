import { Button, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Price from "../../Common/Price";
import Empty from "antd/es/empty/empty";
import Helper from "../../class/Helper";
import { useParams } from "react-router-dom";
const Index = (props, _ref) => {
    const { t } = useTranslation();
    const { paramtype } = useParams()
    const [tabs] = useState([
        { label: t('领券中心'), value: 0 },
        { label: t('可使用优惠券'), value: 10 },
        { label: t('已使用优惠券'), value: 20 },
        { label: t('已过期优惠券'), value: 30 },
    ]);
    const [type, setType] = useState(paramtype || 0);
    const [list, setList] = useState([]);
    const [loadingKey, setLoadingKey] = useState(0)
    function multiplyBy100AndTrim(num) {
        let result = num * 100; // 将数字乘以100
        if (Number.isInteger(result) && result % 10 === 0) {
            return result / 10; // 如果结果是整数且个位数为0，则将结果除以10
        } else {
            return result; // 否则返回乘以100后的结果
        }
    }
    useEffect(() => {
        if (type > 0) {
            getList();
        } else {
            getCouponList();
        }
        //eslint-disable-next-line
    }, [type]);
    // 优惠券
    const getList = () => {
        global.Ajax.GET(`api/Member/Coupons?Status=${type}`).then(res => {
            if (res.isSuccess) {
                setList(res.result)
            }
        })
    }
    //获取可用的优惠券列表
    const getCouponList = () => {
        global.Ajax.GET(`api/Member/Coupons/Unclaimed`).then(res => {
            if (res.isSuccess) {
                setList(res.result)
            }
        })
    }
    return (
        <React.Fragment>
            {/*<div className="flex coupon_code">*/}
            {/*    <Input placeholder={t("请输入兑换码")} bordered={false} />*/}
            {/*    <Button type="primary">{t("兑换")}</Button>*/}
            {/*</div>*/}
            <div className="par_nav flex coupon_nav">
                {tabs.map((item, index) => (
                    <p onClick={() => {
                        if (type != item.value) {
                            setList([])
                            setType(item.value)
                        }
                    }
                    } className={`${type == item.value ? 'active' : ''}`} key={String(index)}>{item.label}</p>
                ))}
            </div>
            <div className="couponlist flwp">
                {list.length == 0 && <Empty />}
                {type > 0 ?
                    <>
                        {list.map((item, index) => (
                            <div className="item32 item" key={String(index)}>
                                <div className={`top ${(type == 10 || type == 0) ? 'bglv' : 'bgc'} center font12`}>
                                    {/* <p className="pri">¥200</p> */}
                                    {item.type == 'FixedAmount' ? <Price value={Number(item.discount)} className="pri" /> : <span className="pri">{multiplyBy100AndTrim(item.discount)}{t('折')}</span>}
                                    <p className="man">{item.desc}</p>
                                </div>
                                <div className="font12 color9 mid">
                                    <p>{t("适用范围")}：{item.rangeOfApplication}</p>
                                    {/* <p>{t("包裹类型")}：{t("国际包裹")}</p> */}
                                    <p>{Helper.utc2time(item.expirationTime)}</p>
                                </div>
                                {type == 10 && <div className="bq font12">
                                    <p className="bg"></p>
                                    <span>NEW</span>
                                </div>}
                            </div>
                        ))}
                    </>
                    :

                    <>
                        {list.map((item, index) => (
                            <div className="item32 item" key={String(index)}>
                                <div className={`top bglv center font12`}>
                                    {/* <p className="pri">¥200</p> */}
                                    {item.type == 2 ? <Price value={Number(item.policy.discount)} className="pri" /> : <span className="pri">{multiplyBy100AndTrim(item.policy.discount)}{t('折')}</span>}
                                    <p className="man">{item.policyDesc}</p>
                                    <p className="type">{item.manual}</p>
                                </div>
                                <div className="font12 color9 mid">
                                    <p>{t("适用范围")}：{item.name}</p>
                                    {/* <p>{t("包裹类型")}：{t("国际包裹")}</p> */}
                                    <p>{Helper.utc2time(item.postedOn)}</p>
                                    <p>{t("领取后")}{item.expiryDays}{t("天可使用")}</p>
                                </div>
                                <Button loading={loadingKey == index + 1} onClick={() => {
                                    setLoadingKey(index + 1)
                                    global.Ajax.hhtc_request(`api/Member/Coupons/${item.id}/Claimed`, {}, "POST").then(res => {
                                        setLoadingKey(0)
                                        if (res.isSuccess) {
                                            message.success(res.message)
                                            setList([])
                                            getCouponList()
                                        } else {
                                            message.error(res.message)
                                        }
                                    })
                                }
                                } className={"couponBtn"} >{t("立即领取")}</Button>
                                <div className="bq font12">
                                    <p className="bg"></p>
                                    <span>{t("剩")}{item.totalCnt}{t("张")}</span>
                                </div>
                            </div>
                        ))}
                    </>
                }

            </div>
            {/* <Pagination total={50} showQuickJumper showTotal={() => {
                return `${t("共条", {
                    num: 10
                })}`
            }} style={{ textAlign: 'right' }} /> */}
        </React.Fragment>
    )
}

export default Index;