import { Button, Modal } from "antd";
import React, { useState } from "react";
import OrderMask from '../../Common/OrderMark';
import { useTranslation } from "react-i18next";

const Index = () => {
    const { t } = useTranslation();
    // const {  modal } = App.useApp();
    const [open, setOpen] = useState(false);
    // const columns = [{
    //     title: t('标识'),
    //     dataIndex: 'type',
    //     className: 'font12',
    //     render: () => (
    //         <Tag bordered={false} color="#f00" style={{ borderRadius: 11 }}>哈哈</Tag>
    //     )
    // }, {
    //     title: t('备注'),
    //     dataIndex: 'type',
    //     className: 'font12',
    //     width: '64%',
    //     render: () => (
    //         <span className="color9">哈哈</span>
    //     ),
    // }, {
    //     title: t('操作'),
    //     dataIndex: 'type',
    //     className: 'font12',
    //     align: 'center',
    //     width: 150,
    //     render: () => (
    //         <div className="pubbtn flexAllCenter">
    //             <p className="cursor weight600">{t("编辑")}</p>
    //             <p onClick={() => del()} className="cursor weight600">{t("删除")}</p>
    //         </div>
    //     )
    // }];
    const onCancel = () => {
        setOpen(false);
    }
    // const del = () => {
    //     modal.confirm({
    //         title: t('警告'),
    //         content: t('是否确认删除？'),
    //         centered: true,
    //         onOk: () => { }
    //     })
    // }
    return (
        <React.Fragment>
            <Button onClick={() => setOpen(true)} type="primary" className="margint20 weight600">{t("创建标识")}</Button>
            <div className="empty">
                <img className="pic" alt='' src={require('../../imgs/goodsTag.png')} />
                <div className="flexAllCenter">
                    <p>{t("没有找到您查找的标签，")}</p>
                    <span onClick={() => setOpen(true)} className="colorlv cursor">{t("去添加新标签")}</span>
                </div>
                <div className="flex">
                    <div className="rowFlex">
                        <h4>1.{t("什么是商品标签")}</h4>
                        <p className="font12">{t("是一种更灵活、更有趣的商品分类方式，可以为您的订单内每个商品添加一个或多个标签。")}</p>
                        <img alt='' src={require('../../imgs/tag1.png')} />
                    </div>
                    <div className="rowFlex">
                        <h4>2.{t("添加标签的好处")}</h4>
                        <p className="font12">{t("可通过自定义标签在购物车、我的订单、我的仓库环节更高效地筛选和管理你的订单。")}</p>
                        <img alt='' src={require('../../imgs/tag2.png')} />
                    </div>
                    <div className="rowFlex">
                        <h4>3.{t("如何添加标签")}</h4>
                        <p className="font12">{t("在购物车、我的订单、我的仓库均可自定义添加标签")}</p>
                        <img alt='' src={require('../../imgs/tag3.png')} />
                    </div>
                </div>
            </div>
            {/* <Table
                dataSource={[1]}
                columns={columns}
                pagination={false}
                className="margint10"
            /> */}
            {/* 编辑标识 */}
            <Modal
                title={t('订单标识')}
                width={600}
                centered
                onCancel={onCancel}
                open={open}
                footer={null}
                className="disclaimer"
                destroyOnClose={true}
            >
                <OrderMask />
            </Modal>
        </React.Fragment>
    )
}

export default Index;