import { Button, Checkbox, Form, Input, Modal, 
    // Progress,
     Radio, Tooltip } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EditUserInfo from "./EditUserInfo";
import { useDispatch } from "react-redux";
import Myprivilege from "./Myprivilege";

const Index = (props, _ref) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [imgUrl, setImgurl] = useState(require('../../imgs/sy.png'));
    const [editOpen, setEditOpen] = useState(false);
    const [info, setInfo] = useState({});
    const dispatch = useDispatch();
    useEffect(() => {
        document.title = t('个人信息');
        // 
        getInfo();
        //eslint-disable-next-line
    }, []);
    const onCancel = () => {
        setOpen(false);
        setEditOpen(false)
    }
    const onValuesChange = (res, values) => {
        let key = Object.keys(res)[0];
        if (key == 'type') {
            let url;
            if (values[key] == 1) {
                url = require('../../imgs/sy.png');
            } else if (values[key] == 2) {
                url = require('../../imgs/kz.png');
            } else if (values[key] == 3) {
                url = require('../../imgs/xz.png');
            }
            setImgurl(url);
        }
    }
    // 获取基本信息
    const getInfo = () => {
        global.Ajax.GET('api/Member/MemberInfo').then(res => {
            if (res.isSuccess) {
                setInfo(res.result);
                dispatch({
                    type: 'setUserInfo',
                    data: res.result,
                })
            }
        })
    }
    return (
        <React.Fragment>
            <div className="userhome">
                <div className="info flexCenter" style={{ position: 'relative' }}>
                    <div className="lbox">
                        <div className="avatarbox">
                            <img alt='' src={(info.headPortrait && info.headPortrait != '') ? info.headPortrait : require('../../imgs/avatar_hui.png')} className="avatar" />
                            <span>{info.levelName}</span>
                        </div>
                        <p className="margint4">ID：{info.memberId || ''}</p>
                    </div>
                    <div className="info_cont rowFlex">
                        <div className="flwp font12">
                            <div className="flexCenter item32">
                                <span>{t("用户名")}：</span>
                                <span className="rowFlex weight600">{info.name || ''}</span>
                            </div>
                            <div className="flexCenter item32">
                                <span>{t("国家1")}：</span>
                                <span className="rowFlex weight600">{info.nation || ''}</span>
                            </div>
                            <div className="flexCenter item32">
                                <span>{t("手机")}：</span>
                                <span className="rowFlex weight600">{info.phoneNumber || ''}</span>
                            </div>
                            <div className="flexCenter item32">
                                <span>{t("性别")}：</span>
                                <span className="rowFlex weight600">{info.sex > 0 ? (info.sex == 1 ? t(('男')) : t(('女')) ): ''}</span>
                            </div>
                            <div className="flexCenter item32">
                                <span>{t("语言")}：</span>
                                <span className="rowFlex weight600">{info.language || ''}</span>
                            </div>
                            <div className="flexCenter item32">
                                <span>{t("生日")}：</span>
                                <span className="rowFlex weight600">{info.birthday || ''}</span>
                            </div>
                        </div>
                        {/* <div className="flexCenter font12" style={{ marginBottom: -4 }}>
                            <span>{t("成长值")}</span>
                            <span className="weight600">0</span>
                            <p onClick={() => props.navigate("/user/account/levelrecord")} className="marginlauto cursor">{t("查看成长值记录")}</p>
                        </div>
                        <Progress percent={30} showInfo={false} strokeColor={'#f6ce52'} trailColor="#353535" />
                        <p className="font12">{t("距离只需要个成长值", {
                            level: "V1",
                            num: 3000
                        })}</p> */}
                    </div>
                    <span onClick={() => setEditOpen(true)} className="font12 editbtn">
                        <i className="iconfont icon-bianji font12 marginr4"></i>
                        {t("编辑")}
                    </span>
                </div>
            </div>
            {/* <div className="flexCenter paddb12 margint20 borderb1f2">
                <h1 className="marginrauto">{t("我的尺码信息")}</h1>
                <Button onClick={() => setOpen(true)} type="link" className="font12">{t("编辑")}</Button>
            </div> */}
            <Myprivilege levelId={info.levelId} />

            {/* 编辑尺码 */}
            <Modal
                title={t('帮我选')}
                width={800}
                open={open}
                destroyOnClose
                onCancel={onCancel}
                footer={null}
                className="disclaimer"
            >
                <div className="flex edit_size_box">
                    <div className="left">
                        <img alt='' src={imgUrl} className="pic" />
                    </div>
                    <div className="rowFlex rbox">
                        <Form
                            colon={false}
                            layout="vertical"
                            onValuesChange={onValuesChange}
                        >
                            <Form.Item label={t('角色')} name='name' rules={[{ required: true, message: t('请输入角色名称') }]}>
                                <Input placeholder={t("角色名称")} />
                            </Form.Item>
                            <Form.Item label={t('选择商品分类')} name='type' initialValue={'1'}>
                                <Radio.Group
                                    options={[
                                        { label: t('上衣'), value: '1' },
                                        { label: t('裤子'), value: '2' },
                                        { label: t('鞋子'), value: '3' },
                                        { label: t('其他'), value: '4' },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item noStyle shouldUpdate={(prev, cur) => prev.type != cur.type}>
                                {({ getFieldValue }) => {
                                    let type = getFieldValue('type');
                                    return (
                                        <React.Fragment>
                                            {/* 上衣 */}
                                            {type == 1 && <div className="flwp">
                                                <Form.Item className="item49" label={t('身高')} name='chang' rules={[{ required: true, message: t('身高是必填项') }]}>
                                                    <Input placeholder={t("身高")} suffix='cm' />
                                                </Form.Item>
                                                <Form.Item className="item49" label={t('体重')} name='chang' rules={[{ required: true, message: t('体重是必填项') }]}>
                                                    <Input placeholder={t("体重")} suffix='cm' />
                                                </Form.Item>
                                                <Form.Item className="item49" label={t('肩宽')} name='kuan'>
                                                    <Input placeholder={t("肩宽")} suffix='cm' />
                                                </Form.Item>
                                                <Form.Item className="item49" label={t('胸围')} name='kuan'>
                                                    <Input placeholder={t("胸围")} suffix='cm' />
                                                </Form.Item>
                                                <Form.Item className="item49" label={t('臂长')} name='kuan'>
                                                    <Input placeholder={t("臂长")} suffix='cm' />
                                                </Form.Item>
                                                <Form.Item className="item49" label={t('腰围')} name='kuan'>
                                                    <Input placeholder={t("腰围")} suffix='cm' />
                                                </Form.Item>
                                            </div>}
                                            {/* 裤子 */}
                                            {type == 2 && <div className="flwp">
                                                <Form.Item className="item49" label={t('身高')} name='chang' rules={[{ required: true, message: t('身高是必填项') }]}>
                                                    <Input placeholder={t("身高")} suffix='cm' />
                                                </Form.Item>
                                                <Form.Item className="item49" label={t('体重')} name='chang' rules={[{ required: true, message: t('体重是必填项') }]}>
                                                    <Input placeholder={t("体重")} suffix='cm' />
                                                </Form.Item>
                                                <Form.Item className="item49" label={t('腰围')} name='kuan'>
                                                    <Input placeholder={t("腰围")} suffix='cm' />
                                                </Form.Item>
                                                <Form.Item className="item49" label={t('臀围')} name='kuan'>
                                                    <Input placeholder={t("臀围")} suffix='cm' />
                                                </Form.Item>
                                                <Form.Item className="item49" label={t('腿长')} name='kuan'>
                                                    <Input placeholder={t("腿长")} suffix='cm' />
                                                </Form.Item>
                                            </div>}
                                            {/* 鞋子 */}
                                            {type == 3 && <div className="flwp">
                                                <Form.Item className="item49" label={t('脚长')} name='chang' rules={[{ required: true, message: t('脚长是必填项') }]}>
                                                    <Input placeholder={t("脚长")} suffix='cm' />
                                                </Form.Item>
                                                <Form.Item className="item49" label={t('脚宽')} name='kuan'>
                                                    <Input placeholder={t("脚宽")} suffix='cm' />
                                                </Form.Item>
                                            </div>}
                                            {/* 其他 */}
                                            {type == 4 && <div className="flwp">
                                                <Form.Item className="item49" label={t('身高')} name='chang'>
                                                    <Input placeholder={t("身高")} suffix='cm' />
                                                </Form.Item>
                                                <Form.Item className="item49" label={t('体重')} name='chang'>
                                                    <Input placeholder={t("体重")} suffix='cm' />
                                                </Form.Item>
                                            </div>}
                                        </React.Fragment>
                                    )
                                }}
                            </Form.Item>
                            <Form.Item label={t('个人喜好')} name='hobby' rules={[{ required: true, message: t('个人喜好是必填项') }]}>
                                <Radio.Group
                                    options={[
                                        { label: t('合身的'), value: '1' },
                                        { label: t('宽松的'), value: '2' },
                                    ]}
                                    className="weight600"
                                />
                            </Form.Item>
                            <Form.Item label='' name='remark'>
                                <Input.TextArea rows={2} placeholder={t("如有其他要求可以在此留言")} />
                            </Form.Item>
                            <Form.Item name='is_check'>
                                <Checkbox className="color6 weight600">{t("订购前需要跟我确定尺码")}</Checkbox>
                                <Tooltip title={(<p className="font12">{t("如果您需要采购跟您再次确认。会延长采购处理时间。")}</p>)}>
                                    <i className="iconfont icon-a-bangzhu14px font12 cursor color6"></i>
                                </Tooltip>
                            </Form.Item>
                            <div className="flexCenter" style={{ paddingTop: 0 }}>
                                <p className="rowFlex font14">{t("服务费")}&nbsp;¥ 3.00</p>
                                <Button type="primary" shape="round" htmlType="submit">{t("保存")}</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
            {/* 修改用户信息 */}
            <Modal
                title={t('修改用户信息')}
                width={600}
                open={editOpen}
                centered
                destroyOnClose
                onCancel={onCancel}
                footer={null}
                className="disclaimer"
            >
                <EditUserInfo data={info} onOk={() => {
                    onCancel();
                    getInfo();
                }} />
            </Modal>
        </React.Fragment>
    )
}

export default forwardRef(Index);