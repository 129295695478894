import { Button, DatePicker, Tooltip, theme } from "antd";
import React, { forwardRef, useEffect } from "react";
import * as echarts from 'echarts';
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";

const Index = (props, _ref) => {
    const { t } = useTranslation();
    const { token: {
        colorPrimaryBg
    } } = theme.useToken();
    useEffect(() => {
        document.title = t('消费分析');
        // 折线图
        let chartDom = document.getElementById('box1');
        let myChart = echarts.init(chartDom);
        let option = {
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                    return [pt[0], '10%'];
                },
                alwaysShowContent: true,
            },
            title: {
                text: t('消费走势'),
                top: 10,
                left: 10,
                textStyle: {
                    fontSize: 14,
                }
            },
            grid: {
                left: '8%',
                bottom: '10%',
                top: '15%',
            },
            xAxis: {
                type: 'category',
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                axisLine: { //x轴线的颜色以及宽度
                    show: true,
                    lineStyle: {
                        color: "#f2f2f2",
                        width: 1,
                        type: "solid"
                    }
                },
                axisLabel: {
                    textStyle: {
                        color: '#ddd'
                    }
                },
                axisTick: {
                    show: false,
                },
                boundaryGap: false,
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    textStyle: {
                        color: '#ddd'
                    }
                },
                splitLine: {//分割线配置
                    show: true,
                    lineStyle: {
                        color: "#f2f2f2",
                    }
                },
            },
            series: [
                {
                    data: [0, 0, 224, 218, 135, 147, 260],
                    type: 'line',
                    lineStyle: { // 设置线条的style等
                        normal: {
                            color: '#11ba66', // 折线线条颜色
                        },
                    },
                    itemStyle: {
                        normal: {
                            color: '#11ba66',
                        },
                    },
                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0,
                                color: 'rgba(17,186,102,.4)',
                            }, {
                                offset: 1,
                                color: 'rgba(255,255,255,0)',
                            }],
                            global: false,
                        },
                    },
                    markLine: {
                        symbol: 'none',
                        label: {
                            show: false,
                        },
                        lineStyle: {
                            color: '#f00',
                            type: 'solid',
                        },
                        data: [{
                            xAxis: 'Sun',
                        }]
                    }
                }
            ]
        };
        myChart.setOption(option);
        // 环形图
        let chartDom2 = document.getElementById('box2');
        let myChart2 = echarts.init(chartDom2);
        let option2 = {
            series: [
                {
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 8
                    },
                    data: [
                        {
                            value: 300, name: '暂无数据', itemStyle: {
                                normal: {
                                    color: '#cdcdcd'
                                }
                            }
                        }
                    ]
                }
            ]
        };
        myChart2.setOption(option2);
        //eslint-disable-next-line
    }, [])
    return (
        <React.Fragment>
            <div className="flexCenter">
                <h1>{t("消费分析")}</h1>
                <div className="rowFlex" style={{ textAlign: 'right' }}>
                    <Button onClick={() => props.navigate("/user/bills")} className="bgf5 color6" style={{ border: 0 }}>{t("我的账单")}</Button>
                    <Button className="colorlv borderlv marginl10" style={{ background: colorPrimaryBg }}>{t("消费分析")}</Button>
                </div>
            </div>
            <div className="reptbox flex">
                <div className="left bglv flexColumn">
                    <DatePicker defaultValue={dayjs('2024-01', 'YYYY-MM')} picker="month" bordered={false} inputReadOnly allowClear={false} />
                    <div className="rowFlex flexColumn" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <p>{t("共支出笔，合计", {
                            num: 0
                        })}</p>
                        <p className="p1">CNY 0.00</p>
                    </div>
                </div>
                <div className="rbox rowFlex" style={{ height: 380, position: 'relative' }}>
                    <div id="box1" style={{ width: '100%', height: '100%' }}></div>
                    <Button type="primary" size="small" style={{ position: 'absolute', right: '4%', top: '8%' }}>CNY 0.00</Button>
                </div>
            </div>
            <div className="flex consumption margint20">
                <div className="left marginr10">
                    <h4>{t("消费构成")}
                        <Tooltip color="#303133" title={(<div className="font12 weight600" style={{ color: '#fff' }}>
                            <p>{t("我们将消费类型分为四大类别：")}</p>
                            <p style={{ marginTop: 5 }}>{t("商品")}： <span style={{ color: 'hsla(0,0%,100%,.7)', fontWeight: 'normal' }}>{t("包括商品及相关消费，例如实付金额、定制拍照服务、商品延长仓储费等。")}</span></p>
                            <p style={{ marginTop: 5 }}>{t("包裹")}： <span style={{ color: 'hsla(0,0%,100%,.7)' }}>{t("包含与包裹相关的消费，如实付运费、运费补款、包裹增值服务费等。")}</span></p>
                            <p style={{ marginTop: 5 }}>{t("预演")}： <span style={{ color: 'hsla(0,0%,100%,.7)' }}>{t("显示购买包裹预演包装的费用支出。")}</span></p>
                            <p style={{ marginTop: 5 }}>{t("其他")}： <span style={{ color: 'hsla(0,0%,100%,.7)' }}>{t("包括不属于以上三类的消费支出，如打赏费用、退换货操作费等。")}</span></p>
                        </div>)} overlayInnerStyle={{ width: 460 }}>
                            <i className="iconfont icon-a-bangzhu14px marginl4 cursor" style={{ fontWeight: 'normal' }}></i>
                        </Tooltip>
                    </h4>
                    <div id="box2" style={{ height: 360 }}></div>
                    {/* <Divider dashed /> */}
                    <div className="qq">
                        <span></span>
                        <span></span>
                    </div>
                    <div id="box3" style={{ height: 310, borderTop: '1px dashed #d8d8d8' }}></div>
                </div>
                <div className="rbox">
                    <h4>{t("商品购买TOP10")}</h4>
                </div>
            </div>
        </React.Fragment>
    )
}

export default forwardRef(Index);