import { DatePicker, Select, Table } from "antd";
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";

const Index = (props, _ref) => {
    const {t}=useTranslation();
    const columns=[{
        title: t('交易时间'),
        dataIndex: 'time'
    },{
        title: t('类型'),
        dataIndex: 'time'
    },{
        title: `${t("金额")}(¥)`,
        dataIndex: 'time'
    },{
        title: t('业务单号'),
        dataIndex: 'time'
    },{
        title: t('备注'),
        dataIndex: 'time'
    }]
    return (
        <React.Fragment>
            <h1>{t("欠款记录")}</h1>
            <div className="flwp margint10">
                <DatePicker.RangePicker className="marginr10 margint10" />
                <Select
                    placeholder={t('类型')}
                    style={{ width: 180 }}
                    className="marginr10 margint10"
                />
            </div>
            <Table
                dataSource={[]}
                columns={columns}
                pagination={false}
            />
        </React.Fragment>
    )
}

export default forwardRef(Index);