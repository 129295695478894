import React, { useEffect, useState } from "react";
import Bksq from './Bksq';  // 补款授权
import Ddbs from './Ddbs';  // 订单标识
import Dzpz from './Dzpz';  // 定制拍照
import PushNotice from './PushNotice';  // 推送通知
import WaterMark from './WaterMark';  // 自定义照片
import { useTranslation } from "react-i18next";

const Components = {
    "Bksq": Bksq,
    "Ddbs": Ddbs,
    "Dzpz": Dzpz,
    "PushNotice": PushNotice,
    "WaterMark": WaterMark,
}
const list = (path, props) => {
    var MyComponentt = Components[path];
    return <MyComponentt {...props} />;
}

const Index = () => {
    const { t } = useTranslation();
    const tabs = [
        { title: t('补款授权'), desc: t('开启这个功能，免去小金额补款确认，有效提高效率。'), path: 'Bksq' },
        { title: t('订单标识管理'), desc: t('开启这个功能，管理您的客户。'), path: "Ddbs" },
        { title: t('定制拍照模板'), desc: t('学会使用这个功能，填写[定制拍照]内容的时候，会变的更加容易'), path: "Dzpz" },
        { title: t('电子邮件&APP推送通知'), desc: t('勾选我们应该通知你的内容'), path: 'PushNotice' },
        { title: t('自定义照片'), desc: t('开启水印则您收到的拍照会添加您选择的水印样式'), path: 'WaterMark' },
    ];
    const [current, setCurrent] = useState(0);
    useEffect(() => {
        document.title = t('自定义功能');
        //eslint-disable-next-line
    }, []);
    return (
        <React.Fragment>
            <div className="flex mtabs">
                {tabs.map((item, index) => (
                    <div onClick={() => setCurrent(index)} className={`item item${index} ${current == index ? 'active' : ''}`} key={String(index)}>
                        <span className="icon"></span>
                        <p className="weight600">{item.title}</p>
                        <p className="font12 color9">{item.desc}</p>
                    </div>
                ))}
            </div>
            {list(tabs[current].path)}
        </React.Fragment>
    )
}

export default Index;