import {Timeline,  message} from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import Helper from "../../class/Helper";
import { useTranslation } from "react-i18next";
const OrderGj = (props, _ref) => {
    const { t } = useTranslation();
     const[datas,setDatas]=useState([])
    useEffect(()=>{
        getOrderGj();
        //eslint-disable-next-line
    },[]);
    const getOrderGj = () => {


        global.Ajax.GET('api/ParcelOrder/ParcelTrackings/'+props.mic).then(res => {
            if (res.isSuccess) {
                let datass=[];
                for(var i=0;i<res.result.events.length;i++)
                {
                    var event=res.result.events[i]
                    datass.push({
                        children: (
                            <div>
                                <p>{Helper.utc2time(event.operatedOn)}</p>
                                <div>
                                    {event.stage} {event.place} {event.operator}
                                </div>
                            </div>
                        )
                    })
                }
                setDatas(datass)
            } else {
                message.error(res.message)
            }

        })
    }

    return (
        <React.Fragment>

            <div className="order_status_box">
                <h4>{t("订单状态")}：{props.state}</h4>
            </div>
            <Timeline
                className="order_tracks"
                items={datas}
            />

        </React.Fragment>
    )
}

export default forwardRef(OrderGj);