import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./appage.css";

const Index = () => {
  const { t,i18n } = useTranslation();
  useEffect(() => {
    document.title = t("app");
    //eslint-disable-next-line
  }, []);
  const lange = useMemo(()=>{
    if(i18n.language =="zh-CN"){
      return "cn"
    }else{
      return "en"
    }
  },[i18n.language])
  return (
    <React.Fragment>
      <div className="apppage">
        <div className="imagebox">
          <div className="left">
            <h1>{t("扫码下载APP")}</h1>
            <p>{t("相机扫码下载,随时随地轻松购物")}</p>
            <div className="iosapp">
              <img alt="ios" src={require("../../imgs/iosapp.png")} />
              <p className="iostext">ios</p>
            </div>
          </div>
          <div className="right">
            <img alt="android" src={require("../../imgs/apphome.png")} />
          </div>
        </div>
        <div className="w1200">
          <img alt="app1_cn" src={require(`../../imgs/app1_${lange}.png`)} />
        </div>
        <div className="w1200">
          <img alt="app4_cn" src={require(`../../imgs/app4_${lange}.png`)} />
        </div>
        <div class="bottom">
          <div class="flex imagebox">
            <div className="left">
              <div className="iosapp">
                <img style={{width:"200px"}} alt="ios" src={require("../../imgs/iosapp.png")} />
                <p className="iostext">ios</p>
              </div>
            </div>
            <div className="left white">
              <h1>{t("扫码下载APP")}</h1>
              <p>{t("相机扫码下载,随时随地轻松购物")}</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Index;
