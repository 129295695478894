import {
  Affix,
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  Empty,
  Image,
  Input,
  Modal,
  Pagination,
  Popover,
  Select,
  // theme,
} from "antd";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import Helper from "../../class/Helper";
import { useTranslation } from "react-i18next";
import Loading from "../../Common/Loading";
import PlatformIcon from "../../Common/PlatformIcon";
import OrderMask from "../../Common/OrderMark"; // 订单标识

const Index = (props, _ref) => {
  const boxRef = useRef();
  const affixRef = useRef();
  const { t } = useTranslation();
  // const {
  //   token: { colorPrimary, colorPrimaryBg },
  // } = theme.useToken();
  const seltRef = useRef(null);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const [otherSearch, setOtherSearch] = useState(true);
  const [bsOpen, setBsOpen] = useState(false);
  const [obj, setObj] = useState({
    id: "",
    show: false,
  });
  const [fqOpen, setFqOpen] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [row, setRow] = useState({
    num: 0,
    ids: [],
    OrderNumber: "",
    PlatForm: 0,
    tagIds: [],
    page: 1,
    size: 10
  });
  const [lines, setLines] = useState([]); // 快递
  const [tagsList, setTagsList] = useState([]);
  useEffect(() => {
    document.title = t("我的仓库");
    if (items.length == 0) {
      getColors();
      getTagsList();
      getLines();
      // body
      document.body.addEventListener("click", handleBody);
    }
    let storageCountry = localStorage.getItem("storageCountry");
    if (storageCountry) {
      storageCountry = JSON.parse(storageCountry);
      setRow({
        ...row,
        country: storageCountry,
      });
      seltRef.current && seltRef.current.setObj(storageCountry);
    }
    //
    // window.addEventListener("scroll", handler, true);
    return () => {
      document.body.removeEventListener("click", handleBody);
      // window.removeEventListener("scroll", handler, true);
    };
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    //
    getList();
    //eslint-disable-next-line
  }, [
    row.OrderNumber,
    row.PeriodMin,
    row.PlatForm,
    row.page,
    row.tagIds
  ]);
  const handleBody = (e) => {
    if (e.target && e.target.offsetParent.matches("body")) {
      seltRef.current && seltRef.current.setOpen(false);
    }
  };
  // 获取标签
  const getTagsList = () => {
    global.Ajax.GET("api/Tag/List").then((res) => {
      if (res.isSuccess) {
        setTagsList(res.result);
      }
    });
  };
  // 获取颜色
  const getColors = async () => {
    let data = await Helper.colorList();
    setItems(data);
  };
  const onCancel = () => {
    setFqOpen(false);
    setBsOpen(false);
  };
  // 获取列表
  const getList = () => {
    setListLoading(true);
    global.Ajax.hhtc_request("api/Hub/GetMyHub", {
      orderNumber: row.OrderNumber,
      periodMin: row.PeriodMin || undefined,
      periodMax: row.PeriodMax || undefined,
      pageIndex: row.page,
      pageSize: row.size,
      tagIds: row.tagIds,
      platForm: row.PlatForm,
    }).then((res) => {
      if (res.isSuccess) {
        let datas = res.result.items;
        // 处理已选中的物流单
        datas.forEach((item) => {
          let index = item.entryNotices.findIndex((r) =>
            row.ids.includes(r.id)
          );
          if (index > -1) {
            item.entryNotices[index].checked = true;
          }
        });
        setList(datas);
        row.page == 1 && setTotal(res.result.pagination.availCnt);
      }
      setListLoading(false);
    });
  };
  // 获取寄送路线
  const getLines = () => {
    global.Ajax.GET("api/Hub/GetService").then((res) => {
      if (res.isSuccess) {
        setLines(res.result);
      }
    });
  };
  // 判断一级是否全选
  function isFirstCheckAll(index) {
    let data = JSON.parse(JSON.stringify(list));
    let res = data[index].checked || true;
    let childs = data[index].entryNotices;
    if (childs.length > 0) {
      res = childs.every((item) => item.checked);
    } else {
      res = data[index].checked;
    }
    return res;
  }
  // 判断一级是否显示半选
  function isFirstIndeterminate(index) {
    let data = JSON.parse(JSON.stringify(list));
    let res = false;
    let childs = data[index].entryNotices;
    if (childs.length > 0) {
      res = childs.some((item) => item.checked);
      if (childs.every((item) => item.checked)) {
        res = false;
      }
    }
    return res;
  }
  // 判断是否全部选中
  const isAllChecked = () => {
    let data = JSON.parse(JSON.stringify(list));
    // 判断一级是否全部选中
    let res = data.every((item) => item.checked);
    return res;
  };
  // 判断全选是否部分选中
  const isAllIndeterminate = () => {
    let data = JSON.parse(JSON.stringify(list));
    let res = false;
    for (let index in data) {
      let childs = data[index].entryNotices;
      if (childs.length > 0) {
        res = childs.some((item) => item.checked);
        if (res) {
          break;
        }
      }
    }
    res = isAllChecked() ? false : res;
    return res;
  };
  // 全选
  const checkAll = () => {
    let data = JSON.parse(JSON.stringify(list));
    for (let i in data) {
      data[i].checked = !isAllChecked();
      let secChild = data[i].entryNotices;
      for (let k in secChild) {
        // 改变二级
        if (secChild[k].stage == 120) {
          secChild[k].checked = !isAllChecked();
        }
      }
    }
    setList(data);
    calcGoods(data);
  };
  // 一级选项切换
  const firstChange = (index) => {
    let data = JSON.parse(JSON.stringify(list));
    if (data[index].checked) {
      // 一级已全选
      // 改变所有子级为不选
      let secChild = data[index].entryNotices;
      for (let i in secChild) {
        // 改变二级
        secChild[i].checked = false;
      }
      // 改变自己
      data[index].checked = false;
    } else {
      // 一级未全选
      // 改变所有子级为选中
      let secChild = data[index].entryNotices;
      for (let i in secChild) {
        // 改变二级
        secChild[i].checked = true;
      }
      // 改变自己
      data[index].checked = true;
    }
    setList(data);
    calcGoods(data);
  };
  // 二级选项切换
  const secChange = (index1, index2) => {
    let data = JSON.parse(JSON.stringify(list));
    data[index1].entryNotices[index2].checked =
      !data[index1].entryNotices[index2].checked;
    let secChild = data[index1].entryNotices;
    // 如果二级全部选中改变一级的checked
    data[index1].checked = secChild.every((item) => item.checked);
    setList(data);
    calcGoods(data);
  };
  // 计算选中商品信息
  const calcGoods = (list) => {
    let num = row.num,
      ids = row.ids;
    for (let i in list) {
      let entryNotices = list[i].entryNotices;
      for (let j in entryNotices) {
        if (entryNotices[j].checked) {
          //已选中
          // 判断是否已包含了此id
          let index = ids.findIndex((item) => item == entryNotices[j].id);
          if (index == -1) {
            num += 1;
            ids.push(entryNotices[j].id);
          }
        } else {
          // 未选中
          // 判断是否已包含了此id
          let index = ids.findIndex((item) => item == entryNotices[j].id);
          if (index != -1) {
            num -= 1;
            ids.splice(index, 1);
          }
        }
      }
    }
    setRow({
      ...row,
      showLine: false,
      num,
      ids,
    });
    setTimeout(() => {
      document.getElementById("asdf").firstElementChild.style.height =
        affixRef.current.offsetHeight + "px";
    }, 100);
    // 预估
    if (ids.length > 0) {
      getWeight(ids);
    } else {
      setObj({
        ...obj,
        weight: 0,
      });
    }
  };
  // 预估重量
  const getWeight = (Ids) => {
    global.Ajax.hhtc_request("api/Hub/EstimatedWeight", { Ids }).then((res) => {
      if (res.isSuccess) {
        setObj({
          ...obj,
          weight: res.result,
        });
      }
    });
  };

  // 添加标签
  const setTag = (orderProductId, tagId) => {
    global.Ajax.hhtc_request("api/ProductDetailTag/Add", {
      orderProductId,
      tagId,
    }).then((res) => {
      if (res.isSuccess) {
        getList();
      }
    });
  };
  // 删除标签
  const delTag = (id) => {
    global.Ajax.hhtc_request(
      `api/ProductDetailTag/Delete/${id}`,
      {},
      "DELETE"
    ).then((res) => {
      if (res.isSuccess) {
        getList();
      }
    });
  };
  // 查询是否有可提交的物流单
  const getIsSub = (data) => {
    let res = data.some((item) => item.stage == 120);
    return res;
  };
  // 提交
  const sub = () => {
    props.navigate(`/submitparcel?Ids=${JSON.stringify(row.ids)}`);
  };
  return (
    <React.Fragment>
      <div className="top_search_order">
        <Input
          onChange={(e) => {
            setRow({
              ...row,
              OrderNumber: e.target.value,
            });
          }}
          placeholder={`${t("名称")}/${t("主订单")}/${t("商品单号")}`}
          style={{ width: 280 }}
          suffix={<i className="iconfont icon-dingbusousuo"></i>}
        />
        <span
          onClick={() => setOtherSearch(!otherSearch)}
          className="marginl10 cursor otherLock"
        >
          {t("精简筛选条件")}
          <i
            className={`iconfont ${
              otherSearch ? "icon-xuankuangshouqi" : "icon-xuankuangzhankai"
            }`}
          ></i>
        </span>
        <div className={`row10 flwp others ${otherSearch ? "active" : ""}`}>
          <Select
            popupClassName="colorboxs"
            className="colorselt margint10 marginr10"
            placeholder={t("标识颜色")}
            popupMatchSelectWidth={false}
            allowClear
            options={items}
            style={{ width: 144 }}
            mode="multiple"
            fieldNames={{ label: "key" }}
            optionRender={(option) => (
              <span
                className="colorkk"
                style={{ background: option.data.key }}
              ></span>
            )}
            tagRender={(props) => {
              return (
                <span
                  className="colorkk"
                  style={{ background: props.label, marginLeft: -10 }}
                ></span>
              );
            }}
            onChange={(e) => {
              setRow({
                ...row,
                tagIds: e,
              });
            }}
          />
          <Select
            placeholder={t("选择平台")}
            options={[
              { label: t("淘宝"), value: 1 },
              { label: t("1688"), value: 2 },
              { label: t("京东"), value: 3 },
              { label: t("转运"), value: 5 },
            ]}
            className="margint10 marginr10"
            style={{ width: 140 }}
            allowClear
            onChange={(PlatForm) => {
              setRow({
                ...row,
                PlatForm,
              });
            }}
          />
          <DatePicker.RangePicker
            className="margint10 marginr10"
            onChange={(_, date) => {
              setRow({
                ...row,
                PeriodMin: date[0],
                PeriodMax: date[1],
              });
            }}
          />
        </div>
      </div>
      {/* 列表 */}
      <div ref={boxRef} className="order_table storage_list">
        <div
          className="flex font12 weight600 center"
          style={{
            borderTop: "1px solid #ddd",
            borderBottom: "1px solid #ddd",
            padding: "12px 0",
          }}
        >
          <div className="rowFlex infos">{t("商品详情")}</div>
          <div className="row3">{t("标签")}</div>
          <div className="row3">{t("备注")}</div>
        </div>
        {/* 加载中 */}
        {listLoading && <Loading style={{ paddingTop: 100 }} />}
        {/* 空数据 */}
        {!listLoading && list.length == 0 && (
          <Empty style={{ marginTop: 100 }} />
        )}
        {/* 表内容 */}
        <div>
          {!listLoading &&
            list.map((item, index) => (
              <div className="card_item" key={String(index)}>
                <div className="flexCenter font12 tbox">
                  {getIsSub(item.entryNotices) && (
                    <Checkbox
                      indeterminate={isFirstIndeterminate(index)}
                      checked={isFirstCheckAll(index)}
                      onChange={() => {
                        firstChange(index);
                      }}
                    ></Checkbox>
                  )}
                  <PlatformIcon
                    platform={item.platForm}
                    className="marginl10"
                  />
                  <span className="weight600 marginl10">
                    {t("订单编号")}: {item.orderNumber}
                  </span>
                  <span className="marginl10">{t("创建时间")}</span>
                  <span className="marginl4">
                    {Helper.utc2time(item.createdTime)}
                  </span>
                  {/* <p className="marginlauto color9 cursor">{t("采购员")}:Preanna</p>
                            <p className="weight600 marginl10 cursor" style={{ color: colorError }}>{t("打赏TA")}</p>
                            <p className="marginl10 ydsbtn" style={{ color: colorError, background: colorErrorBg }}>{t("已打赏")}</p>
                            <p className="colorlv marginl10 cursor">{t("咨询")}</p> */}
                </div>
                <div className="child">
                  {/* 商品 */}
                  {item.products &&
                    item.products.map((row, k) => (
                      <div className="item" key={String(k)}>
                        <div className="flex cellinfo">
                          <div className="infos flex rowFlex">
                            {/* <Checkbox
                                                className="marginl10"
                                                checked={row.checked}
                                                onChange={() => secChange(index, k)}
                                            ></Checkbox> */}
                            <img
                              alt=""
                              src={row.picUrl}
                              className="pic marginl10"
                            />
                            <div className="wzk rowFlex marginl10">
                              <p className="line2 title">{row.title}</p>
                              <p className="color_type">{row.propertiesName}</p>
                              <p className="color_type">PI232110223722</p>
                            </div>
                          </div>
                          <div className="row3">
                            <div className="flexAllCenter bqbox">
                              <span>{t("标签")}：</span>
                              <Popover
                                content={
                                  <React.Fragment>
                                    <Button
                                      onClick={() => {
                                        setBsOpen(true);
                                      }}
                                      type="default"
                                      size="small"
                                      shape="round"
                                      className="font12"
                                      style={{ lineHeight: "22px" }}
                                    >
                                      +{t("创建新标签")}
                                    </Button>
                                    {Helper.filterArray(tagsList, row.tags).map(
                                      (m, n) => (
                                        <Button
                                          key={String(n)}
                                          onClick={() => setTag(row.id, m.id)}
                                          type="primary"
                                          size="small"
                                          className="font12 margint10"
                                          style={{
                                            display: "block",
                                            background: m.colour,
                                            lineHeight: "22px",
                                          }}
                                        >
                                          {m.name}
                                        </Button>
                                      )
                                    )}
                                  </React.Fragment>
                                }
                              >
                                <i className="iconfont icon-tianjiabiaoqian cursor"></i>
                              </Popover>
                              {/* 已添加标签 */}
                              {row.tags &&
                                row.tags.map((m, n) => (
                                  <Dropdown
                                    menu={{
                                      items: [
                                        {
                                          key: "1",
                                          label: (
                                            <span
                                              className="font12"
                                              onClick={() => delTag(m.id)}
                                            >
                                              <i className="iconfont icon-dingdanhuishouzhan font14"></i>
                                              {t("删除")}
                                            </span>
                                          ),
                                        },
                                      ],
                                    }}
                                    overlayClassName="delboxxx"
                                    arrow={{
                                      pointAtCenter: true,
                                    }}
                                    placement="bottom"
                                    key={String(n)}
                                  >
                                    <Button
                                      type="primary"
                                      shape="round"
                                      size="small"
                                      className="font12 marginl10"
                                      style={{
                                        background: m.tagColour,
                                        paddingTop: 1,
                                      }}
                                    >
                                      {m.tagName}
                                    </Button>
                                  </Dropdown>
                                ))}
                            </div>
                          </div>
                          <div className="row3">{row.remark || "-"}</div>
                        </div>
                      </div>
                    ))}
                  {/* 物流单 */}
                  <div className="entryBox">
                    {/* 表头 */}
                    <div className="flex center font12 weight600 borderb1f2">
                      <p className="row_5"></p>
                      <p
                        className="rowFlex paddl12"
                        style={{ textAlign: "left" }}
                      >
                        {t("物流单")}
                      </p>
                      <p className="row2">{t("重量")}(g)</p>
                      <p className="row2">{t("尺寸")}(cm)</p>
                      <p className="row15">{t("商品类型")}</p>
                      <p className="row15">{t("状态")}</p>
                    </div>
                    {/* 表内容 */}
                    {item.entryNotices &&
                      item.entryNotices.map((row, k) => (
                        <div className="" key={String(k)}>
                          <div className="flexCenter center">
                            <div className="row_5">
                              {row.stage == 120 && (
                                <Checkbox
                                  className="marginl10"
                                  checked={row.checked}
                                  onChange={() => secChange(index, k)}
                                ></Checkbox>
                              )}
                            </div>
                            <p
                              className="rowFlex paddl12"
                              style={{ textAlign: "left" }}
                            >
                              {row.trackingNumber}
                            </p>
                            <p className="row2">{row.weight}</p>
                            <p className="row2">
                              {row.length} * {row.width} * {row.height}
                            </p>
                            <p className="row15">{row.nature}</p>
                            <p className="row15">
                              {row.stage == 1 && t("已作废")}
                              {row.stage == 10 && t("创建")}
                              {row.stage == 100 && t("已预报")}
                              {row.stage == 110 && t("已签入")}
                              {row.stage == 120 && t("已清点")}
                              {row.stage == 130 && t("已提交打包")}
                              {row.stage == 220 && t("异常")}
                              {row.stage == 240 && t("准备退货")}
                              {row.stage == 241 && t("退货中")}
                              {row.stage == 250 && t("退货完成")}
                            </p>
                          </div>
                          {/* 商品照片 */}
                          {row.pictures.length > 0 && (
                            <div className="goods_photo bgf5 flexCenter">
                              <p className="color9 marginr10">
                                {t("普通拍照")}
                              </p>
                              <div className="rowFlex flex">
                                <Image.PreviewGroup>
                                  {row.pictures.map(
                                    (m, n) =>
                                      m != "" && (
                                        <div
                                          className="marginr4"
                                          key={String(n)}
                                        >
                                          <Image
                                            src={m}
                                            width={40}
                                            height={40}
                                          />
                                        </div>
                                      )
                                  )}
                                </Image.PreviewGroup>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* 底部操作栏 */}
      {list.length > 0 && (
        <Affix id="asdf" offsetBottom={0}>
          <div ref={affixRef}>
            <div className="fixbot bgbai">
              <div className="flexCenter">
                <Checkbox
                  checked={isAllChecked()}
                  indeterminate={isAllIndeterminate()}
                  onClick={checkAll}
                >
                  {t("全选")}
                </Checkbox>
                <Button disabled type="link" size="small" className="colorlv">
                  {t("编辑标签")}
                </Button>
                {/*<span className="font12 marginlauto">Bccbuy{t("仓库")}</span>*/}
                {/*<span className="color9" style={{ margin: '0 4px' }}>To</span>*/}
                {/*<SelectTabsCountry*/}
                {/*    ref={seltRef}*/}
                {/*    allowClear*/}
                {/*    className="bgf5"*/}
                {/*    inputHeight={32}*/}
                {/*    inputWidth={120}*/}
                {/*    value={row.country}*/}
                {/*    onChange={(data) => {*/}
                {/*        setRow({*/}
                {/*            ...row,*/}
                {/*            country: data,*/}
                {/*        })*/}
                {/*        localStorage.setItem('storageCountry', JSON.stringify(data))*/}
                {/*    }}*/}
                {/*/>*/}
                {/* <Select placeholder={t('寄送国家')} style={{ width: 150 }} bordered={false} className="bgf5" /> */}
                {/*<Button*/}
                {/*    disabled={(row.ids.length > 0 && row.country) ? false : true}*/}
                {/*    style={{ width: 100 }}*/}
                {/*    className={`marginl10 ${(row.ids.length > 0 && row.country) ? 'borderlv colorlv' : ''}`}*/}
                {/*    onClick={() => {*/}
                {/*        setRow({*/}
                {/*            ...row,*/}
                {/*            showLine: !row.showLine,*/}
                {/*        })*/}
                {/*        setTimeout(() => {*/}
                {/*            document.getElementById('asdf').firstElementChild.style.height = affixRef.current.offsetHeight + 'px';*/}
                {/*        }, 100);*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {t('条可用', {*/}
                {/*        num: (row.ids.length > 0 && row.country) ? lines.length : 0*/}
                {/*    })}*/}
                {/*    <i className={`iconfont ${row.showLine ? 'icon-xuankuangshouqi' : 'icon-xuankuangzhankai'} `}></i>*/}
                {/*</Button>*/}
                {/* <Select disabled placeholder={t('条可用', {
                                num: 0
                            })} style={{ width: 100 }} className="marginl10" /> */}
              </div>
              {/* 选择快递路线 */}
              {row.ids.length > 0 && row.country && row.showLine && (
                <div className="linesbox flwp">
                  {lines.map((item, index) => (
                    <div
                      onClick={() => {
                        setRow({
                          ...row,
                          ServiceId: item.id,
                        });
                      }}
                      className={`item font12 ${
                        row.ServiceId == item.id ? "active" : ""
                      }`}
                      key={String(index)}
                    >
                      <img
                        alt=""
                        src={require("../../imgs/kd.png")}
                        className="icon"
                      />
                      <p className="color3 center">{item.name}</p>
                      <span className="block color9 center">
                        {item.remarks}
                      </span>
                    </div>
                  ))}
                </div>
              )}
              <div
                className="flexCenter bot"
                style={{
                  borderTop: "1px solid #f2f2f2",
                  paddingTop: 10,
                  marginTop: 10,
                  alignItems: "flex-end",
                }}
              >
                {total > 0 && (
                  <Pagination
                    size="small"
                    total={total}
                    showSizeChanger={false}
                    showQuickJumper={false}
                    showTotal={() => {
                      return `${t("共条", {
                        num: total,
                      })}`;
                    }}
                    pageSize={row.size}
                    onChange={(page) => {
                      setRow({
                        ...row,
                        page,
                      });
                    }}
                  />
                )}
                <div className="rowFlex" style={{ textAlign: "right" }}>
                  {/* <p className="font12 color9">
                                    {t("预估押金")}({t("实重")})
                                    <span className="font18 colorlv">¥ --</span>
                                </p> */}
                  <p className="font12">
                    {t("选中商品")}
                    <span
                      className="colorlv font14"
                      style={{ margin: "0 4px" }}
                    >
                      {row.num}
                    </span>
                    {t("件（共件商品）", {
                      num: total,
                    })}
                    {obj.weight > 0 && (
                      <React.Fragment>
                        <span className="marginr10">
                          {t("预估重量1")}&nbsp;
                          <span className="weight600">{obj.weight}g</span>
                        </span>
                      </React.Fragment>
                    )}
                    {/*<span className="font12" style={{ marginRight: 4 }}>{t("预估体积")} 24*5*2</span>*/}
                    {/*<Tooltip title={(<p className="font12">{t("这是系统预测的体积，存在误差。如果您想在提包前获得准确数据，可以购买预演包装。")}</p>)}>*/}
                    {/*    <i className="iconfont icon-a-bangzhu14px cursor font12"></i>*/}
                    {/*</Tooltip>*/}
                  </p>
                </div>
                {/* <Tooltip title={(<p className="font12">{t("预演服务不是真实包裹，仅是预打包服务，如需提包请返回仓库进行“提交运送”")}</p>)}>
                                <Button disabled type="default" style={{ borderWidth: 2, borderColor: colorPrimary, color: colorPrimary, fontWeight: 500 }} className="marginl10">{t("预演包装")}</Button>
                            </Tooltip> */}
                <Button
                  onClick={() => sub()}
                  disabled={row.ids.length > 0 ? false : true}
                  type="primary"
                  className="marginl10"
                >
                  {t("提交运送")}
                </Button>
              </div>
            </div>
          </div>
        </Affix>
      )}
      {/* 放弃商品 */}
      <Modal
        width={500}
        open={fqOpen}
        title={t("放弃商品")}
        centered
        destroyOnClose
        onCancel={onCancel}
        className="disclaimer dzpzbox"
      >
        <p className="font12 tips">
          {t(
            "温馨提醒：当您确认【放弃商品】后，我们会销毁您的商品并且将无法恢复。该商品不会退回给卖家来得到退款。请慎重操作。"
          )}
        </p>
        <img alt="" src={require("../../imgs/pic.png")} className="goods_pic" />
        <p className="font12 color9">PI23211022372</p>
        <Input
          placeholder={t("请输入：”我确认放弃“")}
          bordered={false}
          className="inpt_inner"
        />
      </Modal>
      {/* 订单标识 */}
      <Modal
        title={t("订单标识")}
        width={600}
        centered
        onCancel={onCancel}
        open={bsOpen}
        footer={null}
        className="disclaimer"
        destroyOnClose={true}
      >
        <OrderMask
          onOk={() => {
            onCancel();
          }}
        />
      </Modal>
    </React.Fragment>
  );
};

export default forwardRef(Index);
